import { makeStyles } from '@mui/styles'

const flexCenter = {
  display: 'flex',
}

export const useStyles = makeStyles(() => ({
  cardContainer: {
    ...flexCenter,
    width: '100%',
    textAlign: 'justify',
    textJustify: 'inter-word',
    backgroundColor: 'white',
    padding: 50,
  },
  title: {
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: '1.2em',
    paddingBottom: 20,
  },
  subTitle: {
    fontWeight: 'bold',
    paddingTop: 20,
    paddingBottom: 10,
  },
  imp: {
    fontWeight: 'bold',
  },
}))

import { API_ENDPOINT, API_VERSION, ROUTE_CALCULATE } from '../../config'
import {
  CalculateRequest,
  CalculateRequestPuestaTierra,
  CalculateRequestSoporte,
  CalculateRequestUnion,
  ProductComplementSoportes,
  ProductSelected,
  UserHistoryItem
} from '../../config/types/contentTypes'
import { apiCall, getOptions } from '../../util/axios'

export const calculateMaterial = async (myList: ProductSelected[]) => {
  const calculateRequest: CalculateRequest[] = await generateCalculateRequest(myList)

  const calculateMaterialResponse = await apiCall(
    await getOptions({
      endpoint: `${API_ENDPOINT}/${API_VERSION}/${ROUTE_CALCULATE}`,
      httpMethod: 'POST',
      data: calculateRequest,
    })
  ).catch((error) => {
    throw error
  })

  if (calculateMaterialResponse?.data) {
    const items: UserHistoryItem[][] = calculateMaterialResponse?.data
    return items
  }
}

export const generateCalculateRequest = async (
  myList: ProductSelected[]
): Promise<CalculateRequest[]> => {
  const calculateRequest: CalculateRequest[] = []
  myList.forEach((productSelected) => {
    if (!productSelected.acabado?.id || !productSelected.peralte?.id || !productSelected.ancho?.id)
      return undefined

    const uniones: CalculateRequestUnion[] = []
    productSelected.uniones?.forEach((union) => {
      if (productSelected.ancho?.id && productSelected.peralte?.id) {
        uniones.push({
          id: union.id,
          anchoID: productSelected.ancho?.id,
          peralteID: productSelected.peralte?.id,
          adicional: `${union.adicional}`,
        })
      }
    })

    const soportes: CalculateRequestSoporte[] = []
    productSelected.soportes?.forEach((soporte: ProductComplementSoportes) => {
      if (productSelected.ancho?.id && productSelected.peralte?.id && soporte.distancia) {
        const soporteItem: CalculateRequestSoporte = {
          id: soporte.id,
          anchoID: productSelected.ancho?.id,
          peralteID: productSelected.peralte?.id,
          distancia: `${soporte.distancia}`,
        }

        if (soporte.distanciaTecho) {
          soporteItem.distanciaTecho = soporte.distanciaTecho
          soporteItem.kitInstalacionId = soporte.kitInstalacionId
        }

        soportes.push(soporteItem)
      }
    })

    const derivaciones: any[] = Array.from(
      productSelected.derivaciones ? productSelected.derivaciones : [],
      ([key, value]) => ({
        [key]: `${value}`,
      })
    )

    const puestasTierra: CalculateRequestPuestaTierra[] = []
    // puestaTierraSelect
    productSelected.puestaTierraSelect?.forEach((value, key) => {
      puestasTierra.push({
        id: key,
        distancia: {
          cubrir: value.d1,
          separacion: value.d2,
        },
      })
    })

    const otrosAccesorios: any[] = Array.from(
      productSelected.otrosAccesorios ? productSelected.otrosAccesorios : [],
      ([key, value]) => ({
        [key]: `${value}`,
      })
    )

    calculateRequest.push({
      product: productSelected.id,
      acabado: productSelected.acabado?.id,
      acabadoRelated: productSelected.acabadoRelated?.id,
      peralte: productSelected.peralte?.id,
      peralteRelated: productSelected.peralteRelated?.id,
      ancho: productSelected.ancho?.id,
      anchoRelated: productSelected.anchoRelated?.id,
      metros: `${productSelected.metros}`,
      uniones: uniones,
      soportes: soportes,
      derivaciones: derivaciones,
      puestasTierra: puestasTierra,
      otrosAccesorios: otrosAccesorios,
    })
  })

  return calculateRequest
}

import { AppState, AppStatus } from '../../config/types'
import {
  Content,
  ProductComplement,
  ProductContent,
  ProductSelected,
} from '../../config/types/contentTypes'
import { DispatchAction } from '../../config/types/contextTypes'
import { UserSession } from '../../config/types/sessionTypes'
import AppStatusReducer from './appStatusReducer'
import ContentReducer from './contentReducer'
import GeneralErrorReducer from './generalErrorReducer'
import LoginReducer from './loginReducer'
import MyListReducer from './myListReducer'
import ProductComplementReducer from './productComplementReducer'
import ProductReducer from './productReducer'
import ProductsContentReducer from './productsContentReducer'
import ProductSelectedReducer from './productSelectedReducer'

const MainReducer = (
  {
    userSession,
    status,
    content,
    contentData,
    error,
    product,
    productComplement,
    productSelected,
    myList,
  }: {
    userSession: UserSession
    status: AppStatus
    content: Content
    contentData: ProductContent[]
    error: string
    product: ProductContent
    productComplement: ProductComplement
    productSelected: ProductSelected
    myList: ProductSelected[]
  },
  action: DispatchAction
): AppState => {
  if (!action?.type)
    return {
      userSession,
      status,
      content,
      contentData,
      error,
      product,
      productComplement,
      productSelected,
      myList,
    }

  return {
    userSession: LoginReducer(userSession, action),
    status: AppStatusReducer(status, action),
    content: ContentReducer(content, action),
    contentData: ProductsContentReducer(contentData, action),
    error: GeneralErrorReducer(error, action),
    product: ProductReducer(product, action),
    productComplement: ProductComplementReducer(productComplement, action),
    productSelected: ProductSelectedReducer(productSelected, action),
    myList: MyListReducer(myList, action),
  }
}

export default MainReducer

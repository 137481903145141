import { Dispatch } from 'react'

import { API_ENDPOINT, API_VERSION, ROUTE_GET_PRODUCTS } from '../../config'
import { ProductContent } from '../../config/types/contentTypes'
import { DispatchAction } from '../../config/types/contextTypes'
import { apiCall, getOptions } from '../../util/axios'
import { APP_PRODUCT_CONTENT_TYPE } from '../reducers/productsContentReducer'
import { activateLoading, deactivateLoading } from './appStatusActions'

export const getProductsContent = async (dispatch: Dispatch<DispatchAction>): Promise<void> => {
  activateLoading(dispatch)
  const productsContentResponse = await apiCall(
    await getOptions({
      endpoint: `${API_ENDPOINT}/${API_VERSION}/${ROUTE_GET_PRODUCTS}`,
      httpMethod: 'GET',
    })
  ).catch((error) => {
    throw error
  })

  if (productsContentResponse?.status === 200) {
    const productsContent: ProductContent[] = productsContentResponse.data

    dispatch({ type: APP_PRODUCT_CONTENT_TYPE, data: productsContent })
  } else {
    alert(productsContentResponse.data.message)
    // dispatch({ type: GENERAL_ERROR_TYPE, data: 'Ocurrió un error al intentar iniciar sesión. Intente más tarde' })
  }
  deactivateLoading(dispatch)
}

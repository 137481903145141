import Moment from 'moment'
import React, { Dispatch, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Grid, Pagination, ThemeProvider, Typography } from '@mui/material'

import TableCollapse from '../../components/table-collapse'
import { getHistoryHeaders } from '../../components/table-collapse/configuration'
import { AppState } from '../../config/types'
import { UserHistory, UserHistoryItem, UserHistoryRaw } from '../../config/types/contentTypes'
import { DispatchAction } from '../../config/types/contextTypes'
import { UserSession } from '../../config/types/sessionTypes'
import { exportToXLSX } from '../../context/actions/exportFileActions'
import { getUserHistory, preloadUserHistory } from '../../context/actions/historyActions'
import { getProductByIdHistory, getProductsByHistory } from '../../context/actions/productActions'
import { useStateValue } from '../../context/store'
import { themeCharofil } from '../../styles/styles'
import { getDevice } from '../../util/axios'

const History: React.FC = () => {
  const navigate = useNavigate()
  const { state, dispatch }: { state: AppState; dispatch: Dispatch<DispatchAction> } =
    useStateValue()

  const userSession: UserSession | undefined = state?.userSession

  const [history, setHistory] = useState<UserHistory[] | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [page, setPage] = useState<number>(1)
  const paginationSize = 1

  useEffect(() => {
    async function getHistory(userId: number) {
      setHistory(await getUserHistory(userId))
      setIsLoading(false)
    }

    if (!history && userSession && !isLoading) {
      setIsLoading(true)
      getHistory(userSession.id)
    }
  }, [history, userSession, isLoading])

  const downloadExcel = async (items: UserHistoryItem[]) => {
    if (!userSession) return

    const headers = getHistoryHeaders(false)

    const data = {
      headers: [...headers.filter((k) => k.exportName !== 'Editar').map((k) => k.exportName)],
      items: items,
    }

    await exportToXLSX(data, userSession.externalDevice ? userSession.externalDevice : getDevice())
  }

  const moveToMyList = async (listIndex: number | undefined) => {
    if (!userSession || !history || !(listIndex !== undefined)) return

    const result: UserHistoryRaw | undefined = await preloadUserHistory(
      dispatch,
      userSession.id,
      history[listIndex].id
    )

    if (!result) {
      alert(
        'No se logró cargar el histórico debido a que algunos productos han cambiado de nombre ' +
          'ó ya no se encuentran disponibles.'
      )
      return
    } else {
      await getProductsByHistory(dispatch, result.userHistory)
      navigate({ pathname: '/myList' })
    }
  }

  const onPreLoad = async (listIndex: number | undefined, itemIndex: number | undefined) => {
    if (!userSession || !history || !(listIndex !== undefined && itemIndex !== undefined)) return

    const result: UserHistoryRaw | undefined = await preloadUserHistory(
      dispatch,
      userSession.id,
      history[listIndex].id
    )

    if (!result) {
      alert(
        'No se logró cargar el histórico debido a que algunos productos han cambiado de nombre ' +
          'ó ya no se encuentran disponibles.'
      )
      return
    } else {
      await getProductByIdHistory(dispatch, result.userHistory[itemIndex])
      navigate({ pathname: '/product', search: `?id=${result.userHistory[itemIndex].productId}` })
    }
  }

  const buildHistoryItems = (history: UserHistory[]): JSX.Element[] => {
    if (!history?.length) return []

    const materials: JSX.Element[] = []

    history.forEach((h, listIndex) => {
      let productIndex = 0
      h.result.forEach((item) => {
        if (item.type === 'product') {
          item.listIndex = listIndex
          item.itemIndex = productIndex
          productIndex += 1
        }
      })

      materials.push(
        <Grid
          container
          key={`table-c-${listIndex}`}
          item
          style={{ width: '100%', paddingTop: 10, paddingBottom: 45 }}
        >
          <TableCollapse
            title={`Materiales: ${Moment(h.creation).format('DD/MM/YYYY HH:mm:ss')}`}
            items={h.result}
            onPreLoad={onPreLoad}
            onMoveToMyList={() => moveToMyList(listIndex)}
            onDownloadExcel={() => downloadExcel(h.result)}
          />
        </Grid>
      )
    })

    return materials
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const paginate = (materials: JSX.Element[]) => {
    if (!materials) return []

    const pageSize = Math.ceil(materials.length / paginationSize)

    return Array.from({ length: pageSize }, (_, index) => {
      const start = index * paginationSize
      return materials.slice(start, start + paginationSize)
    })
  }

  return history ? (
    <Grid
      container
      direction="column"
      style={{ minHeight: '95vh', backgroundColor: '#f1f1f1' }}
      alignItems="center"
    >
      <Grid item xs={12} style={{ width: '80%' }}>
        <Grid container style={{ paddingTop: 30, paddingBottom: 15 }}>
          <Grid item xs={12}>
            <Typography variant="h5" fontWeight="bold">
              Cuantificaciones Guardadas
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            display={'flex'}
            alignItems="center"
            justifyContent="center"
            style={{ paddingTop: 40 }}
          >
            <ThemeProvider theme={themeCharofil}>
              <Pagination
                key="pagination-top"
                hideNextButton
                hidePrevButton
                count={Math.ceil(buildHistoryItems(history).length / paginationSize)}
                page={page}
                onChange={handleChange}
                color="primary"
              />
            </ThemeProvider>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 20 }}>
            {paginate(buildHistoryItems(history))[page - 1]}
          </Grid>
          <Grid item xs={12} display={'flex'} alignItems="center" justifyContent="center">
            <ThemeProvider theme={themeCharofil}>
              <Pagination
                key="pagination-top"
                hideNextButton
                hidePrevButton
                count={Math.ceil(buildHistoryItems(history).length / paginationSize)}
                page={page}
                onChange={handleChange}
                color="primary"
              />
            </ThemeProvider>
          </Grid>
        </Grid>
        <Grid style={{ height: 50 }} />
      </Grid>
    </Grid>
  ) : null
}

export default History

import React from 'react'

interface Props {
  position: 'absolute' | 'relative'
}

const Footer: React.FC<Props> = ({ position }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: 'black',
        color: 'white',
        fontSize: 13,
        position: position,
        bottom: 0,
        height: '2.5rem',
      }}
    >
      Copyright© ®CHAROFIL {new Date().getFullYear()} v2.2.6{' '}
      {process.env.NODE_ENV !== 'production' ? ` | ${React.version}` : 'Designed by SV'}
    </div>
  )
}

export default Footer

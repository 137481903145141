import { DispatchAction } from '../../config/types/contextTypes'

export const STATUS_TYPE = 'status'

const Reducer = (state: string, action: DispatchAction): string => {
  switch (action.type) {
    case STATUS_TYPE:
      if (action.data) {
        return action.data
      }

      return state

    default:
      return state
  }
}

export default Reducer

import { Dispatch } from 'react'

import {
  ProductComplement,
  ProductContent,
  ProductSelected,
  ProductTabs,
  PuestaTierraSelectValue,
  UserHistoryRawItem
} from '../../config/types/contentTypes'
import { DispatchAction } from '../../config/types/contextTypes'
import { APP_PRODUCT_SELECTED_TYPE } from '../reducers/productSelectedReducer'

export const DEFAULT_SELECT_ITEM = { id: 0 }

export const buildProductSelected = async (
  dispatch: Dispatch<DispatchAction>,
  product: ProductContent
): Promise<void> => {
  const productSelected: ProductSelected = {
    ...product,
    acabado: product.acabados ? product.acabados[0] : undefined,
    peralte: DEFAULT_SELECT_ITEM,
    ancho: DEFAULT_SELECT_ITEM,
    union: DEFAULT_SELECT_ITEM,
    soporte: DEFAULT_SELECT_ITEM,
  }

  dispatch({ type: APP_PRODUCT_SELECTED_TYPE, data: productSelected })
}

export const updateProductSelected = async (
  dispatch: Dispatch<DispatchAction>,
  productSelected: ProductSelected | undefined
): Promise<void> => {
  dispatch({ type: APP_PRODUCT_SELECTED_TYPE, data: productSelected })
}

export const preloadProductSelectedHistory = async (
  dispatch: Dispatch<DispatchAction>,
  product: ProductContent,
  userHistoryRaw: UserHistoryRawItem,
  productComplement: ProductComplement | undefined
): Promise<void> => {
  const newProductSelected: ProductSelected = await preLoadProductSelected(
    product,
    userHistoryRaw,
    productComplement
  )
  dispatch({ type: APP_PRODUCT_SELECTED_TYPE, data: newProductSelected })
}

export const preloadProductSelectedMyList = async (
  dispatch: Dispatch<DispatchAction>,
  product: ProductContent,
  productSelected: ProductSelected,
  productComplement: ProductComplement | undefined
): Promise<void> => {
  const newProductSelected: ProductSelected = await preLoadProductSelected(
    product,
    productSelected,
    productComplement
  )
  dispatch({ type: APP_PRODUCT_SELECTED_TYPE, data: newProductSelected })
}

export const preLoadProductSelected = async (
  product: ProductContent,
  productItem: UserHistoryRawItem | ProductSelected,
  productComplement: ProductComplement | undefined
): Promise<ProductSelected> => {
  const productSelected: ProductSelected = {
    ...product,
  }

  if (isProductItemHistory(productItem)) {
    const userHistoryRaw = productItem as UserHistoryRawItem

    productSelected.metros = userHistoryRaw.metros

    // Acabados
    productSelected.acabado = product.acabados?.find(
      (acabado) => acabado.id === userHistoryRaw.acabadoId
    )
    productSelected.acabadoRelated = productSelected.acabados?.find(
      (acabado) => acabado.id === userHistoryRaw.acabadoRelatedId
    )

    // Peraltes
    productSelected.peralte = productSelected.acabado?.peraltes?.find(
      (peralte) => peralte.id === userHistoryRaw.peralteId
    )
    productSelected.peralteRelated = productSelected.acabado?.peraltes?.find(
      (peralte) => peralte.id === userHistoryRaw.peralteRelatedId
    )

    // Anchos
    productSelected.ancho = productSelected.peralte?.anchos?.find(
      (ancho) => ancho.id === userHistoryRaw.anchoId
    )
    productSelected.anchoRelated = productSelected.peralte?.anchos?.find(
      (ancho) => ancho.id === userHistoryRaw.anchoRelatedId
    )

    const tabTramosRectosWithPerfil: ProductTabs | undefined = product.tabs?.find(
      (t) => t.TipoProductoID === 1007
    )
    if (
      product.perfil &&
      tabTramosRectosWithPerfil &&
      productSelected.peralte?.perfil &&
      productSelected.ancho?.ancho
    ) {
      productSelected.perfil = true
      productSelected.perfilSelect = productSelected.peralte.perfil.id
      productSelected.anchoSelect = productSelected.ancho?.ancho
    }

    productSelected.soportes = []
    if (userHistoryRaw.soportes && productComplement?.soportes) {
      userHistoryRaw.soportes.forEach((s) => {
        const soporte = productComplement?.soportes.find(
          (pcs) => pcs.id === s.id && pcs.ancho_id === s.anchoId && pcs.peralte_id === s.peralteId
        )
        if (soporte) {
          soporte.distancia = s.distancia
          soporte.distanciaTecho = s.distanciaTecho
          soporte.kitInstalacionId = s.kitInstalacionId
          productSelected.soportes?.push(soporte)
        }
      })
    }

    productSelected.uniones = []
    if (userHistoryRaw.uniones && productComplement?.uniones) {
      userHistoryRaw.uniones.forEach((u) => {
        const union = productComplement?.uniones.find(
          (pcu) => pcu.id === u.id && pcu.ancho_id === u.anchoId && pcu.peralte_id === u.peralteId
        )
        if (union) {
          union.adicional = u.adicional
          productSelected.uniones?.push(union)
        }
      })
    }

    if (userHistoryRaw.derivaciones && product.derivacionesByAncho) {
      productSelected.derivaciones = userHistoryRaw.derivaciones
    }

    if (userHistoryRaw.puestasTierra) {
      const puestaTierraSelect = new Map<number, PuestaTierraSelectValue>()
      userHistoryRaw.puestasTierra.forEach((pt) => {
        puestaTierraSelect.set(pt.id, { d1: pt.distancia.cubrir, d2: pt.distancia.separacion })
      })
      productSelected.puestaTierraSelect = puestaTierraSelect
    }

    if (userHistoryRaw.otrosAccesorios && product.otrosAccesoriosByProductID) {
      productSelected.otrosAccesorios = userHistoryRaw.otrosAccesorios
    }
  } else {
    const productSelectedMyList = productItem as ProductSelected

    productSelected.metros = productItem.metros

    // Acabados
    productSelected.acabado = product.acabados?.find(
      (acabado) => acabado.id === productItem.acabado?.id
    )
    productSelected.acabadoRelated = productSelectedMyList.acabadoRelated

    // Peraltes
    productSelected.peralte = productSelected.acabado?.peraltes?.find(
      (peralte) => peralte.id === productItem.peralte?.id
    )
    productSelected.peralteRelated = productSelectedMyList.peralteRelated

    // Anchos
    productSelected.ancho = productSelected.peralte?.anchos?.find(
      (ancho) => ancho.id === productItem.ancho?.id
    )
    productSelected.anchoRelated = productSelectedMyList.anchoRelated

    const tabTramosRectosWithPerfil: ProductTabs | undefined = product.tabs?.find(
      (t) => t.TipoProductoID === 1007
    )
    if (
      product.perfil &&
      tabTramosRectosWithPerfil &&
      productSelected.peralte?.perfil &&
      productSelected.ancho?.ancho
    ) {
      productSelected.perfil = true
      productSelected.perfilSelect = productSelected.peralte.perfil.id
      productSelected.anchoSelect = productSelected.ancho?.ancho
    }

    productSelected.soportes = []
    if (productSelectedMyList.soportes && productComplement?.soportes) {
      productSelectedMyList.soportes.forEach((s) => {
        const soporte = productComplement?.soportes.find(
          (pcs) => pcs.id === s.id && pcs.ancho_id === s.ancho_id && pcs.peralte_id === s.peralte_id
        )
        if (soporte) {
          soporte.distancia = s.distancia
          soporte.distanciaTecho = s.distanciaTecho
          soporte.kitInstalacionId = s.kitInstalacionId
          productSelected.soportes?.push(soporte)
        }
      })
    }

    productSelected.uniones = []
    if (productSelectedMyList.uniones && productComplement?.uniones) {
      productSelectedMyList.uniones.forEach((u) => {
        const union = productComplement?.uniones.find(
          (pcu) => pcu.id === u.id && pcu.ancho_id === u.ancho_id && pcu.peralte_id === u.peralte_id
        )
        if (union) {
          union.adicional = u.adicional
          productSelected.uniones?.push(union)
        }
      })
    }

    if (productSelectedMyList.derivaciones && product.derivacionesByAncho) {
      productSelected.derivaciones = productSelectedMyList.derivaciones
    }

    if (productSelectedMyList.puestaTierraSelect) {
      productSelected.puestaTierraSelect = productSelectedMyList.puestaTierraSelect
    }

    if (productSelectedMyList.otrosAccesorios && product.otrosAccesoriosByProductID) {
      productSelected.otrosAccesorios = productSelectedMyList.otrosAccesorios
    }
  }

  return productSelected
}

function isProductItemHistory(
  productItem: UserHistoryRawItem | ProductSelected
): productItem is UserHistoryRawItem {
  return (<UserHistoryRawItem>productItem).acabadoId !== undefined
}

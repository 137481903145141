import React, { Dispatch, useState } from 'react'

import InfoIcon from '@mui/icons-material/Info'
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography
} from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

// redux
import { AppState } from '../../../config/types'
import { ProductAcabadosOtrosAccesorios, ProductSelected } from '../../../config/types/contentTypes'
import { DispatchAction } from '../../../config/types/contextTypes'
import { updateProductSelected } from '../../../context/actions/productSelectedActions'
import { useStateValue } from '../../../context/store'
import { themeCharofil } from '../../../styles/styles'
import Loading from '../../loading'
import { useStyles } from '../useStyles'

const OtrosAccesoriosForm: React.FC = () => {
  const classes = useStyles()
  const { state, dispatch }: { state: AppState; dispatch: Dispatch<DispatchAction> } =
    useStateValue()

  const productSelected: ProductSelected | undefined = state?.productSelected
  // const productResult = useSelector((state) => productResultById(state))
  const [tiposFilter, setTiposFilter] = useState<string>('0')
  const [page, setPage] = useState<number>(1)
  const paginationSize = 8

  const handleSearchOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!productSelected) return

    if (page > 1) setPage(1)

    await updateProductSelected(dispatch, {
      ...productSelected,
      searchOtrosAccesorios: event.target.value,
    })
  }

  const handleSelectFilterChange = (event: SelectChangeEvent) => {
    if (page > 1) setPage(1)
    setTiposFilter(event.target.value)
  }

  const displayTipoAccesorioFilter = (
    accesorios: ProductAcabadosOtrosAccesorios[],
    accesoriosRelated: ProductAcabadosOtrosAccesorios[]
  ) => {
    if (!accesorios && !accesoriosRelated) return false

    let displayFilter = false

    accesorios?.forEach((accesorio: ProductAcabadosOtrosAccesorios) => {
      if (accesorio.tipo) {
        displayFilter = true
        return
      }
    })

    accesoriosRelated?.forEach((accesorio: ProductAcabadosOtrosAccesorios) => {
      if (accesorio.tipo) {
        displayFilter = true
        return
      }
    })

    return displayFilter
  }

  const getTipoAccesorioFilterOps = (
    accesorios: ProductAcabadosOtrosAccesorios[],
    byProductID: ProductAcabadosOtrosAccesorios[],
    accesoriosRelated: ProductAcabadosOtrosAccesorios[]
  ) => {
    if (!accesorios && !byProductID) return []
    const options: string[] = []
    const sum = [...accesorios, ...byProductID]
    sum.forEach((accesorio) => {
      if (accesorio.tipo) {
        if (!options.includes(accesorio.tipo)) options.push(accesorio.tipo)
      }
    })

    return options.sort((a, b) => {
      if (a < b) return -1
      else if (a > b) return 1
      else return 0
    })
  }

  const handleNoPiezasOnChange = async (id: number, value: string) => {
    if (!productSelected) return

    const numbers = parseInt(value.replace(/[^0-9]/g, ''))
    const map = productSelected.otrosAccesorios
      ? productSelected.otrosAccesorios
      : new Map<number, number>()

    if (!Number.isNaN(numbers)) {
      map.set(id, numbers)
    } else if (map.has(id)) {
      map.delete(id)
    }

    await updateProductSelected(dispatch, {
      ...productSelected,
      otrosAccesorios: map,
    })
  }

  const [open, setOpen] = useState(false)
  const [dialogDescription, setDialogDescription] = useState({ title: '', description: '' })
  const handleOpenDialog = (title: string, description: string) => {
    const texts = {
      title: title,
      description: description,
    }
    setDialogDescription(texts)
    setOpen(true)
  }

  const handleCloseDialog = () => {
    setOpen(false)
  }

  const printItems = (accesorio: ProductAcabadosOtrosAccesorios, isByProduct: boolean) => {
    if (!productSelected) return false

    const isAll = tiposFilter === '0'

    if (
      (!accesorio.by_ancho &&
        !accesorio.by_productID &&
        isAll &&
        !productSelected.searchOtrosAccesorios &&
        !productSelected.searchOtrosAccesorios) ||
      (!isAll && accesorio.tipo === tiposFilter && !productSelected.searchOtrosAccesorios) ||
      (!isAll &&
        accesorio.tipo === tiposFilter &&
        productSelected.searchOtrosAccesorios &&
        accesorio.nombre
          .toLowerCase()
          .includes(productSelected.searchOtrosAccesorios.toLowerCase())) ||
      (isAll &&
        productSelected.searchOtrosAccesorios &&
        accesorio.nombre
          .toLowerCase()
          .includes(productSelected.searchOtrosAccesorios.toLowerCase()))
    ) {
      return true
    } else if (isByProduct) {
      if (
        (isAll &&
          !productSelected.searchOtrosAccesorios &&
          !productSelected.searchOtrosAccesorios) ||
        (!isAll && accesorio.tipo === tiposFilter && !productSelected.searchOtrosAccesorios) ||
        (!isAll &&
          accesorio.tipo === tiposFilter &&
          productSelected.searchOtrosAccesorios &&
          accesorio.nombre
            .toLowerCase()
            .includes(productSelected.searchOtrosAccesorios.toLowerCase())) ||
        (isAll &&
          productSelected.searchOtrosAccesorios &&
          accesorio.nombre
            .toLowerCase()
            .includes(productSelected.searchOtrosAccesorios.toLowerCase()))
      ) {
        return true
      }
    } else {
      const isSameAncho =
        accesorio.by_ancho !== null &&
        accesorio.by_ancho !== undefined &&
        productSelected.ancho?.ancho === accesorio.by_ancho

      if (
        (isSameAncho &&
          isAll &&
          !productSelected.searchOtrosAccesorios &&
          !productSelected.searchOtrosAccesorios) ||
        (isSameAncho &&
          !isAll &&
          accesorio.tipo === tiposFilter &&
          !productSelected.searchOtrosAccesorios) ||
        (isSameAncho &&
          !isAll &&
          accesorio.tipo === tiposFilter &&
          productSelected.searchOtrosAccesorios &&
          accesorio.nombre
            .toLowerCase()
            .includes(productSelected.searchOtrosAccesorios.toLowerCase())) ||
        (isSameAncho &&
          isAll &&
          productSelected.searchOtrosAccesorios &&
          accesorio.nombre
            .toLowerCase()
            .includes(productSelected.searchOtrosAccesorios.toLowerCase()))
      ) {
        return true
      }
    }

    return false
  }

  const printAccesorios = (accesorio: ProductAcabadosOtrosAccesorios, isByProduct: boolean) => {
    return printItems(accesorio, isByProduct) ? (
      <Grid item key={accesorio.id} xs={12} sm={6} md={4} lg={3}>
        <Card>
          <CardActionArea>
            <CardMedia
              component="img"
              alt={accesorio.nombre}
              image={accesorio.img ? accesorio.img : ''}
              title={accesorio.nombre}
              height="220"
              sx={{ padding: '1em 1em 0 0', objectFit: 'contain' }}
            />
            <CardContent className={classes.cardContent}>
              <Grid style={{ height: 45 }}>
                <Typography variant="subtitle2" gutterBottom>
                  {accesorio.nombre}
                </Typography>
              </Grid>
            </CardContent>
          </CardActionArea>
          <CardActions disableSpacing>
            <Grid container direction="column">
              <Grid item xs={12}>
                <ThemeProvider theme={themeCharofil}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id={`piezas-${accesorio.id}`}
                    label="Piezas"
                    name={`piezas-${accesorio.id}`}
                    value={getOtrosAccesoriosValue(accesorio.id)}
                    inputProps={{
                      style: { textAlign: 'center' },
                    }}
                    onChange={(event) => handleNoPiezasOnChange(accesorio.id, event.target.value)}
                  />
                </ThemeProvider>
                <Grid alignItems={'center'}>
                  <Tooltip title="Ver más" placement="top">
                    <IconButton
                      aria-label="Ver más"
                      onClick={() => handleOpenDialog(accesorio.nombre, accesorio.descripcion)}
                    >
                      <InfoIcon fontSize="small" style={{ color: '#BE0A15' }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    ) : null
  }

  const getOtrosAccesoriosValue = (id: number) => {
    if (!productSelected || !productSelected.otrosAccesorios) return ''

    if (productSelected.otrosAccesorios.has(id)) return productSelected.otrosAccesorios.get(id)
    else return ''
  }

  const mergeAccesorios = (
    accesorios: ProductAcabadosOtrosAccesorios[],
    accesoriosRelated: ProductAcabadosOtrosAccesorios[]
  ) => {
    let newAccesorios: ProductAcabadosOtrosAccesorios[] = []

    if (accesorios && accesoriosRelated) {
      newAccesorios = [...accesorios, ...accesoriosRelated]
    } else if (accesorios) {
      newAccesorios = [...accesorios]
    } else if (accesoriosRelated) {
      newAccesorios = [...accesoriosRelated]
    }

    return newAccesorios
  }

  const paginate = (list: JSX.Element[]) => {
    if (!list) return []

    const pageSize = Math.ceil(list.length / paginationSize)

    return Array.from({ length: pageSize }, (_, index) => {
      const start = index * paginationSize
      return list.slice(start, start + paginationSize)
    })
  }

  const getAccesorios = () => {
    if (!productSelected) return []
    const accesorios: JSX.Element[] = []

    if (
      productSelected.acabado?.otrosAccesorios ||
      productSelected.acabadoRelated?.otrosAccesorios
    ) {
      mergeAccesorios(
        productSelected.acabado?.otrosAccesorios ? productSelected.acabado?.otrosAccesorios : [],
        productSelected.acabadoRelated?.otrosAccesorios
          ? productSelected.acabadoRelated?.otrosAccesorios
          : []
      ).forEach((accesorio: ProductAcabadosOtrosAccesorios) => {
        const acc = printAccesorios(accesorio, false)
        if (acc) accesorios.push(acc)
      })
    }

    if (
      productSelected.otrosAccesoriosByProductID &&
      productSelected.otrosAccesoriosByProductID.length
    ) {
      productSelected.otrosAccesoriosByProductID.forEach(
        (accesorio: ProductAcabadosOtrosAccesorios) => {
          const acc = printAccesorios(accesorio, true)
          if (acc) accesorios.push(acc)
        }
      )
    }

    return accesorios
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  return productSelected ? (
    <Grid container direction="column" className={classes.cardContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ThemeProvider theme={themeCharofil}>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  id="metros"
                  name="metros"
                  label="Ancho de Derivación"
                  inputProps={{ style: { textAlign: 'center' } }}
                  value={productSelected.ancho?.ancho}
                  disabled
                />
              </ThemeProvider>
            </Grid>
            {displayTipoAccesorioFilter(
              productSelected.acabado?.otrosAccesorios
                ? productSelected.acabado?.otrosAccesorios
                : [],
              productSelected.acabadoRelated?.otrosAccesorios
                ? productSelected.acabadoRelated?.otrosAccesorios
                : []
            ) ? (
              <Grid item xs={12}>
                <FormControl className={classes.selectInput}>
                  <ThemeProvider theme={themeCharofil}>
                    <InputLabel id="accesorios-tipo-select-label">
                      Seleccione el tipo de Accesorio:
                    </InputLabel>
                    <Select
                      labelId="accesorios-tipo-select-label"
                      id="accesorios-tipo-select"
                      key="accesorios-tipo-select"
                      label="Seleccione el tipo de Accesorio:"
                      value={tiposFilter}
                      onChange={handleSelectFilterChange}
                    >
                      <MenuItem key={`accesorios-tipo-empty`} value={'0'}>
                        Todos
                      </MenuItem>
                      {getTipoAccesorioFilterOps(
                        productSelected.acabado?.otrosAccesorios
                          ? productSelected.acabado?.otrosAccesorios
                          : [],
                        productSelected.otrosAccesoriosByProductID,
                        productSelected.acabadoRelated?.otrosAccesorios
                          ? productSelected.acabadoRelated?.otrosAccesorios
                          : []
                      ).map((option) => (
                        <MenuItem key={`accesorios-tipo-${option}`} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </ThemeProvider>
                </FormControl>
              </Grid>
            ) : (
              ''
            )}
            <Grid item xs={12}>
              <ThemeProvider theme={themeCharofil}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="search"
                  label="Buscar"
                  name="search"
                  value={productSelected.searchOtrosAccesorios}
                  onChange={handleSearchOnChange}
                />
              </ThemeProvider>
            </Grid>
            {productSelected.acabado?.otrosAccesorios ? (
              <>
                <Grid item xs={12} display={'flex'} alignItems="center" justifyContent="center">
                  <ThemeProvider theme={themeCharofil}>
                    <Pagination
                      key="pagination-top"
                      hideNextButton
                      hidePrevButton
                      count={Math.ceil(getAccesorios().length / paginationSize)}
                      page={page}
                      onChange={handleChange}
                      color="primary"
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    {paginate(getAccesorios())[page - 1]}
                  </Grid>
                  <Grid item xs={12}>
                    <Dialog
                      fullWidth
                      maxWidth="sm"
                      open={open}
                      onClose={handleCloseDialog}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">{dialogDescription.title}</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          {dialogDescription.description}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <ThemeProvider theme={themeCharofil}>
                          <Button onClick={handleCloseDialog} variant="contained" color="primary">
                            Cerrar
                          </Button>
                        </ThemeProvider>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
                <Grid item xs={12} display={'flex'} alignItems="center" justifyContent="center">
                  <ThemeProvider theme={themeCharofil}>
                    <Pagination
                      key="pagination-bottom"
                      hideNextButton
                      hidePrevButton
                      count={Math.ceil(getAccesorios().length / paginationSize)}
                      page={page}
                      onChange={handleChange}
                      color="primary"
                    />
                  </ThemeProvider>
                </Grid>
              </>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Loading />
  )
}

export default OtrosAccesoriosForm

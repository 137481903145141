import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '100%',
    maxWidth: 700,
  },
  container: {
    width: 200,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderColor: '#a72529',
    borderRadius: 35,
  },
  title: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  selectInput: {
    width: '100%',
  },
  itemSelectedLeft: {
    ...flexCenter,
    width: 100,
    backgroundColor: '#a72529',
    color: 'white',
    cursor: 'pointer',
    borderTopLeftRadius: 35,
    borderBottomLeftRadius: 35,
    fontWeight: 'bold',
  },
  itemSelectedRight: {
    ...flexCenter,
    width: 100,
    backgroundColor: '#a72529',
    color: 'white',
    cursor: 'pointer',
    borderTopRightRadius: 35,
    borderBottomRightRadius: 35,
    fontWeight: 'bold',
  },
  item: {
    ...flexCenter,
    width: 100,
    backgroundColor: 'white',
    color: 'black',
    cursor: 'pointer',
    borderRadius: 35,
  },
}))

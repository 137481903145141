import { makeStyles } from '@mui/styles'

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const useStyles = makeStyles((theme) => ({
  carousel: {
    width: '90%',
  },
  homeTitle: {
    ...flexCenter,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  homeSubtitle: {
    ...flexCenter,
    paddingTop: '5px',
    paddingBottom: '10px',
  },
  carouselBoxShadow: {},
  carouselLegend: {
    maxHeight: '30px',
  },
  avatar: {
    backgroundColor: '#E90031',
  },
  paper: {
    ...flexCenter,
    flexDirection: 'column',
    backgroundColor: 'transparent',
  },
  detailsBtn: {
    color: 'white',
  },
  icon: {},

  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '70.25%', // 16:9
  },
  cardContentContainer: {
    backgroundColor: 'black',
  },
  cardContent: {
    flexGrow: 1,
    color: 'white',
    backgroundColor: '#630c08',
  },
  cardActions: {
    color: 'blue',
    backgroundColor: '#630c08',
  },
  sliderRoot: {
    height: '90vh',
    backgroundColor: 'black',
  },
  pwdReq: {
    color: 'red',
    fontStyle: 'italic',
  },
}))

import React, { Dispatch, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AccountCircle, LogoutRounded, Menu } from '@mui/icons-material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import HistoryIcon from '@mui/icons-material/History'
import HomeIcon from '@mui/icons-material/Home'
import ListAltIcon from '@mui/icons-material/ListAlt'
import { Box, Divider, Drawer, IconButton, List } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { AppState } from '../../config/types'
import { ProductContent } from '../../config/types/contentTypes'
import { DispatchAction } from '../../config/types/contextTypes'
import { UserSession } from '../../config/types/sessionTypes'
import { CHAROFIL_SESSION_TYPE, logout, logoutFromApp } from '../../context/actions/loginActions'
import { CHAROFIL_GUEST_SESSION_TYPE } from '../../context/actions/sessionActions'
import { useStateValue } from '../../context/store'
import ListItem from './ListItem'
import ListItemHeader from './ListItemHeader'

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    color: 'white !important',
    background: '#333 !important',
  },
})

const MenuDrawer: React.FC = () => {
  const { state, dispatch }: { state: AppState; dispatch: Dispatch<DispatchAction> } =
    useStateValue()
  const navigate = useNavigate()

  const classes = useStyles()

  const user: UserSession | undefined = state.userSession
  const [open, setOpen] = useState<boolean>(false)

  const productsContent: ProductContent[] = state.contentData ? state.contentData : []

  const openDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setOpen(open)
  }

  const handleProductOnClick = (productId: number) => {
    navigate({ pathname: '/product', search: `?id=${productId}` })
  }

  const getIconByType = (type: string | undefined) => {
    if (!type) return <ArrowRightIcon style={{ color: 'white' }} />

    switch (type) {
      case 'home':
        return <HomeIcon style={{ color: 'white' }} />

      case 'history':
        return <HistoryIcon style={{ color: 'white' }} />

      case 'myList':
        return <ListAltIcon style={{ color: 'white' }} />

      default:
        return <ArrowRightIcon style={{ color: 'white' }} />
    }
  }

  const buildMenuItems = (user: UserSession, productsContent: ProductContent[]) => {
    return (
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={openDrawer(false)}
        onKeyDown={openDrawer(false)}
      >
        <List>
          <ListItemHeader
            key="userListItem"
            keyName="user"
            text={
              user.sessionType !== CHAROFIL_SESSION_TYPE && user.name && user.last
                ? `${user.name} ${user.last}`
                : user.userName
            }
            image={undefined}
          />
        </List>
        <Divider style={{ backgroundColor: 'white', color: 'white' }} />
        <List>
          <ListItem
            key="menu-list-home"
            keyName="menu-list-home"
            text="Home"
            icon={getIconByType('home')}
            onClick={() => navigate({ pathname: '/home' })}
          />
        </List>
        {user.userType !== 1003 ? (
          <>
            <Divider style={{ backgroundColor: 'white', color: 'white' }} />
            <List>
              <ListItem
                key="menu-list-history"
                keyName="menu-list-history"
                text="Historial"
                icon={getIconByType('history')}
                onClick={() => navigate({ pathname: '/historial' })}
              />
              <ListItem
                key="menu-list-review"
                keyName="menu-list-review"
                text="Revisar mi lista"
                icon={getIconByType('myList')}
                onClick={() => navigate({ pathname: '/myList' })}
              />
            </List>
          </>
        ) : null}

        <Divider style={{ backgroundColor: 'white', color: 'white' }} />
        <List>
          <ListItem key="productsList" keyName={'products'} text={'Productos'} isButton={false} />
          {productsContent.map((product: ProductContent, index: number) => (
            <ListItem
              key={`list-item-product-${index}`}
              keyName={`item-product-${index}`}
              icon={<ArrowRightIcon style={{ color: 'white' }} />}
              text={product.nombre}
              onClick={() => handleProductOnClick(product.id)}
            />
          ))}
        </List>

        {user.sessionType === CHAROFIL_GUEST_SESSION_TYPE || user.externalDevice ? (
          <>
            <Divider style={{ backgroundColor: 'white', color: 'white' }} />
            <ListItem
              key="listRegresarApp"
              keyName={'RegresarApp'}
              icon={<LogoutRounded style={{ color: 'white' }} />}
              text={'Regresar a la App'}
              onClick={async () => {
                await logoutFromApp(dispatch)
                navigate('/')
              }}
            />
          </>
        ) : (
          <>
            <Divider style={{ backgroundColor: 'white', color: 'white' }} />
            <List>
              <ListItem
                key={'listConfiguración'}
                keyName={'Configuración'}
                icon={<AccountCircle style={{ color: 'white' }} />}
                text={'Cuenta'}
                onClick={async () => {
                  navigate('/account')
                }}
              />
              <ListItem
                key="listSalir"
                keyName={'Salir'}
                icon={<LogoutRounded style={{ color: 'white' }} />}
                text={'Cerrar Sesión'}
                onClick={async () => {
                  await logout(dispatch)
                  navigate('/')
                }}
              />
            </List>
          </>
        )}
      </Box>
    )
  }

  return user && productsContent ? (
    <React.Fragment key={'MenuDrawer'}>
      <IconButton
        size="medium"
        edge="start"
        color="inherit"
        aria-label="Open Menu"
        sx={{ mr: 2 }}
        onClick={openDrawer(true)}
      >
        <Menu style={{ color: 'white' }} />
      </IconButton>
      <Drawer
        anchor={'right'}
        open={open}
        onClose={openDrawer(false)}
        classes={{ paper: classes.paper }}
      >
        {buildMenuItems(user, productsContent)}
      </Drawer>
    </React.Fragment>
  ) : null
}

export default MenuDrawer

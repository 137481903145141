// Devices
export const DEVICE_WEB_ANDROID = 'Web Android'
export const DEVICE_WEB_IOS = 'Web IOS'
export const DEVICE_WEB = 'Web'
export const DEVICE_ANDROID = 'Android'
export const DEVICE_IOS = 'IOS'
export const DEVICE_WEB_ANDROID_ENCRYPTED = 'U2FsdGVkX1917MDKSc3Yt+4+1HGu0vhHXmDul7EGMQI='
export const DEVICE_WEB_IOS_ENCRYPTED = 'U2FsdGVkX1+2WBTkajZJcFn9256JWN6dNCUDfeS0qZ0='
export const DEVICE_WEB_ENCRYPTED = 'U2FsdGVkX1++Lrfb+Lir03iBimzNs9/C/hCdcU/xWIY='
export const DEVICE_ANDROID_ENCRYPTED = 'U2FsdGVkX185Z9O/UmEpjkK5AiKxvxom2yMc9ZMHq90='
export const DEVICE_IOS_ENCRYPTED = 'U2FsdGVkX1+tBviRo3IJE+opkk12AdT3uDG3dY4j6TY='

// LocalStorage Keys
export const USER_SESSION_KEY = 'UserSession'
export const SESSION_RESPONSE_KEY = 'SessionRS'

// TIME
export const TIME_FORMAT = "YYYY-MM-DD HH:mm:ss'Z"

import { jwtDecode } from 'jwt-decode'
import { Dispatch } from 'react'

import {
  API_ENDPOINT,
  API_VERSION,
  ROUTE_FACEBOOK_LOGIN,
  ROUTE_GOOGLE_LOGIN,
  ROUTE_LOGIN,
  SESSION_LOGOUT
} from '../../config'
import { DispatchAction } from '../../config/types/contextTypes'
import { WebViewMessage } from '../../config/types/navigationTypes'
import { UserSession } from '../../config/types/sessionTypes'
import { LOGIN_TYPE, LOGOUT_TYPE } from '../../context/reducers/loginReducer'
import { apiCall, getDevice, getOptions } from '../../util/axios'
import { USER_SESSION_KEY } from '../../util/constants'
import { getData } from '../../util/localStorage'

export const CHAROFIL_SESSION_TYPE = 'charofil'
export const APPLE_SESSION_TYPE = 'apple'
export const FACEBOOK_SESSION_TYPE = 'facebook'
export const GOOGLE_SESSION_TYPE = 'google'

export const login = async (dispatch: Dispatch<DispatchAction>, data: any): Promise<void> => {
  const loginResponse = await apiCall(
    await getOptions({
      endpoint: `${API_ENDPOINT}/${API_VERSION}/${ROUTE_LOGIN}`,
      httpMethod: 'POST',
      data: { userName: data.userName, password: data.userPassword, device: getDevice() },
    })
  ).catch((error) => {
    throw error
  })

  if (loginResponse?.status === 200 && loginResponse?.data) {
    const userSession: UserSession = {
      id: loginResponse.data.user.id,
      userType: loginResponse.data.user.userType,
      userName: loginResponse.data.user.userName,
      name: loginResponse.data.user.name,
      last: loginResponse.data.user.last,
      email: loginResponse.data.user.email,
      fechaRegistro: loginResponse.data.user.fechaRegistro,
      token: loginResponse.headers.token,
      endTime: loginResponse.data.expTime,
      sessionType: CHAROFIL_SESSION_TYPE,
    }

    dispatch({ type: LOGIN_TYPE, data: userSession })
  } else {
    alert('Usuario o contraseña inválidos. Por favor, intenta nuevamente.')
    // dispatch({ type: LOGIN_ERROR_TYPE, data: 'Usuario o contraseña inválidos' })
  }
}

export const fbLogin = async (dispatch: Dispatch<DispatchAction>, data: any): Promise<void> => {
  const fbLoginResponse = await apiCall(
    await getOptions({
      endpoint: `${API_ENDPOINT}/${API_VERSION}/${ROUTE_FACEBOOK_LOGIN}`,
      httpMethod: 'POST',
      data,
    })
  ).catch((error) => {
    throw error
  })

  if (fbLoginResponse?.data?.success && fbLoginResponse?.data?.user) {
    const userSession: UserSession = {
      id: fbLoginResponse.data.user.id,
      email: fbLoginResponse.data.user.email,
      name: fbLoginResponse.data.user.name,
      last: fbLoginResponse.data.user.last,
      userName: fbLoginResponse.data.user.userName,
      token: fbLoginResponse.headers.token,
      endTime: fbLoginResponse.data.expTime,
      userType: fbLoginResponse.data.userType,
      sessionType: FACEBOOK_SESSION_TYPE,
    }

    dispatch({ type: LOGIN_TYPE, data: userSession })
  } else {
    alert(fbLoginResponse.data.message)
    // dispatch({ type: GENERAL_ERROR_TYPE, data: 'Ocurrió un error al intentar iniciar sesión. Intente más tarde' })
  }
}
export const googleLogin = async (dispatch: Dispatch<DispatchAction>, data: any): Promise<void> => {
  const googleLoginResponse = await apiCall(
    await getOptions({
      endpoint: `${API_ENDPOINT}/${API_VERSION}/${ROUTE_GOOGLE_LOGIN}`,
      httpMethod: 'POST',
      data,
    })
  ).catch((error) => {
    throw error
  })

  if (googleLoginResponse?.data?.success && googleLoginResponse?.data?.user) {
    const userSession: UserSession = {
      id: googleLoginResponse.data.user.id,
      email: googleLoginResponse.data.user.email,
      name: googleLoginResponse.data.user.name,
      last: googleLoginResponse.data.user.last,
      userName: googleLoginResponse.data.user.userName,
      token: googleLoginResponse.headers.token,
      endTime: googleLoginResponse.data.expTime,
      userType: googleLoginResponse.data.userType,
      sessionType: GOOGLE_SESSION_TYPE,
    }

    dispatch({ type: LOGIN_TYPE, data: userSession })
  } else {
    alert(googleLoginResponse.data.message)
    // dispatch({ type: GENERAL_ERROR_TYPE, data: 'Ocurrió un error al intentar iniciar sesión. Intente más tarde' })
  }
}

export const logout = async (dispatch: Dispatch<DispatchAction>): Promise<void> => {
  const userSession: UserSession = await getUserSessionAsync()

  await apiCall(
    await getOptions({
      endpoint: `${API_ENDPOINT}/${API_VERSION}/${SESSION_LOGOUT}`,
      httpMethod: 'POST',
    })
  ).catch((error) => {
    throw error
  })

  switch (userSession.sessionType) {
    case CHAROFIL_SESSION_TYPE:
      dispatch({ type: LOGOUT_TYPE })
      break
    case APPLE_SESSION_TYPE:
      dispatch({ type: LOGOUT_TYPE })
      break
    case FACEBOOK_SESSION_TYPE:
      dispatch({ type: LOGOUT_TYPE })
      break
    case GOOGLE_SESSION_TYPE:
      dispatch({ type: LOGOUT_TYPE })
      break

    default:
      dispatch({ type: LOGOUT_TYPE })
      break
  }
}

export const logoutFromApp = async (dispatch: Dispatch<DispatchAction>): Promise<void> => {
  const userSession: UserSession = await getUserSessionAsync()

  switch (userSession.sessionType) {
    case CHAROFIL_SESSION_TYPE:
      dispatch({ type: LOGOUT_TYPE })
      break
    case APPLE_SESSION_TYPE:
      dispatch({ type: LOGOUT_TYPE })
      break
    case FACEBOOK_SESSION_TYPE:
      dispatch({ type: LOGOUT_TYPE })
      break
    case GOOGLE_SESSION_TYPE:
      dispatch({ type: LOGOUT_TYPE })
      break

    default:
      dispatch({ type: LOGOUT_TYPE })
      break
  }

  const webViewAction: WebViewMessage = {
    action: 'returnApp',
  }

  const window2: any = window
  window2.ReactNativeWebView.postMessage(JSON.stringify(webViewAction))
}

export const sessionExpired = async (dispatch: Dispatch<DispatchAction>): Promise<void> => {
  alert('Sesión expirada')
  await logout(dispatch)
}

export const getUserSessionAsync = async (): Promise<UserSession> => {
  const sessionResponse: UserSession = await getData(USER_SESSION_KEY)

  if (sessionResponse?.sessionType !== CHAROFIL_SESSION_TYPE) return sessionResponse
  return jwtDecode(sessionResponse.token)
}

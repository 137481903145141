import React from 'react'

import { IconButton, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'

interface ListItemProps {
  keyName: string
  text?: string
  icon?: React.ReactNode
  isButton?: boolean
  isButtonIcon?: boolean
  onClick?: () => void
}

const ListItemCustom: React.FC<ListItemProps> = ({
  keyName,
  text,
  icon,
  isButton = true,
  isButtonIcon = false,
  onClick,
}) => {
  if (!text && !icon) return null

  const buildText = () => {
    if (icon) {
      return <Typography variant="body2">{text}</Typography>
    } else {
      return (
        <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 'bold' }}>
          {text}
        </Typography>
      )
    }
  }

  if (isButtonIcon && icon) {
    return (
      <ListItem key={keyName} onClick={() => (onClick ? onClick() : null)}>
        <IconButton key={keyName}>{icon}</IconButton>
      </ListItem>
    )
  } else {
    return isButton ? (
      <ListItem button key={keyName} onClick={() => (onClick ? onClick() : null)}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        {text ? <ListItemText primary={buildText()} /> : null}
      </ListItem>
    ) : (
      <ListItem key={keyName} onClick={() => (onClick ? onClick() : null)}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        {text ? <ListItemText primary={buildText()} /> : null}
      </ListItem>
    )
  }
}

export default ListItemCustom

export const storeData = (key: string, value: any): void => {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    console.log('Error on StoreData: ', e)
  }
}

export const getData = (key: string): Promise<any | undefined> | undefined => {
  try {
    const value: string | null = localStorage.getItem(key)
    if (value) {
      return JSON.parse(value)
    } else {
      return undefined
    }
  } catch (e) {
    console.log(e)
  }
}

export const getDataSync = (key: string): any | undefined => {
  try {
    const value: string | null = localStorage.getItem(key)
    if (value) {
      return JSON.parse(value)
    } else {
      return undefined
    }
  } catch (e) {
    console.log(e)
  }
}

export const removeData = (key: string): void => {
  try {
    localStorage.removeItem(key)
  } catch (e) {
    console.log(e)
  }
}

import React from 'react'

import { Grid, Typography } from '@mui/material'

import { useGeneralStyles } from '../../util/useGeneralStyles'
import UserForm from './userConfig'

// import UserPreferences from './userPreferencesConfig'

const ConfigurationsPage: React.FC = () => {
  const generalClasses = useGeneralStyles()

  return (
    <Grid container className={generalClasses.generalRoot}>
      <Grid container className={generalClasses.generalContainerPage}>
        <Grid container className={generalClasses.generalContainerCard2} direction="column">
          <Grid item xs={12} className={generalClasses.generalCardItemDetails}>
            <Typography
              component="h1"
              variant="h6"
              color="black"
              textAlign="center"
              marginBottom={1}
            >
              Configurar Mi Cuenta
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <UserForm />
          </Grid>
          {/* <Grid item xs={12}>
            <UserPreferences />
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ConfigurationsPage

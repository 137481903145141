import React, { Dispatch, useEffect } from 'react'

import { AppState } from '../config/types'
import { initializeState } from '../context/actions/appStatusActions'
import { useStateValue } from '../context/store'
import Error from '../pages/error'
import Loading from '../pages/loading'
import WrapperScreen from './index'

// localStorage.clear()

const InitApp: React.FC = () => {
  const { state, dispatch }: { state: AppState; dispatch: Dispatch<any> } = useStateValue()
  const initializeStore = async () => {
    await initializeState(dispatch)
  }

  async function initialize() {
    await initializeStore()
  }

  useEffect(() => {
    if (!state.status.initialized && !state.status.isLoading && !state.error) {
      initialize()
    }
  })

  return state.status.initialized && !state.status.isLoading && !state.error ? (
    <WrapperScreen />
  ) : state.status.isLoading ? (
    <Loading />
  ) : (
    <Error />
  )
}

export default InitApp

import { makeStyles } from '@mui/styles'

const center = {
  alignItems: 'center',
  justifyContent: 'center',
}

export const useStyles = makeStyles(() => ({
  itemProgress: {
    ...center,
  },
  itemImage: {
    ...center,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 10,
  },
  image: {
    objectFit: 'cover',
    maxWidth: 280,
    maxHeight: 280,
  },
}))

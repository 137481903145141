import { makeStyles } from '@mui/styles'

const center = {
  alignItems: 'center',
  justifyContent: 'center',
}

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const useGeneralStyles = makeStyles((theme) => ({
  footer: {
    ...flexCenter,
    backgroundColor: '#000',
    minHeight: 35,
  },
  generalRoot: {
    ...flexCenter,
    minHeight: '100vh',
    backgroundColor: '#e5e5e5',
  },
  generalLoadingRoot: {
    ...flexCenter,
    minHeight: '100vh',
    backgroundColor: '#630c00',
  },
  generalContainer: {
    ...flexCenter,
    marginTop: 30,
    marginBottom: 30,
  },
  generalContainerPage: {
    ...flexCenter,
    maxWidth: '80%',
    marginTop: 30,
    marginBottom: 30,
  },
  generalContainerPageTabs: {
    ...flexCenter,
    maxWidth: '80%',
    marginTop: 30,
    boxShadow: '0 3px 5px 2px rgba(121, 121, 121, .5)',
    backgroundColor: 'white',
  },
  generalContainerPage2: {
    ...flexCenter,
    maxWidth: '90%',
    marginTop: 10,
    marginBottom: 10,
  },
  generalContainerCard: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    paddingTop: 20,
    paddingBottom: 30,
  },
  generalContainerCard2: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  generalContainerItem: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  generalContainerCenterItem: {
    ...flexCenter,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  generalContainerCardItem: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  generalTableContainerCard: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  generalTitle: {
    ...flexCenter,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  generalSubtitle: {
    ...flexCenter,
    paddingTop: '5px',
    paddingBottom: '10px',
  },
  generalAvatar: {
    backgroundColor: '#E90031',
  },
  generalAvatarUser: {},
  generalCenterItems: {
    ...flexCenter,
  },
  generalCenterItemsWithoutFlex: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  generalCardItemImage: {
    ...center,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  generalCardItemDetails: {
    height: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 20,
  },
  generalProfile: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    marginTop: 5,
    marginBottom: 5,
  },
  generalAccordion: {
    width: '100%',
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: '#f8f8f8 !important',
  },
  generalAccordionSummary: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  generalAccordionDetailsContainer: {
    width: '100%',
    backgroundColor: '#f8f8f8 !important',
  },
}))

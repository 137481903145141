import { Dispatch } from 'react'

import { API_ENDPOINT, API_VERSION, ROUTE_SESSION } from '../../config'
import { DispatchAction } from '../../config/types/contextTypes'
import { UserSession } from '../../config/types/sessionTypes'
import { apiCall, getOptions } from '../../util/axios'
import { USER_SESSION_KEY } from '../../util/constants'
import { removeData } from '../../util/localStorage'
import { STATUS_TYPE } from '../reducers/generalErrorReducer'
import { LOGIN_TYPE } from '../reducers/loginReducer'
import { activateLoading, deactivateLoading } from './appStatusActions'

export const CHAROFIL_GUEST_SESSION_TYPE = 'charofil-guest'

export const validateGuestSession = async (
  dispatch: Dispatch<DispatchAction>,
  token: string,
  device: string
): Promise<void> => {
  activateLoading(dispatch)
  const sessionResponse = await apiCall(
    await getOptions({
      endpoint: `${API_ENDPOINT}/${API_VERSION}/${ROUTE_SESSION}`,
      httpMethod: 'POST',
      data: { device: device },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).catch((error) => {
    throw error
  })

  if (sessionResponse?.status === 200 && sessionResponse?.data && sessionResponse?.data?.success) {
    const userSession: UserSession = {
      id: sessionResponse.data.user.id,
      userType: sessionResponse.data.user.userType,
      userName: sessionResponse.data.user.userName,
      name: sessionResponse.data.user.name,
      last: sessionResponse.data.user.last,
      email: sessionResponse.data.user.email,
      fechaRegistro: sessionResponse.data.user.fechaRegistro,
      token: token,
      endTime: sessionResponse.data.expTime,
      sessionType: CHAROFIL_GUEST_SESSION_TYPE,
      externalDevice: device,
    }

    dispatch({ type: LOGIN_TYPE, data: userSession })
    setTimeout(() => {}, 5000)
  } else {
    removeData(USER_SESSION_KEY)
    dispatch({
      type: STATUS_TYPE,
      data: 'Ha ocurrido un error al intentar acceder a Charofil. Por favor, intente más tarde',
    })
  }
  deactivateLoading(dispatch)
}

import { Dispatch } from 'react'

import { API_ENDPOINT, API_VERSION, ROUTE_GET_PRODUCTS } from '../../config'
import {
  ProductAcabadosPeraltesAnchosDerivaciones,
  ProductContent,
  ProductSelected,
  UserHistoryRawItem
} from '../../config/types/contentTypes'
import { DispatchAction } from '../../config/types/contextTypes'
import { apiCall, getOptions } from '../../util/axios'
import { APP_PRODUCT_TYPE } from '../reducers/productReducer'
import { activateLoading, deactivateLoading } from './appStatusActions'
import { addToMyList } from './myListActions'
import { getProductComplementForPreLoad } from './productComplementActions'
import {
  buildProductSelected,
  preLoadProductSelected,
  preloadProductSelectedHistory,
  preloadProductSelectedMyList
} from './productSelectedActions'

export const getProductById = async (
  dispatch: Dispatch<DispatchAction>,
  productId: number
): Promise<void> => {
  if (!productId) {
    return
  }

  activateLoading(dispatch)

  const productsContentResponse = await getProductByIdFromApi(productId)

  if (productsContentResponse?.status === 200) {
    const derivacionesByAncho = new Map<string, ProductAcabadosPeraltesAnchosDerivaciones[]>()
    for (const [key, value] of Object.entries(productsContentResponse.data.derivacionesByAncho)) {
      derivacionesByAncho.set(`${key}`, value as [])
    }

    const product: ProductContent = {
      id: productsContentResponse.data.id,
      nombre: productsContentResponse.data.nombre,
      descripcion: productsContentResponse.data.descripcion,
      perfil: productsContentResponse.data.perfil,
      tabs: productsContentResponse.data.tabs,
      img: productsContentResponse.data.img,
      acabados: productsContentResponse.data.acabados,
      derivacionesByAncho: derivacionesByAncho,
      otrosAccesoriosByProductID: productsContentResponse.data.otrosAccesoriosByProductID,
      puestaTierra: productsContentResponse.data.puestaTierra,
      productsRelated: productsContentResponse.data.productsRelated,
    }

    dispatch({ type: APP_PRODUCT_TYPE, data: product })
    await buildProductSelected(dispatch, product)
  } else {
    alert(productsContentResponse.data.message)
    // dispatch({ type: GENERAL_ERROR_TYPE, data: 'Ocurrió un error al intentar iniciar sesión. Intente más tarde' })
  }

  deactivateLoading(dispatch)
}

export const getProductByIdHistory = async (
  dispatch: Dispatch<DispatchAction>,
  userHistoryRaw: UserHistoryRawItem
): Promise<void> => {
  activateLoading(dispatch)

  const productsContentResponse = await getProductByIdFromApi(userHistoryRaw.productId)

  if (productsContentResponse?.status === 200) {
    const derivacionesByAncho = new Map<string, ProductAcabadosPeraltesAnchosDerivaciones[]>()
    for (const [key, value] of Object.entries(productsContentResponse.data.derivacionesByAncho)) {
      derivacionesByAncho.set(`${key}`, value as [])
    }

    const product: ProductContent = {
      id: productsContentResponse.data.id,
      nombre: productsContentResponse.data.nombre,
      descripcion: productsContentResponse.data.descripcion,
      perfil: productsContentResponse.data.perfil,
      tabs: productsContentResponse.data.tabs,
      img: productsContentResponse.data.img,
      acabados: productsContentResponse.data.acabados,
      derivacionesByAncho: derivacionesByAncho,
      otrosAccesoriosByProductID: productsContentResponse.data.otrosAccesoriosByProductID,
      puestaTierra: productsContentResponse.data.puestaTierra,
      productsRelated: productsContentResponse.data.productsRelated,
    }

    dispatch({ type: APP_PRODUCT_TYPE, data: product })

    let productComplement = undefined
    if (userHistoryRaw.peralteId && userHistoryRaw.anchoId) {
      productComplement = await getProductComplementForPreLoad(
        dispatch,
        userHistoryRaw.productId,
        userHistoryRaw.peralteId,
        userHistoryRaw.anchoId
      )
    }

    await preloadProductSelectedHistory(dispatch, product, userHistoryRaw, productComplement)
  } else {
    alert(productsContentResponse.data.message)
    // dispatch({ type: GENERAL_ERROR_TYPE, data: 'Ocurrió un error al intentar iniciar sesión. Intente más tarde' })
  }

  deactivateLoading(dispatch)
}

export const getProductsByHistory = async (
  dispatch: Dispatch<DispatchAction>,
  historyRaw: UserHistoryRawItem[]
): Promise<void> => {
  activateLoading(dispatch)

  const productsSelected: ProductSelected[] = []

  await Promise.all(
    historyRaw.map(async (historyItem) => {
      const productsContentResponse = await getProductByIdFromApi(historyItem.productId)

      if (productsContentResponse?.status === 200) {
        const derivacionesByAncho = new Map<string, ProductAcabadosPeraltesAnchosDerivaciones[]>()
        for (const [key, value] of Object.entries(
          productsContentResponse.data.derivacionesByAncho
        )) {
          derivacionesByAncho.set(`${key}`, value as [])
        }

        const product: ProductContent = {
          id: productsContentResponse.data.id,
          nombre: productsContentResponse.data.nombre,
          descripcion: productsContentResponse.data.descripcion,
          perfil: productsContentResponse.data.perfil,
          tabs: productsContentResponse.data.tabs,
          img: productsContentResponse.data.img,
          acabados: productsContentResponse.data.acabados,
          derivacionesByAncho: derivacionesByAncho,
          otrosAccesoriosByProductID: productsContentResponse.data.otrosAccesoriosByProductID,
          puestaTierra: productsContentResponse.data.puestaTierra,
          productsRelated: productsContentResponse.data.productsRelated,
        }

        let productComplement = undefined
        if (historyItem.peralteId && historyItem.anchoId) {
          productComplement = await getProductComplementForPreLoad(
            dispatch,
            historyItem.productId,
            historyItem.peralteId,
            historyItem.anchoId
          )
        }

        const productSelected = await preLoadProductSelected(
          product,
          historyItem,
          productComplement
        )
        if (productSelected) productsSelected.push(productSelected)
      } else {
        alert(productsContentResponse.data.message)
        // dispatch({ type: GENERAL_ERROR_TYPE, data: 'Ocurrió un error al intentar iniciar sesión. Intente más tarde' })
      }
    })
  )

  await addToMyList(dispatch, productsSelected)

  deactivateLoading(dispatch)
}

export const getProductByIdMyList = async (
  dispatch: Dispatch<DispatchAction>,
  productSelected: ProductSelected
) => {
  activateLoading(dispatch)

  const productsContentResponse = await getProductByIdFromApi(productSelected.id)
  if (productsContentResponse?.status === 200) {
    const derivacionesByAncho = new Map<string, ProductAcabadosPeraltesAnchosDerivaciones[]>()
    for (const [key, value] of Object.entries(productsContentResponse.data.derivacionesByAncho)) {
      derivacionesByAncho.set(`${key}`, value as [])
    }

    const product: ProductContent = {
      id: productsContentResponse.data.id,
      nombre: productsContentResponse.data.nombre,
      descripcion: productsContentResponse.data.descripcion,
      perfil: productsContentResponse.data.perfil,
      tabs: productsContentResponse.data.tabs,
      img: productsContentResponse.data.img,
      acabados: productsContentResponse.data.acabados,
      derivacionesByAncho: derivacionesByAncho,
      otrosAccesoriosByProductID: productsContentResponse.data.otrosAccesoriosByProductID,
      puestaTierra: productsContentResponse.data.puestaTierra,
      productsRelated: productsContentResponse.data.productsRelated,
    }

    dispatch({ type: APP_PRODUCT_TYPE, data: product })

    let productComplement = undefined
    if (productSelected.peralte?.id && productSelected.ancho?.id) {
      productComplement = await getProductComplementForPreLoad(
        dispatch,
        productSelected.id,
        productSelected.peralte.id,
        productSelected.ancho.id
      )
    }

    await preloadProductSelectedMyList(dispatch, product, productSelected, productComplement)
  } else {
    alert(productsContentResponse.data.message)
    // dispatch({ type: GENERAL_ERROR_TYPE, data: 'Ocurrió un error al intentar iniciar sesión. Intente más tarde' })
  }

  deactivateLoading(dispatch)
}

const getProductByIdFromApi = async (productId: number) => {
  const productsContentResponse = await apiCall(
    await getOptions({
      endpoint: `${API_ENDPOINT}/${API_VERSION}/${ROUTE_GET_PRODUCTS}`,
      httpMethod: 'POST',
      data: {
        product_id: `${productId}`,
      },
    })
  ).catch((error) => {
    throw error
  })

  return productsContentResponse
}

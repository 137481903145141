import React, { Dispatch, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Grid } from '@mui/material'

import { AppState } from '../../config/types'
import { Content } from '../../config/types/contentTypes'
import { DispatchAction } from '../../config/types/contextTypes'
import { getContent } from '../../context/actions/contentActions'
import { useStateValue } from '../../context/store'
import withRouter from '../../hooks/UseWithRouter'
import Footer from '../footer'
import Loading from '../loading'
import ForgotPasswordForm from './forms/forgotPasswordForm'
import LoginForm from './forms/loginForm'
import NewAccountForm from './forms/newAccountForm'

const Login: React.FC = () => {
  const navigate = useNavigate()
  const { state, dispatch }: { state: AppState; dispatch: Dispatch<DispatchAction> } =
    useStateValue()

  const content: Content | undefined = state?.content

  const [background, setBackground] = useState<any | undefined>(undefined)
  const [showCreateAccount, setShowCreateAccount] = useState(false)
  const [showForgotPassword, setShowForgotPassword] = useState(false)

  useEffect(() => {
    async function callGetContent() {
      await getContent(dispatch)
    }

    async function setBackgroundImg() {
      if (!content?.resources?.images?.login) return ''

      const size: number = Object.keys(content.resources.images.login).length
      const random: number = Math.floor(Math.random() * (size - 1 + 1) + 1)

      let url =
        random === 1
          ? content.resources.images.login.loginBG1.imagen
          : random === 2
          ? content.resources.images.login.loginBG2.imagen
          : random === 3
          ? content.resources.images.login.loginBG3.imagen
          : random === 4
          ? content.resources.images.login.loginBG4.imagen
          : random === 5
          ? content.resources.images.login.loginBG5.imagen
          : ''

      setBackground({
        backgroundSize: 'cover',
        backgroundImage: `url(${url})`,
      })
    }

    if (!content) {
      callGetContent()
    } else if (!background) {
      setBackgroundImg()
    }
  }, [background, content, dispatch, state.content?.success])

  const onCreateAccount = () => {
    setShowForgotPassword(false)
    setShowCreateAccount(true)
  }
  const onForgotPassword = () => {
    setShowCreateAccount(false)
    setShowForgotPassword(true)
  }
  const onShowLoginForm = () => {
    setShowCreateAccount(false)
    setShowForgotPassword(false)
  }

  return content ? (
    <Grid container component="main">
      <Grid item xs={false} sm={5} md={7} style={{ ...background, minHeight: '100vh' }} />
      <Grid item xs={12} sm={7} md={5} style={{ width: '100%', backgroundColor: 'white' }}>
        {showCreateAccount && !showForgotPassword ? (
          <NewAccountForm onCancel={onShowLoginForm} />
        ) : showForgotPassword && !showCreateAccount ? (
          <ForgotPasswordForm onCancel={onShowLoginForm} />
        ) : (
          <LoginForm
            labels={content.labels}
            socialNetworks={content.socialNetworks}
            onCreateAccount={onCreateAccount}
            onForgotPassword={onForgotPassword}
          />
        )}
      </Grid>
      <Footer position="relative" />
    </Grid>
  ) : (
    <Loading />
  )
}

export default withRouter(Login)

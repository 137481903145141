import React, { Dispatch, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
  Avatar,
  Badge,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'

import { ButtonSubmit } from '../../components/Button'
import LoadingPage from '../../components/LoadingPage'
import { AppState } from '../../config/types'
import { DispatchAction } from '../../config/types/contextTypes'
import { UserSession } from '../../config/types/sessionTypes'
import { UserUpdate } from '../../config/types/userTypes'
import { updateUser } from '../../context/actions/usersAction'
import { useStateValue } from '../../context/store'
import { useGeneralStyles } from '../../util/useGeneralStyles'

const UserConfig: React.FC = () => {
  const { state, dispatch }: { state: AppState; dispatch: Dispatch<DispatchAction> } =
    useStateValue()
  const navigate = useNavigate()
  const generalClasses = useGeneralStyles()

  const user: UserSession | undefined = state?.userSession

  const [name, setName] = useState<string>(user?.name ? user.name : '')
  const [lastName, setLastName] = useState<string>(user?.last ? user.last : '')
  // const [dob, setDob] = useState<string>(user?.dob ? user.dob : '')
  const [password, setPassword] = useState<string>('')
  // const [lang, setLang] = useState<string>(user?.lang ? user.lang : 'es')
  const [error, setError] = useState<Map<string, string>>(new Map<string, string>())
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const [showLoading, setShowLoading] = useState<boolean>(true)

  useEffect(() => {
    setTimeout(() => setShowLoading(false), 500)
  }, [])

  const colorDefault = 'primary'
  const [userNameColor, setUserNameColor] = useState<
    'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | undefined
  >(colorDefault)
  const [nameColor, setNameColor] = useState<
    'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | undefined
  >(colorDefault)
  const [lastColor, setLastColor] = useState<
    'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | undefined
  >(colorDefault)
  const [dobColor, setDOBColor] = useState<
    'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | undefined
  >(colorDefault)
  const [emailColor, setEmailColor] = useState<
    'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | undefined
  >(colorDefault)
  const [passwordColor, setPasswordColor] = useState<
    'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | undefined
  >(colorDefault)

  const removeError = (key: string) => {
    setError((prev) => {
      const newState = new Map(prev)
      newState.delete(key)
      return newState
    })
  }

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.trim()

    const key = 'name'
    if (!val || val.length < 3) {
      if (!error.has(key))
        setError((prev) => new Map(prev).set(key, 'El nombre debe tener un mínimo de 3 caracteres'))

      setNameColor('error')
    } else {
      removeError(key)
      setNameColor('success')
    }

    setName(val)
  }
  const onChangeLast = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.trim()

    const key = 'last'
    if (!val || val.length < 3) {
      if (!error.has(key))
        setError((prev) =>
          new Map(prev).set(key, 'Los apellidos deben tener un mínimo de 3 caracteres')
        )

      setLastColor('error')
    } else {
      removeError(key)
      setLastColor('success')
    }

    setLastName(val)
  }
  // const onChangeDOB = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const val = e.target.value.trim()

  //   const key = 'dob'
  //   if (!val) {
  //     if (!error.has(key))
  //       setError((prev) => new Map(prev).set(key, 'La fecha de nacimiento no puede estar vacía'))

  //     setDOBColor('error')
  //   } else {
  //     removeError(key)
  //     setDOBColor('success')
  //   }

  //   setDob(val)
  // }
  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.trim()

    const key = 'password'
    if (!val || val.length < 8) {
      if (!error.has(key))
        setError((prev) =>
          new Map(prev).set(key, 'La contraseña no puede tener menos de 8 caracteres')
        )

      setPasswordColor('error')
    } else {
      removeError(key)
      setPasswordColor('success')
    }

    setPassword(val)
  }

  // const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setLang(e.target.value)
  // }

  const onSave = () => {
    if (error.size > 0 || user === undefined) return

    const userRequest: UserUpdate = {
      id: user.id,
      username: user.userName,
      name: name,
      lastname: lastName,
      email: user.email,
    }

    if (password) {
      userRequest.password = password
    }

    updateUser(dispatch, userRequest, user)
    navigate('/home')
  }

  const handleShowPassword = () => {
    setShowPassword(showPassword ? false : true)
  }

  return state?.userSession && user && !showLoading ? (
    <Grid container className={generalClasses.generalContainerCard}>
      <Grid container xs={12} md={8}>
        <Grid
          item
          xs={12}
          className={generalClasses.generalContainerCenterItem}
          style={{ paddingTop: 20, paddingBottom: 30 }}
        >
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <IconButton style={{ backgroundColor: '#e3e3e3' }}>
                <ModeEditOutlinedIcon color="inherit" />
              </IconButton>
            }
          >
            <Avatar
              alt={'user-img'}
              // src={user.image.image}
              sx={{ width: 200, height: 200 }}
            />
          </Badge>
        </Grid>
        <Grid item xs={12} md={6} className={generalClasses.generalContainerCardItem}>
          <FormControl fullWidth>
            <TextField
              label="Nombre de Usuario"
              variant="outlined"
              value={user.userName}
              color={userNameColor}
              disabled={true}
            />
          </FormControl>
          {error.has('userName') ? (
            <Grid item xs={12}>
              <Typography color="red" variant="body2">
                {error.get('userName')}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12} md={6} className={generalClasses.generalContainerCardItem}>
          <FormControl fullWidth>
            <TextField
              label="Correo"
              variant="outlined"
              value={user.email}
              color={emailColor}
              disabled={true}
            />
          </FormControl>
          {error.has('email') ? (
            <Grid item xs={12}>
              <Typography color="red" variant="body2">
                {error.get('email')}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12} md={6} className={generalClasses.generalContainerCardItem}>
          <FormControl fullWidth>
            <TextField
              label="Nombre(s)"
              variant="outlined"
              value={name}
              onChange={onChangeName}
              color={nameColor}
            />
          </FormControl>
          {error.has('name') ? (
            <Grid item xs={12}>
              <Typography color="red" variant="body2">
                {error.get('name')}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12} md={6} className={generalClasses.generalContainerCardItem}>
          <FormControl fullWidth>
            <TextField
              label="Apellido(s)"
              variant="outlined"
              value={lastName}
              onChange={onChangeLast}
              color={lastColor}
            />
          </FormControl>
          {error.has('last') ? (
            <Grid item xs={12}>
              <Typography color="red" variant="body2">
                {error.get('last')}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        {/* <Grid item xs={12} md={6} className={generalClasses.generalContainerCardItem}>
          <FormControl fullWidth>
            <TextField
              label="Fecha de Nacimiento"
              variant="outlined"
              value={dob}
              onChange={onChangeDOB}
              color={dobColor}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          {error.has('dob') ? (
            <Grid item xs={12}>
              <Typography color="red" variant="body2">
                {error.get('dob')}
              </Typography>
            </Grid>
          ) : null}
        </Grid> */}
        {/* <Grid item xs={12} md={6} className={generalClasses.generalContainerCardItem}> */}
        <Grid item xs={12} className={generalClasses.generalContainerCardItem}>
          <FormControl fullWidth style={{ alignContent: 'center', justifyContent: 'center' }}>
            <TextField
              label="Cambiar contraseña"
              variant="outlined"
              value={password}
              type={showPassword ? 'text' : 'password'}
              onChange={onChangePassword}
              color={passwordColor}
            />
            <Tooltip
              title={!showPassword ? 'Ver Contraseña' : 'Ocultar Contraseña'}
              placement="top"
              style={{
                position: 'absolute',
                right: 22,
              }}
            >
              <IconButton aria-label="Ver Contraseña" onClick={handleShowPassword}>
                {showPassword ? (
                  <VisibilityOffIcon fontSize="medium" />
                ) : (
                  <VisibilityIcon fontSize="medium" />
                )}
              </IconButton>
            </Tooltip>
          </FormControl>
          {error.has('password') ? (
            <Grid item xs={12}>
              <Typography color="red" variant="body2">
                {error.get('password')}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        {/* <Grid item xs={12} md={6} className={generalClasses.generalContainerCardItem}>
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Idioma
            </InputLabel>
            <NativeSelect value={lang} style={{ textAlignLast: 'center' }} onChange={onChangeLang}>
              {state.languages.map((lang, index) => (
                <option key={`language-${index}`} value={lang}>
                  {lang}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </Grid> */}
        <Grid item xs={12} className={generalClasses.generalContainerCardItem}>
          <Grid item xs={12}>
            <Typography variant="body2">Fecha de creación: {user.fechaRegistro}</Typography>
          </Grid>
          {/* {user.updatedAt ? (
            <Grid item xs={12}>
              <Typography variant="body2">Actualizado: {user.createdAt}</Typography>
            </Grid>
          ) : null} */}
        </Grid>
      </Grid>
      <Grid item xs={12} className={generalClasses.generalContainerCardItem}>
        <Grid container spacing={2} direction="row">
          <Grid item xs={12} md={6}>
            <ButtonSubmit text={'Guardar'} width={200} onClick={onSave} />
          </Grid>
          <Grid item xs={12} md={6}>
            <ButtonSubmit
              text="Cancelar"
              color="red"
              width={200}
              onClick={() => navigate('/home')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <LoadingPage />
  )
}

export default UserConfig

import React, { useState } from 'react'

import {
  Avatar,
  Button,
  FormControl,
  Grid,
  TextField,
  ThemeProvider,
  Typography
} from '@mui/material'

import { recoveryPassword } from '../../../context/actions/usersAction'
import { themeCharofil } from '../../../styles/styles'
import { useStyles } from '../useStyles'

interface LoginFormProps {
  onCancel: () => void
}

const ForgotPasswordForm: React.FC<LoginFormProps> = ({ onCancel }) => {
  const classes = useStyles()

  const [userName, setUserName] = useState<string>('')
  const [email, setEmail] = useState('')

  const [btnBlocked, setBtnBlocked] = useState(false)
  const [error, setError] = useState('')

  const validateUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value.trim())
  }

  const onEnterKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key.toLocaleLowerCase() === 'enter') handleRecovery()
  }

  const handleRecovery = async () => {
    if (!userName || !email) return

    setBtnBlocked(true)

    const responseMsg: string | undefined = await recoveryPassword({ userName, email })
    if (responseMsg) {
      setError(responseMsg)
    } else {
      onCancel()
    }

    setBtnBlocked(false)
  }

  return (
    <Grid container direction="column" className={classes.cardContainer}>
      <Grid container style={{ padding: 10 }}>
        <Grid item xs={12} style={{ padding: 20 }}>
          {/* Español | English | Portuguese */}
        </Grid>
        <Grid item xs={12} style={{ padding: 20 }}>
          <Grid
            xs={12}
            display={'flex'}
            alignItems="center"
            justifyContent="center"
            style={{ padding: 10 }}
          >
            <Avatar sx={{ bgcolor: '#E90031' }}></Avatar>
          </Grid>
          <Grid xs={12} display={'flex'} alignItems="center" justifyContent="center">
            <Typography component="h1" variant="h5">
              Recuperar Contraseña
            </Typography>
          </Grid>

          <Grid container direction="column">
            <Grid item xs={12}>
              <FormControl fullWidth>
                <ThemeProvider theme={themeCharofil}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    required
                    id="user"
                    name="user"
                    label={'Usuario'}
                    value={userName}
                    onChange={validateUserName}
                    onKeyUp={onEnterKeyPress}
                    inputProps={{
                      style: { textAlign: 'center' },
                    }}
                  />
                </ThemeProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <ThemeProvider theme={themeCharofil}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    required
                    id="email"
                    name="email"
                    label={'Email'}
                    value={email}
                    onChange={(event) => {
                      const value = event.target.value
                        .trim()
                        .replace(/[^a-zA-Z0-9.@_-]+/g, '')
                        .replace('  ', ' ')
                      setEmail(value)
                    }}
                    onKeyUp={onEnterKeyPress}
                    inputProps={{
                      style: { textAlign: 'center' },
                    }}
                  />
                </ThemeProvider>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item justifyItems="center">
            <Typography component="h5" variant="body2" color={'error'}>
              {error}
            </Typography>
          </Grid>

          <Grid style={{ width: '100%', paddingTop: 20, paddingBottom: 10 }}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              onClick={handleRecovery}
              style={{ backgroundColor: '#BE0A15' }}
              disabled={btnBlocked}
            >
              Recuperar
            </Button>
          </Grid>
          <Grid style={{ width: '100%', paddingTop: 10, paddingBottom: 20 }}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              onClick={onCancel}
              style={{ backgroundColor: '#BE0A15' }}
              disabled={btnBlocked}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ForgotPasswordForm

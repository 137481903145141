import React from 'react'

import { Button, Typography } from '@mui/material'
import { styled } from '@mui/styles'

import { SubmitStyledBtnProps } from './types'

const blue =
  'linear-gradient(90deg, rgba(29,22,148,1) 0%, rgba(9,9,121,1) 19%, rgba(29,112,145,1) 81%, rgba(9,15,125,1) 100%)'
const bgBlue =
  'linear-gradient(90deg, rgba(54,47,171,0.01) 0%, rgba(80,80,255,1) 27%, rgba(121,194,221,0.01) 86%, rgba(63,69,181,0.01) 100%)'

const red = 'linear-gradient(to right, #a90329 0%,#d33f41 11%,#8f0222 44%,#9b0a0d 78%)'
const bgRed =
  'linear-gradient(90deg, rgba(60,0,14,1) 0%, rgba(93,2,22,1) 27%, rgba(211,63,65,1) 83%, rgba(120,4,7,1) 100%)'

let submitStyled = {
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(69, 109, 173, .3)',
  color: 'white',
  height: 38,
  padding: '0 30px',
}

export const ButtonSubmit: React.FC<SubmitStyledBtnProps> = ({
  text,
  width,
  color = 'blue',
  fullWidth = false,
  margin = 10,
  onClick,
}) => {
  const SubmitStyledBtn = styled(Button)({
    ...submitStyled,
    background: color === 'blue' ? blue : red,
    '&:hover': { background: color === 'blue' ? bgBlue : bgRed },
    margin,
    width,
  })
  return (
    <SubmitStyledBtn fullWidth={fullWidth} onClick={onClick}>
      <Typography style={{ color: 'white' }}>{text}</Typography>
    </SubmitStyledBtn>
  )
}

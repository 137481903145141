import { createTheme } from '@mui/system'
import { makeStyles } from '@mui/styles'

const theme = createTheme()

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const useStyles = makeStyles(() => ({
  socialNetAvatars: {
    margin: theme.spacing(3),
    width: theme.spacing(6),
    height: theme.spacing(6),
    cursor: 'pointer',
  },
}))

import { Dispatch } from 'react'

import { API_ENDPOINT, API_VERSION, ROUTE_GET_PRODUCT_COMPLEMENT } from '../../config'
import { ProductComplement } from '../../config/types/contentTypes'
import { DispatchAction } from '../../config/types/contextTypes'
import { apiCall, getOptions } from '../../util/axios'
import { APP_PRODUCT_COMPLEMENT_TYPE } from '../reducers/productComplementReducer'

export const getProductComplement = async (
  dispatch: Dispatch<DispatchAction>,
  productId: number,
  peralteId: number,
  anchoId: number
): Promise<void> => {
  if (!productId || !peralteId || !anchoId) {
    return
  }

  const productsContentResponse = await getProductCompl(productId, peralteId, anchoId)
  if (productsContentResponse?.status === 200) {
    const product: ProductComplement = productsContentResponse.data

    dispatch({ type: APP_PRODUCT_COMPLEMENT_TYPE, data: product })
  } else {
    alert(productsContentResponse.data.message)
    return undefined
    // dispatch({ type: GENERAL_ERROR_TYPE, data: 'Ocurrió un error al intentar iniciar sesión. Intente más tarde' })
  }
}

export const getProductComplementForPreLoad = async (
  dispatch: Dispatch<DispatchAction>,
  productId: number,
  peralteId: number,
  anchoId: number
): Promise<ProductComplement | undefined> => {
  if (!productId || !peralteId || !anchoId) {
    return
  }

  const productsContentResponse = await getProductCompl(productId, peralteId, anchoId)
  if (productsContentResponse?.status === 200) {
    const product: ProductComplement = productsContentResponse.data

    dispatch({ type: APP_PRODUCT_COMPLEMENT_TYPE, data: product })
    return product
  } else {
    return undefined
  }
}

const getProductCompl = async (
  productId: number,
  peralteId: number,
  anchoId: number
): Promise<any> => {
  const productsContentResponse = await apiCall(
    await getOptions({
      endpoint: `${API_ENDPOINT}/${API_VERSION}/${ROUTE_GET_PRODUCT_COMPLEMENT}`,
      httpMethod: 'POST',
      data: {
        product_id: productId,
        peralte_id: peralteId,
        ancho_id: anchoId,
      },
    })
  ).catch((error) => {
    throw error
  })

  return productsContentResponse
}

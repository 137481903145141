import React, { Dispatch, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  ThemeProvider,
  Typography
} from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import SwitchText from '../../components/switch'
import { AppState } from '../../config/types'
import {
  ProductAcabados,
  ProductContent,
  ProductDialogText,
  ProductSelected,
  PuestaTierraSelectValue
} from '../../config/types/contentTypes'
import { DispatchAction } from '../../config/types/contextTypes'
import { addToMyList, updateMyList } from '../../context/actions/myListActions'
import { getProductById } from '../../context/actions/productActions'
import { updateProductSelected } from '../../context/actions/productSelectedActions'
import { useStateValue } from '../../context/store'
import withRouter from '../../hooks/UseWithRouter'
import { themeCharofil } from '../../styles/styles'
import Loading from '../loading'
import Content from './content'
import { useStyles } from './useStyles'

interface Props {}

const Product: React.FC<Props> = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()

  const defaultItem = { id: 0 }
  const defaultDialogTexts: ProductDialogText = {
    title: 'Añadir a la lista',
    description: '',
    cancel: 'Cancelar',
    agree: 'Aceptar',
  }
  const errorDialogTexts: ProductDialogText = {
    title: 'Añadir a la lista',
    description: '',
    cancel: '',
    agree: 'Aceptar',
  }

  const [searchParams] = useSearchParams()
  const { state, dispatch }: { state: AppState; dispatch: Dispatch<DispatchAction> } =
    useStateValue()

  const myList: ProductSelected[] | undefined = state?.myList
  const productContent: ProductContent | undefined = state?.product
  const productSelected: ProductSelected | undefined = state?.productSelected
  const [productRelated, setProductRelated] = useState<number>(
    productSelected?.id ? productSelected.id : 0
  )
  const indexEdit: number | undefined = location?.state?.indexEdit

  const [open, setOpen] = useState<boolean>(false)
  const [dialogText, setDialogText] = useState<ProductDialogText>(defaultDialogTexts)
  // const [bimetalicoType, setBimetalicoType] = useState<string | undefined>(
  //   productSelected?.acabadoRelated?.name
  //     ? productSelected?.acabadoRelated?.name.split(' ')[0].trim() === 'EZ' ||
  //       productSelected?.acabadoRelated?.name.split(' ')[0].trim() === 'GAC'
  //       ? productSelected?.acabadoRelated?.name.split(' ')[0].trim()
  //       : productSelected?.acabado?.relatedIds
  //       ? 'EZ' // default
  //       : undefined
  //     : undefined
  // )

  useEffect(() => {
    async function getProductSelected(productId: number) {
      if (productId) {
        await getProductById(dispatch, productId)
      } else {
        navigate('/')
      }
    }

    const productId =
      searchParams.get('id') === null || searchParams.get('id') === undefined
        ? undefined
        : searchParams.get('id')
    if (productId && (!productSelected || productSelected?.id !== parseInt(productId))) {
      getProductSelected(parseInt(productId))
    }
  }, [dispatch, navigate, productSelected, searchParams])

  const getBimetalicoType = () => {
    return productSelected?.acabadoRelated?.name
      ? productSelected?.acabadoRelated?.name.split(' ')[0].trim()
      : productSelected?.acabado?.relatedIds
      ? productSelected?.acabado?.relatedIds.split(';')[0].split(':')[0]
      : 'EZ'
  }

  const handleAcabadoChange = (
    acabadoId: number | undefined,
    bimetalicoTypeValue: string | undefined
  ) => {
    if (!acabadoId && !productSelected?.acabado?.id) return
    else if (!acabadoId && productSelected?.acabado?.id) {
      acabadoId = productSelected.acabado.id
    }

    productSelected?.acabados?.forEach((itemAcabado: ProductAcabados) => {
      if (itemAcabado.id === acabadoId) {
        const data: ProductSelected = {
          ...productSelected,
          acabado: itemAcabado,
          peralte: defaultItem,
          ancho: defaultItem,
          perfilSelect: 0,
          anchoSelect: 0,
          metros: 0,
          union: defaultItem,
          soporte: defaultItem,
          uniones: [],
          soportes: [],
          derivaciones: new Map<number, number>(),
          puestaTierraSelect: new Map<number, PuestaTierraSelectValue>(),
          otrosAccesorios: new Map<number, number>(),
        }

        if (itemAcabado.acabadosRelated) {
          const acabadoRelated = bimetalicoTypeValue
            ? data.acabado?.acabadosRelated.find((p) => p.name === bimetalicoTypeValue)
            : data.acabado?.acabadosRelated[0]

          if (acabadoRelated) {
            data.acabadoRelated = acabadoRelated
          }
        } else {
          data.acabadoRelated = undefined
        }

        updateProductSelected(dispatch, data)
        return
      }
    })
  }

  const displayAcabadosLabel = () => {
    let label = 'Acabados:'
    productSelected?.tabs?.forEach((tipo) => {
      if (tipo.TipoProductoID === 1002) {
        label = 'Calibre:'
        return
      }
    })
    return label
  }

  const handleOpenAddToList = () => {
    let dialogT: ProductDialogText = defaultDialogTexts
    if (productSelected?.peralte?.id === defaultItem.id)
      dialogT = {
        ...errorDialogTexts,
        description: `No es posible ${
          isMyListUpdate(indexEdit, myList) ? 'actualizar' : 'añadir a'
        } la lista debido a que no se ha seleccionado un peralte`,
      }
    else if (productSelected?.ancho?.id === defaultItem.id)
      dialogT = {
        ...errorDialogTexts,
        description: `No es posible ${
          isMyListUpdate(indexEdit, myList) ? 'actualizar' : 'añadir a'
        } la lista debido a que no se ha seleccionado un ancho`,
      }
    else if (productSelected?.metros === undefined || productSelected?.metros === 0)
      dialogT = {
        ...errorDialogTexts,
        description: `No es posible ${
          isMyListUpdate(indexEdit, myList) ? 'actualizar' : 'añadir a'
        } la lista debido a que no ha ingresado los metros requeridos`,
      }
    else
      dialogT = {
        ...defaultDialogTexts,
        description: `Se ${
          isMyListUpdate(indexEdit, myList) ? 'actualizarán' : 'añadirán'
        } todos los productos seleccionados a su lista`,
      }

    setDialogText(dialogT)
    setOpen(true)
  }

  const handleCancelAddToList = () => {
    setOpen(false)
  }

  const handleAgreeAddToList = async () => {
    if (!productSelected || !productContent) return

    if (isMyListUpdate(indexEdit, myList)) await updateList()
    else await addToList()

    setOpen(false)
  }

  const addToList = async () => {
    if (!productSelected) return

    let continueAdd = true
    // Producto
    if (productSelected.peralte?.id === defaultItem.id) continueAdd = false
    else if (productSelected.ancho?.id === defaultItem.id) continueAdd = false
    else if (!productSelected.metros) continueAdd = false

    if (!continueAdd) {
      setOpen(false)
      return
    }

    const currentList: ProductSelected[] = myList ? myList : []
    const selectedItems: ProductSelected[] = [
      ...currentList,
      {
        id: productSelected.id,
        nombre: productSelected.nombre,
        descripcion: productSelected.descripcion,
        img: productSelected.img,
        acabado: productSelected.acabado,
        peralte: productSelected.peralte,
        ancho: productSelected.ancho,
        metros: productSelected.metros,
        uniones: productSelected.uniones,
        soportes: productSelected.soportes,
        perfilSelect: productSelected.perfilSelect,
        anchoRelated: productSelected.anchoRelated,
        acabadoRelated: productSelected.acabadoRelated,
        peralteRelated: productSelected.peralteRelated,
        derivaciones: productSelected.derivaciones,
        puestaTierraSelect: productSelected.puestaTierraSelect,
        otrosAccesorios: productSelected.otrosAccesorios,
      },
    ]

    await addToMyList(dispatch, selectedItems)
    navigate({ pathname: '/myList' })
  }

  const updateList = async () => {
    if (!productSelected || !productContent || !myList || indexEdit === undefined) return

    let continueAdd = true
    // Producto
    if (productSelected.peralte?.id === defaultItem.id) continueAdd = false
    else if (productSelected.ancho?.id === defaultItem.id) continueAdd = false
    else if (!productSelected.metros) continueAdd = false

    if (!continueAdd) {
      setOpen(false)
      return
    }

    const currentList: ProductSelected[] = myList ? myList : []
    const selectedItems: ProductSelected = {
      id: productSelected.id,
      nombre: productSelected.nombre,
      descripcion: productSelected.descripcion,
      img: productSelected.img,
      acabado: productSelected.acabado,
      acabadoRelated: productSelected.acabadoRelated,
      peralte: productSelected.peralte,
      peralteRelated: productSelected.peralteRelated,
      ancho: productSelected.ancho,
      anchoRelated: productSelected.anchoRelated,
      metros: productSelected.metros,
      uniones: productSelected.uniones,
      soportes: productSelected.soportes,
      perfilSelect: productSelected.perfilSelect,
      derivaciones: productSelected.derivaciones,
      puestaTierraSelect: productSelected.puestaTierraSelect,
      otrosAccesorios: productSelected.otrosAccesorios,
    }

    Object.assign(currentList[indexEdit], selectedItems)
    await updateMyList(dispatch, currentList)
    navigate({ pathname: '/myList' })
  }

  const isMyListUpdate = (indexEdit: number | undefined, myList: ProductSelected[] | undefined) => {
    return indexEdit !== undefined && myList && myList.length
  }

  return productSelected ? (
    <Grid
      container
      direction="column"
      className={classes.cardContainer}
      style={{ minHeight: '95vh', backgroundColor: '#f1f1f1' }}
    >
      <Grid item xs={12} md={8} className={classes.cardContainer}>
        <Card className={classes.root}>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                <img src={productSelected.img} alt={productSelected.nombre} />
              </Avatar>
            }
            title={productSelected.nombre}
            titleTypographyProps={{
              variant: 'h5',
              fontWeight: 'bold',
              textTransform: 'capitalize',
            }}
            subheader=""
          />

          <CardMedia className={classes.media}>
            <img src={productSelected.acabado?.img} alt={productSelected.acabado?.name} />
          </CardMedia>
          <CardContent>
            <div className={classes.selectContainer}>
              <Grid container direction="column" className={classes.cardContainer}>
                {productSelected.productsRelated?.length ? (
                  <Grid item xs={12} className={classes.fullWidth}>
                    <ThemeProvider theme={themeCharofil}>
                      <Select
                        fullWidth
                        id="product-productsRelated-select"
                        key="product-productsRelated-select"
                        value={productRelated}
                        onChange={(event) => {
                          setProductRelated(parseInt(event.target.value as string))
                          navigate(`/product?id=${event.target.value}`)
                        }}
                      >
                        <MenuItem
                          key={`product-prodRelated-${productSelected.id}`}
                          value={productSelected.id}
                        >
                          {productSelected.nombre}
                        </MenuItem>
                        {productSelected.productsRelated?.map((prodRelated) => (
                          <MenuItem
                            key={`product-prodRelated-${prodRelated.id}`}
                            value={prodRelated.id}
                          >
                            {prodRelated.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </ThemeProvider>
                  </Grid>
                ) : null}
                <Grid item xs={12} className={classes.fullWidth}>
                  <FormControl fullWidth>
                    <ThemeProvider theme={themeCharofil}>
                      <InputLabel id="acabado-select-label">{displayAcabadosLabel()}</InputLabel>
                      <Select
                        fullWidth
                        labelId="acabado-select-label"
                        id="acabado-select"
                        key="acabado-select"
                        label={displayAcabadosLabel()}
                        value={productSelected.acabado ? `${productSelected.acabado.id}` : '0'}
                        onChange={(event: SelectChangeEvent) => {
                          handleAcabadoChange(parseInt(event.target.value), undefined)
                        }}
                      >
                        {productSelected
                          ? productSelected.acabados
                            ? productSelected.acabados.map((acabado) => (
                                <MenuItem key={`acabado-${acabado.id}`} value={acabado.id}>
                                  {acabado.name}
                                </MenuItem>
                              ))
                            : ''
                          : ''}
                      </Select>
                    </ThemeProvider>
                  </FormControl>
                </Grid>
                {productSelected?.acabado?.relatedIds && productSelected?.acabadoRelated ? (
                  <Grid item xs={12} alignItems={'center'}>
                    <SwitchText
                      title="Tipo"
                      value={getBimetalicoType()}
                      text1="Interior"
                      value1="EZ"
                      text2="Exterior"
                      value2="GAC"
                      onChange={(value: string) => {
                        handleAcabadoChange(productSelected.acabado?.id, value)
                      }}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </div>
            <br />
            <br />
            <Typography variant="body1" color="textSecondary" component="p">
              {productSelected.acabado?.description}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item>
        <div className={classes.separator}></div>
      </Grid>
      <Grid container className={classes.cardContainer} alignItems="center">
        <Grid item xs={11} sx={{ display: { xs: 'block', md: 'none', backgroundColor: 'white' } }}>
          <Content />
        </Grid>
        <Grid item md={9} sx={{ display: { xs: 'none', md: 'block', backgroundColor: 'white' } }}>
          <Content />
        </Grid>
      </Grid>
      <Grid item>
        <div className={classes.separator}></div>
        <div className={classes.separator}></div>
      </Grid>

      <Grid className={classes.expandGrid2} />
      <Grid style={{ paddingBottom: 50 }}>
        <Button
          type="submit"
          variant="contained"
          onClick={handleOpenAddToList}
          style={{ backgroundColor: '#BE0A15' }}
        >
          {isMyListUpdate(indexEdit, myList) ? 'Actualizar' : 'Agregar mis productos a la lista'}
        </Button>
      </Grid>
      <Grid style={{ paddingTop: 10, paddingBottom: 25 }}>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={handleCancelAddToList}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialogText.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogText.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {dialogText.cancel ? (
              <ThemeProvider theme={themeCharofil}>
                <Button onClick={handleCancelAddToList} color="primary">
                  {dialogText.cancel}
                </Button>
              </ThemeProvider>
            ) : (
              ''
            )}
            <ThemeProvider theme={themeCharofil}>
              <Button onClick={handleAgreeAddToList} color="primary" autoFocus>
                {dialogText.agree}
              </Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  ) : (
    <Loading />
  )
}

export default withRouter(Product)

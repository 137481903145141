import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '100%',
    maxWidth: 700,
  },
  tabsRoot: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: 'white',
  },
  tabs: {
    zIndex: 100,
    width: '100%',
  },
  tabsSize: {
    minWidth: 215,
    width: 215,
  },
  separator: {
    height: 30,
  },
  media: {
    ...flexCenter,
    height: '250px',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    // transition: theme.transitions.create('transform', {
    //   duration: theme.transitions.duration.shortest,
    // }),
  },
  expandGrid: {
    minHeight: 70,
  },
  expandGrid2: {
    minHeight: 25,
  },
  expandGridBtn: {
    ...flexCenter,
    minHeight: 70,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: 'black',
    // width: theme.spacing(10),
    // height: theme.spacing(10),
  },
  fullWidth: {
    width: '100%',
    padding: 10,
  },
  cardContainer: {
    ...flexCenter,
    width: '100%',
  },
  tabsContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectContainer: {
    ...flexCenter,
    width: '100%',
  },
  selectInput: {
    width: '100%',
  },
  productCointainer: {
    width: '100%',
  },
  cardRoot: {
    maxWidth: 335,
  },
  complGrid: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridSpace: {
    padding: 0,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    padding: 1,
    ...flexCenter,
  },
  cardMediaImg: {
    maxHeight: '70%',
    maxWidth: '70%',
    padding: 2,
  },
  cardContentContainer: {
    backgroundColor: 'black',
  },
  cardContent: {
    flexGrow: 1,
    color: 'black',
    backgroundColor: 'white',
    paddingBottom: 0,
  },
  cardActions: {
    color: 'blue',
    backgroundColor: 'white',
    paddingTop: 0,
  },
  cardContainerDesc: {
    textAlign: 'right',
  },
  cardContainerPointer: {
    cursor: 'pointer',
  },
}))

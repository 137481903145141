import { UserHistoryItem } from '../../config/types/contentTypes'

export const getHistoryHeaders = (showDeleteByItem: boolean) => {
  const headers = [
    {
      key: 'edit',
      exportName: 'Editar',
      es: '',
      en: '',
    },
    {
      key: 'codigo',
      exportName: 'Código',
      es: 'Código',
      en: 'Code',
    },
    {
      key: 'descripcion',
      exportName: 'Descripción',
      es: 'Descripción',
      en: 'Description',
    },
    {
      key: 'imagen',
      exportName: 'Imagen',
      es: 'Imagen',
      en: 'Image',
    },
    {
      key: 'imagenUso',
      exportName: 'Uso',
      es: 'Uso',
      en: 'Use',
    },
    {
      key: 'unidad',
      exportName: 'Unidad',
      es: 'Unidad',
      en: 'Unit',
    },
    {
      key: 'cantidad',
      exportName: 'Cantidad',
      es: 'Cantidad',
      en: 'Amount',
    },
  ]

  if (showDeleteByItem) {
    headers.push({
      key: 'deleteByItem',
      exportName: '',
      es: '',
      en: '',
    })
  }
  return headers
}

export const getHistoryItemValue = (key: string, item: UserHistoryItem) => {
  switch (key) {
    case 'codigo':
      return item.refComercial

    case 'descripcion':
      return item.descripcion

    case 'imagen':
      return item.img

    case 'imagenUso':
      return item.imgApp

    case 'unidad':
      return item.uv

    case 'cantidad':
      return item.cantidad

    default:
      return ''
  }
}

import PropTypes from 'prop-types'
import React, { Dispatch, useState } from 'react'

import {
  AppBar,
  Box,
  FormControl,
  Grid,
  MenuItem,
  Tab,
  Tabs,
  ThemeProvider,
  Typography
} from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { AppState } from '../../config/types'
import { ProductContent } from '../../config/types/contentTypes'
import { DispatchAction } from '../../config/types/contextTypes'
import { useStateValue } from '../../context/store'
import { themeCharofil } from '../../styles/styles'
import Charolas from './forms/charolas'
import Derivaciones from './forms/derivaciones'
import OtrosAccesorios from './forms/otrosAccesorios'
import PuestaTierraForm from './forms/puestaTierra'
import Soporteria from './forms/soporteria'
import TramosRectos from './forms/tramosRectos'
import TramosRectos2 from './forms/tramosRectos2'
import Tuberias from './forms/tuberias'
import { useStyles } from './useStyles'

const Content: React.FC = () => {
  const classes = useStyles()

  const { state }: { state: AppState; dispatch: Dispatch<DispatchAction> } = useStateValue()

  const product: ProductContent | undefined = state?.productSelected
  const [value, setValue] = useState<number>(0)

  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setValue(newValue)
  }

  const createTab = (index: number, tipoProductoId: number, tipoProductoNombre: string) => {
    return (
      <Tab
        key={`scrollable-auto-tab-${tipoProductoId}`}
        id={`scrollable-auto-tab-${index}`}
        label={tipoProductoNombre}
      />
    )
  }

  const createTabByValue = () => {
    const tab = product?.tabs ? product?.tabs[value] : undefined
    if (!tab) return null

    return (
      <FormControl fullWidth variant="standard">
        <ThemeProvider theme={themeCharofil}>
          <Select
            id={`scrollable-auto-tab-${value}`}
            key={`scrollable-auto-tab-${tab.TipoProductoID}`}
            value={`${value}`}
            onChange={(event: SelectChangeEvent) => {
              setValue(parseInt(event.target.value))
            }}
            style={{ minHeight: 49 }}
          >
            {product
              ? product.tabs
                ? product.tabs.map((tab, index) => (
                    <MenuItem key={`acabado-${index}`} value={index}>
                      <Typography variant="subtitle2" color="#BE0A15">
                        {tab.TipoProductoNombre.toUpperCase()}
                      </Typography>
                    </MenuItem>
                  ))
                : ''
              : ''}
          </Select>
        </ThemeProvider>
      </FormControl>
    )
  }

  return (
    <Grid container direction="column" className={classes.cardContainer} style={{ width: '100%' }}>
      <Grid item style={{ width: '100%' }}>
        <AppBar
          position="static"
          color="default"
          style={{
            alignItems: 'center',
          }}
        >
          <Grid container alignItems="center">
            <Grid
              item
              lg={12}
              sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}
            >
              <ThemeProvider theme={themeCharofil}>
                <Tabs value={value} onChange={handleChange} centered>
                  {product
                    ? product.tabs
                      ? product.tabs.map((tab, index) =>
                          createTab(index, tab.TipoProductoID, tab.TipoProductoNombre)
                        )
                      : ''
                    : ''}
                </Tabs>
              </ThemeProvider>
            </Grid>
            <Grid
              item
              md={12}
              sx={{ display: { md: 'block', lg: 'none' } }}
              style={{
                width: '100%',
              }}
            >
              {createTabByValue()}
            </Grid>
          </Grid>
        </AppBar>
        {product
          ? product.tabs
            ? product.tabs.map((tab, index) => (
                <TabPanel key={`tab_panel-${tab.TipoProductoID}`} value={value} index={index}>
                  {forms.get(tab.TipoProductoID)}
                </TabPanel>
              ))
            : ''
          : ''}
      </Grid>
    </Grid>
  )
}

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

const forms = new Map()
forms.set(1001, <Charolas />)
forms.set(1002, <TramosRectos />)
forms.set(1003, <Tuberias />)
forms.set(1004, <Derivaciones />)
forms.set(1005, <PuestaTierraForm />)
forms.set(1006, <OtrosAccesorios />)
forms.set(1007, <TramosRectos2 />)
forms.set(1008, <Soporteria />)

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export default Content

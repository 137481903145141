import { saveAs } from 'file-saver'

import { API_ENDPOINT, API_VERSION, ROUTE_EXPORT_EXCEL } from '../../config'
import { apiCall, getOptions } from '../../util/axios'

export const exportToXLSX = async (jsData: object, device: string = 'Web') => {
  const rqData = {
    date: '',
    data: jsData,
  }

  const exportResponse = await apiCall(
    await getOptions({
      endpoint: `${API_ENDPOINT}/${API_VERSION}/${ROUTE_EXPORT_EXCEL}`,
      httpMethod: 'POST',
      data: rqData,
    })
  ).catch((error) => {
    throw error
  })

  if (exportResponse.status !== 200) {
    alert('An error occured while trying to export. Please try again')
  } else {
    if (device === 'IOS' || device === 'Android') {
      const window2: any = window
      window2.ReactNativeWebView.postMessage(JSON.stringify(exportResponse.data))
    } else {
      saveAs(exportResponse.data.url, exportResponse.data.fileName)
    }
  }
}

// export const exportToPDF = (jsData, fileName) => {
// }

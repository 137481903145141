import { Grid, Typography } from '@mui/material'

import { useStyles } from './useStyles'

interface Props {
  title: string
  text1: string
  text2: string
  value: string
  value1: string
  value2: string
  onChange: (value: string) => void
}

const SwitchText: React.FC<Props> = ({ title, text1, text2, value, value1, value2, onChange }) => {
  const classes = useStyles()

  const handleChange = (item: number) => {
    item === 1 ? onChange(value1) : onChange(value2)
  }

  const buildStyle = (item: number) => {
    if (item === 1 && value === value1) {
      return classes.itemSelectedLeft
    } else if (item === 2 && value === value2) {
      return classes.itemSelectedRight
    } else {
      return classes.item
    }
  }

  return (
    <Grid className={classes.root}>
      <div style={{ paddingTop: 15, paddingBottom: 10 }}>
        <Typography variant="caption" className={classes.title}>
          {title}:
        </Typography>
      </div>
      <div className={classes.container}>
        <div className={buildStyle(1)} onClick={() => handleChange(1)}>
          {text1}
        </div>
        <div className={buildStyle(2)} onClick={() => handleChange(2)}>
          {text2}
        </div>
      </div>
    </Grid>
  )
}

export default SwitchText

import { Dispatch } from 'react'

import { API_ENDPOINT, API_VERSION, ROUTE_SAVE_LIST } from '../../config'
import { ProductSelected } from '../../config/types/contentTypes'
import { DispatchAction } from '../../config/types/contextTypes'
import { UserSession } from '../../config/types/sessionTypes'
import { apiCall, getOptions } from '../../util/axios'
import { APP_MY_LIST_TYPE } from '../reducers/myListReducer'
import { generateCalculateRequest } from './calculateMaterialActions'

export const addToMyList = async (
  dispatch: Dispatch<DispatchAction>,
  newMyList: ProductSelected[]
) => {
  dispatch({ type: APP_MY_LIST_TYPE, data: newMyList })
}

export const updateMyList = async (
  dispatch: Dispatch<DispatchAction>,
  newMyList: ProductSelected[]
) => {
  dispatch({ type: APP_MY_LIST_TYPE, data: newMyList })
}

export const saveMyList = async (
  dispatch: Dispatch<DispatchAction>,
  myList: ProductSelected[],
  user: UserSession
) => {
  const historyResponse = await apiCall(
    await getOptions({
      endpoint: `${API_ENDPOINT}/${API_VERSION}/${ROUTE_SAVE_LIST}`,
      httpMethod: 'POST',
      data: {
        userID: user.id,
        action: 'add',
        json: await generateCalculateRequest(myList),
      },
    })
  ).catch((error) => {
    throw error
  })

  if (historyResponse?.data?.success) {
    return historyResponse?.data.historial
  }
}

import axios, { RawAxiosRequestConfig } from 'axios'
import { isAndroid, isIOS } from 'react-device-detect'

import { CalculateRequest, SaveListRequest } from '../config/types/contentTypes'
import { UserSession } from '../config/types/sessionTypes'
import { CRUDUserRQ } from '../config/types/userTypes'
import { DEVICE_WEB, DEVICE_WEB_ANDROID, DEVICE_WEB_IOS, USER_SESSION_KEY } from './constants'
import { getData } from './localStorage'

export interface AxiosData {
  device?: string
  screen?: string
  lang?: string
  productName?: string
  userName?: string
  password?: string
}

export interface AxiosDataProduct {
  product_id?: string | number
  peralte_id?: number
  ancho_id?: number
}

export interface AxiosExportExcel {
  date: string
  data: object
}

export const getOptions = async ({
  endpoint,
  httpMethod,
  data,
  params,
  headers,
}: {
  endpoint: string
  httpMethod: string
  data?:
    | AxiosData
    | AxiosDataProduct
    | AxiosExportExcel
    | CalculateRequest[]
    | SaveListRequest
    | CRUDUserRQ
    | null
  params?: any
  headers?: any
}) => {
  const options: RawAxiosRequestConfig = {
    method: httpMethod ? httpMethod : 'GET',
    url: endpoint,
    headers: await getHeaders(headers),
    timeout: 30000,
    data,
    params,
  }

  return options
}

export const getHeaders = async (customHeaders: any | undefined) => {
  const headers = {
    ...customHeaders,
    'Content-Type': 'application/json;charset=UTF-8',
    // from: await encryptText(getDevice()),
  }

  const userSession: UserSession = await getData(USER_SESSION_KEY)
  if (userSession?.token) {
    return {
      ...headers,
      Authorization: `Bearer ${userSession.token}`,
    }
  } else {
    return headers
  }
}

// Single
export const apiCall = async (options: any) => {
  // console.log(options)
  const response = await axios(options).catch((err) => {
    return err.response
  })

  return response
}

export const getDevice = () => {
  if (isAndroid) return DEVICE_WEB_ANDROID
  else if (isIOS) return DEVICE_WEB_IOS
  else return DEVICE_WEB
}

import { Dispatch } from 'react'

import {
  API_ENDPOINT,
  API_VERSION,
  ROUTE_CREATE_ACCOUNT,
  ROUTE_RECOVERY_PASSWORD,
  ROUTE_UPDATE_USER
} from '../../config'
import { DispatchAction } from '../../config/types/contextTypes'
import { UserSession } from '../../config/types/sessionTypes'
import { NewUser, UserUpdate } from '../../config/types/userTypes'
import { apiCall, getOptions } from '../../util/axios'
import { LOGIN_TYPE } from '../reducers/loginReducer'

export interface CreateAccountResponse {
  success: boolean
  error?: string
  message?: string
}

// export const getUser = async (dispatch: Dispatch<DispatchAction>, userName: string): Promise<void> => {
//   const userResponse = await apiCall(
//     await getOptions({
//       endpoint: `${API_ENDPOINT}/${API_VERSION}/${ROUTE_ADMIN_USERS}`,
//       httpMethod: 'GET',
//       params: { userName: userName },
//     })
//   ).catch((error) => {
//     throw error
//   })

//   if (userResponse?.status === 200 && userResponse?.data) {
//     const user: User = userResponse.data

//     dispatch({ type: USER_TYPE, data: user })
//   } else if (userResponse?.status === 401) {
//     removeData(USER_SESSION_KEY)
//     removeData(SESSION_RESPONSE_KEY)
//   } else {
//     alert(JSON.stringify(userResponse))
//     // dispatch({ type: GENERAL_ERROR_TYPE, data: 'Ocurrió un error al intentar iniciar sesión. Intente más tarde' })
//   }
// }

export const updateUser = async (
  dispatch: Dispatch<DispatchAction>,
  userUpdate: UserUpdate,
  userSession: UserSession
): Promise<void> => {
  const userResponse = await apiCall(
    await getOptions({
      endpoint: `${API_ENDPOINT}/${API_VERSION}/${ROUTE_UPDATE_USER}`,
      httpMethod: 'POST',
      data: userUpdate,
    })
  ).catch((error) => {
    throw error
  })

  if (userResponse?.status === 200 && userResponse?.data && userResponse?.data?.length > 0) {
    const newUserSession: UserSession = {
      ...userSession,
      name: userResponse.data[0].name,
      last: userResponse.data[0].lastName,
    }

    dispatch({ type: LOGIN_TYPE, data: newUserSession })
  } else {
    alert(userResponse.data.error)
    // dispatch({ type: GENERAL_ERROR_TYPE, data: 'Ocurrió un error al intentar actualizar la información. Intente más tarde' })
  }
}

export const createNewUser = async (newUser: NewUser): Promise<string | undefined> => {
  const userResponse = await apiCall(
    await getOptions({
      endpoint: `${API_ENDPOINT}/${API_VERSION}/${ROUTE_CREATE_ACCOUNT}`,
      httpMethod: 'POST',
      data: {
        user: newUser,
        action: 'insert',
      },
    })
  ).catch((error) => {
    throw error
  })

  const response: CreateAccountResponse = userResponse.data

  if (userResponse?.status === 200 && response && response.success && !response.error) {
    return undefined
  }

  return response.error
    ? response.error
    : 'Error al intentar crear el usuario. Intente de neuvo más tarde'
}

export const recoveryPassword = async (userData: any): Promise<string | undefined> => {
  const recoveryPasswordResponse = await apiCall(
    await getOptions({
      endpoint: `${API_ENDPOINT}/${API_VERSION}/${ROUTE_RECOVERY_PASSWORD}`,
      httpMethod: 'POST',
      data: { type: 'ForgotPassword', user: userData },
    })
  ).catch((error) => {
    throw error
  })

  const response: CreateAccountResponse = recoveryPasswordResponse.data

  if (
    recoveryPasswordResponse?.status === 200 &&
    response &&
    response.success &&
    !response.message
  ) {
    return undefined
  }

  return response.message
    ? response.message
    : 'Error al intentar recuperar la contraseña. Intente de neuvo más tarde'
}

import React, { useState } from 'react'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import EditNoteIcon from '@mui/icons-material/EditNote'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined'
import MenuIcon from '@mui/icons-material/Menu'
import MoveDownIcon from '@mui/icons-material/MoveDown'
import SaveIcon from '@mui/icons-material/Save'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  ThemeProvider,
  Typography
} from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { UserHistoryItem } from '../../config/types/contentTypes'
import { themeCharofil } from '../../styles/styles'
import { getHistoryHeaders, getHistoryItemValue } from './configuration'

interface Props {
  title: string
  items: UserHistoryItem[]
  lang?: 'es' | 'en'
  showFilters?: boolean
  preLoadLabel?: string
  onMoveToMyList?: () => void
  onPreLoad?: (listIndex: number | undefined, itemIndex: number | undefined) => void
  onSave?: () => void
  onSaveAndDownloadExcel?: () => void
  onDownloadExcel?: () => void
  onDeleteItem?: (listIndex: number | undefined, indexItem: number | undefined) => void
  onDeleteAll?: () => void
}

const TableCollapse: React.FC<Props> = ({
  title,
  items = [],
  lang = 'es',
  showFilters = false,
  preLoadLabel = 'Editar',
  onMoveToMyList,
  onPreLoad,
  onSave,
  onSaveAndDownloadExcel,
  onDownloadExcel,
  onDeleteItem,
  onDeleteAll,
}) => {
  const [search, setSearch] = useState<string>('')
  const [openMenu, setOpenMenu] = useState<(EventTarget & HTMLButtonElement) | null>(null)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [openImage, setOpenImage] = useState<string | undefined>(undefined)

  // Filters
  const [filterCodeValue, setFilterCodeValue] = useState<string>('-1')
  const [filterUnidadValue, setFilterUnidadValue] = useState<string>('-1')
  const [filterCantidadValue, setFilterCantidadValue] = useState<string>('-1')

  const getCodeFilterItems = (): string[] => {
    const filterItems: string[] = []
    items.forEach((item) => {
      if (!filterItems.includes(item.refComercial)) filterItems.push(item.refComercial)
    })

    return filterItems
  }

  const handleCodeFilter = (event: SelectChangeEvent) => {
    setFilterCodeValue(event.target.value)
  }

  const getUnidadFilterItems = (): string[] => {
    const filterItems: string[] = []
    items.forEach((item) => {
      if (!filterItems.includes(item.uv)) filterItems.push(item.uv)
    })

    return filterItems
  }

  const handleUnidadFilter = (event: SelectChangeEvent) => {
    setFilterUnidadValue(event.target.value)
  }

  const getCantidadFilterItems = (): string[] => {
    const filterItems: string[] = []
    items.forEach((item) => {
      if (!filterItems.includes(`${item.cantidad}`)) filterItems.push(`${item.cantidad}`)
    })

    return filterItems
  }

  const handleCantidadFilter = (event: SelectChangeEvent) => {
    setFilterCantidadValue(event.target.value)
  }

  const handleMenuClose = () => {
    setOpenMenu(null)
  }

  let bgColor = '#d6dbdf'

  const backgroundRow = (item: UserHistoryItem) => {
    if (item.parentId) return bgColor

    if (bgColor === 'white') {
      bgColor = '#d6dbdf'
    } else {
      bgColor = 'white'
    }
    return bgColor
  }

  const displayTrashIcon = (item: UserHistoryItem): boolean => {
    if (item.type === 'zinc') return false
    if (item.parentId) return false

    return true
  }

  const searchTextInItems = (items: UserHistoryItem[]) => {
    if (!items || items.length === 0) return []

    const newItems = items.filter(
      (item) =>
        item.descripcion?.toLowerCase().includes(search.toLowerCase()) ||
        item.refComercial?.toLowerCase().includes(search.toLowerCase())
    )
    return newItems ? newItems : []
  }

  return (
    <Grid container direction="column" alignItems="center" style={{ width: '100%' }}>
      <Grid
        container
        style={{
          width: '100%',
          border: 1,
          borderStyle: 'solid',
          borderColor: '#C0C0C0',
          borderRadius: 5,
        }}
      >
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            width: '100%',
            minHeight: 40,
            backgroundColor: '#BE0A15',
          }}
        >
          <Typography color="white" variant="subtitle1" fontWeight="bold">
            {title}
          </Typography>
        </Grid>

        {/* Controls */}
        <Grid item xs={12} margin={1}>
          <Grid
            container
            direction="row"
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{
              width: '100%',
              minHeight: 40,
              backgroundColor: 'white',
              border: 1,
              borderSpacing: 0,
              borderStyle: 'solid',
              borderColor: '#ddd',
              borderRadius: 5,
            }}
          >
            {/* Menu */}
            <Grid item xs={1.5} display="flex" alignItems="flex-start" justifyContent="flex-start">
              <Paper elevation={0}>
                <IconButton
                  aria-label="menu"
                  onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                    setOpenMenu(event.currentTarget)
                  }
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-bar"
                  anchorEl={openMenu}
                  open={Boolean(openMenu)}
                  keepMounted
                  onClose={() => setOpenMenu(null)}
                >
                  {showFilters ? (
                    <MenuItem
                      dense
                      onClick={() => {
                        setDialogOpen(true)
                        handleMenuClose()
                      }}
                    >
                      <ListItemIcon>
                        <FilterListOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Filtros</Typography>
                    </MenuItem>
                  ) : null}
                  {onSave ? (
                    <MenuItem
                      dense
                      onClick={() => {
                        onSave()
                        handleMenuClose()
                      }}
                    >
                      <ListItemIcon>
                        <SaveIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Guardar</Typography>
                    </MenuItem>
                  ) : null}
                  {onSaveAndDownloadExcel ? (
                    <MenuItem
                      dense
                      onClick={() => {
                        onSaveAndDownloadExcel()
                        handleMenuClose()
                      }}
                    >
                      <ListItemIcon>
                        <SaveAltIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Guardar y Descargar</Typography>
                    </MenuItem>
                  ) : null}
                  {onMoveToMyList ? (
                    <MenuItem
                      dense
                      onClick={() => {
                        onMoveToMyList()
                        handleMenuClose()
                      }}
                    >
                      <ListItemIcon>
                        <MoveDownIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Mover todo a Mi Lista</Typography>
                    </MenuItem>
                  ) : null}
                  {onDownloadExcel ? (
                    <MenuItem
                      dense
                      onClick={() => {
                        onDownloadExcel()
                        handleMenuClose()
                      }}
                    >
                      <ListItemIcon>
                        <GetAppOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Descargar</Typography>
                    </MenuItem>
                  ) : null}

                  {onDeleteAll ? (
                    <MenuItem dense onClick={() => onDeleteAll()}>
                      <ListItemIcon>
                        <DeleteForeverIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Eliminar Todo</Typography>
                    </MenuItem>
                  ) : null}
                </Menu>
              </Paper>
              <Divider orientation="vertical" color="black" />
            </Grid>

            {/* Search */}
            <Grid item xs={8.5}>
              <InputBase
                fullWidth
                placeholder="Buscar"
                inputProps={{ 'aria-label': 'search filter' }}
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
              <Divider orientation="vertical" style={{ backgroundColor: 'red' }} />
            </Grid>

            {/* Search | Cancel */}
            <Grid item xs={1}>
              {search ? (
                <IconButton aria-label="reset-search" onClick={() => setSearch('')}>
                  <CloseOutlinedIcon />
                </IconButton>
              ) : (
                <IconButton disabled color="primary" aria-label="search">
                  <SearchOutlinedIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          {/* Large */}
          <Grid
            item
            md={12}
            sx={{ display: { xs: 'none', md: 'block', backgroundColor: 'white' } }}
          >
            {/* Headers */}
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{
                width: '100%',
                minHeight: 40,
                backgroundColor: 'rgb(189, 189, 189)',
                padding: 10,
                fontSize: 14,
              }}
            >
              {getHistoryHeaders(onDeleteItem ? true : false).map((header, index) => (
                <Grid
                  key={`table-c-header-${index}`}
                  item
                  xs={
                    header.key === 'descripcion'
                      ? onDeleteItem
                        ? 5
                        : 6
                      : header.key === 'deleteByItem'
                      ? 1
                      : header.key === 'imagen' || header.key === 'imagenUso'
                      ? 1
                      : header.key === 'edit'
                      ? 0.5
                      : 1.5
                  }
                  style={{ textAlign: 'center' }}
                >
                  {lang === 'en' ? header.en : header.es}
                </Grid>
              ))}
            </Grid>

            {/* Items */}
            {searchTextInItems(items).map((item, index) => (
              <Grid
                key={`table-c-item-${index}`}
                item
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{
                  width: '100%',
                  minHeight: 40,
                  backgroundColor: backgroundRow(item),
                  borderTop: index > 0 ? 1 : 0,
                  borderTopStyle: 'solid',
                  borderTopColor: '#C0C0C0',
                  padding: 10,
                  fontSize: 13,
                }}
              >
                {getHistoryHeaders(onDeleteItem ? true : false).map((header, index1) =>
                  header.key === 'descripcion' ? (
                    <Grid
                      key={`table-c-item-v-${index1}`}
                      item
                      xs={onDeleteItem ? 5 : 6}
                      style={{ textAlign: 'justify' }}
                    >
                      {getHistoryItemValue(header.key, item)}
                    </Grid>
                  ) : header.key === 'deleteByItem' && onDeleteItem ? (
                    <Grid key={`table-c-item-v-${index1}`} item xs={1}>
                      {displayTrashIcon(item) ? (
                        <IconButton
                          aria-label="R"
                          component="span"
                          onClick={() => {
                            onDeleteItem(item.listIndex, item.itemIndex)
                          }}
                        >
                          <DeleteForeverOutlinedIcon />
                        </IconButton>
                      ) : null}
                    </Grid>
                  ) : header.key === 'imagen' ? (
                    <Grid
                      key={`table-c-item-v-${index1}`}
                      item
                      xs={1}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {item.img ? <Avatar alt={`image-avatar-${item.img}`} src={item.img} /> : null}
                    </Grid>
                  ) : header.key === 'imagenUso' ? (
                    <Grid
                      key={`table-c-item-v-${index1}`}
                      item
                      xs={1}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {item.imgApp ? (
                        <Avatar alt={`image-avatar-${item.imgApp}`} src={item.imgApp} />
                      ) : null}
                    </Grid>
                  ) : header.key === 'edit' ? (
                    <Grid key={`table-c-item-v-${index1}`} item xs={0.5}>
                      {item.type === 'product' && index1 === 0 && onPreLoad ? (
                        <IconButton
                          aria-label="Editar"
                          onClick={() => onPreLoad(item.listIndex, item.itemIndex)}
                        >
                          <EditNoteIcon fontSize="small" style={{ color: '#BE0A15' }} />
                        </IconButton>
                      ) : null}
                    </Grid>
                  ) : (
                    <Grid
                      key={`table-c-item-v-${index1}`}
                      item
                      xs={1.5}
                      style={{
                        textAlign: 'center',
                        fontWeight: item.type === 'product' && index1 === 1 ? 'bold' : 'normal',
                      }}
                    >
                      {getHistoryItemValue(header.key, item)}
                    </Grid>
                  )
                )}
              </Grid>
            ))}
          </Grid>

          {/* Medium */}
          <Grid
            item
            xs={12}
            sx={{ display: { xs: 'block', md: 'none', fontSize: 14, backgroundColor: 'white' } }}
          >
            <>
              {searchTextInItems(items).map((item, index) => (
                <Grid container style={{ backgroundColor: backgroundRow(item) }}>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    padding={1}
                    style={{
                      width: '100%',
                      minHeight: 40,
                      backgroundColor: 'rgb(189, 189, 189)',
                      textAlign: 'center',
                    }}
                  >
                    <Grid container>
                      <Grid item xs={1} display="flex" alignItems="center" justifyContent="center">
                        {item.type === 'product' && index === 0 && onPreLoad ? (
                          <IconButton
                            aria-label="Editar"
                            onClick={() => onPreLoad(item.listIndex, item.itemIndex)}
                          >
                            <EditNoteIcon fontSize="small" style={{ color: '#BE0A15' }} />
                          </IconButton>
                        ) : null}
                      </Grid>
                      <Grid item xs={10} display="flex" alignItems="center" justifyContent="center">
                        <Typography>{item.refComercial}</Typography>
                      </Grid>
                      <Grid item xs={1} display="flex" alignItems="center" justifyContent="center">
                        {displayTrashIcon(item) && onDeleteItem ? (
                          <IconButton
                            aria-label="Delete"
                            component="span"
                            onClick={() => {
                              onDeleteItem(item.listIndex, item.itemIndex)
                            }}
                          >
                            <DeleteForeverOutlinedIcon
                              fontSize="small"
                              style={{ color: '#BE0A15' }}
                            />
                          </IconButton>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    padding={1}
                    style={{
                      width: '100%',
                      minHeight: 40,
                      textAlign: 'justify',
                    }}
                  >
                    {item.descripcion}
                  </Grid>
                  <Grid container>
                    {item.img !== '' && item.img !== undefined ? (
                      <Grid
                        item
                        xs={item.imgApp !== '' && item.imgApp !== undefined ? 6 : 12}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          paddingTop: 20,
                          paddingBottom: 20,
                          width: '100%',
                          textAlign: 'justify',
                          height: 22,
                        }}
                      >
                        <Avatar alt={`image-avatar-${item.img}`} src={item.img} />
                      </Grid>
                    ) : null}
                    {item.imgApp !== '' && item.imgApp !== undefined ? (
                      <Grid
                        item
                        xs={item.img !== '' && item.img !== undefined ? 6 : 12}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          paddingTop: 20,
                          paddingBottom: 20,
                          width: '100%',
                          textAlign: 'justify',
                          height: 22,
                        }}
                      >
                        <Avatar alt={`image-avatar-${item.imgApp}`} src={item.imgApp} />
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    padding={1}
                    style={{
                      paddingTop: 20,
                      paddingBottom: 10,
                      width: '100%',
                      textAlign: 'justify',
                      height: 22,
                    }}
                  >
                    Unidad: {item.uv}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    padding={1}
                    style={{
                      paddingTop: 10,
                      paddingBottom: 20,
                      width: '100%',
                      textAlign: 'justify',
                      height: 22,
                    }}
                  >
                    Cantidad: {item.cantidad}
                  </Grid>
                </Grid>
              ))}
            </>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            width: '100%',
            minHeight: 5,
            borderBottom: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: '#808080',
            backgroundColor: 'white',
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
          }}
        ></Grid>
      </Grid>

      {/* Filters */}
      <Grid container style={{ width: '100%' }}>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Filtrar por</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" />
            <Grid container direction="row">
              <Grid item xs={12} key={`container-filter-code`}>
                <InputLabel id={`filter-select-label-code`}>Código:</InputLabel>
                <Select
                  fullWidth
                  labelId={`filter-select-label-code`}
                  id={`filter-select-code`}
                  key={`filter-select-code`}
                  label="Código:"
                  value={filterCodeValue}
                  onChange={handleCodeFilter}
                >
                  <MenuItem key={`filter-select-code-all`} value={'-1'}>
                    Todos
                  </MenuItem>
                  {getCodeFilterItems().map((item) => (
                    <MenuItem key={`filter-select-code-${item}`} value={`${item}`}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} key={`container-filter-unidad`}>
                <InputLabel id={`filter-select-label-unidad`}>Unidad:</InputLabel>
                <Select
                  fullWidth
                  labelId={`filter-select-label-unidad`}
                  id={`filter-select-unidad`}
                  key={`filter-select-unidad`}
                  label="Unidad:"
                  value={filterUnidadValue}
                  onChange={handleUnidadFilter}
                >
                  <MenuItem key={`filter-select-unidad-all`} value={'-1'}>
                    Todos
                  </MenuItem>
                  {getUnidadFilterItems().map((item) => (
                    <MenuItem key={`filter-select-unidad-${item}`} value={`${item}`}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} key={`container-filter-cantidad`}>
                <InputLabel id={`filter-select-label-cantidad`}>Cantidad:</InputLabel>
                <Select
                  fullWidth
                  labelId={`filter-select-label-cantidad`}
                  id={`filter-select-cantidad`}
                  key={`filter-select-cantidad`}
                  label="Cantidad:"
                  value={filterCantidadValue}
                  onChange={handleCantidadFilter}
                >
                  <MenuItem key={`filter-select-cantidad-all`} value={'-1'}>
                    Todos
                  </MenuItem>
                  {getCantidadFilterItems().map((item) => (
                    <MenuItem key={`filter-select-cantidad-${item}`} value={`${item}`}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={themeCharofil}>
              <Button
                onClick={() => {
                  setFilterCodeValue('-1')
                  setFilterUnidadValue('-1')
                  setFilterCantidadValue('-1')
                }}
                color="primary"
                autoFocus
              >
                Reset
              </Button>
              <Button onClick={() => setDialogOpen(false)} color="primary" autoFocus>
                Salir
              </Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  )
}

export default TableCollapse

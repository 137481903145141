import React, { Dispatch, useState } from 'react'

import InfoIcon from '@mui/icons-material/Info'
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography
} from '@mui/material'

import { AppState } from '../../../config/types'
import {
  ProductSelected,
  Puestatierra,
  PuestaTierraSelectValue
} from '../../../config/types/contentTypes'
import { DispatchAction } from '../../../config/types/contextTypes'
import { updateProductSelected } from '../../../context/actions/productSelectedActions'
import { useStateValue } from '../../../context/store'
import { themeCharofil } from '../../../styles/styles'
import Loading from '../../loading'
import { useStyles } from '../useStyles'

const PuestaTierraForm: React.FC = () => {
  const classes = useStyles()
  const { state, dispatch }: { state: AppState; dispatch: Dispatch<DispatchAction> } =
    useStateValue()

  const productSelected: ProductSelected | undefined = state?.productSelected

  const handleSearchOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!productSelected) return

    await updateProductSelected(dispatch, {
      ...productSelected,
      searchPuestaTierra: event.target.value,
    })
  }

  const handleDistancia1OnChange = async (id: number, cubrir: number) => {
    if (!productSelected) return

    const map = productSelected.puestaTierraSelect
      ? productSelected.puestaTierraSelect
      : new Map<number, PuestaTierraSelectValue>()

    const d2: string | undefined = map.get(id)?.d2
    if (!Number.isNaN(cubrir)) {
      map.set(id, { d1: `${cubrir}`, d2: d2 ? d2 : '' })
    } else if (map.has(id) && Number.isNaN(cubrir) && d2) {
      map.set(id, { d1: '', d2: d2 })
    } else if (map.has(id) && Number.isNaN(cubrir) && !d2) {
      map.delete(id)
    }

    await updateProductSelected(dispatch, {
      ...productSelected,
      puestaTierraSelect: map,
    })
  }

  const handleDistancia2OnChange = async (id: number, separacion: number) => {
    if (!productSelected) return

    const map = productSelected.puestaTierraSelect
      ? productSelected.puestaTierraSelect
      : new Map<number, PuestaTierraSelectValue>()

    const d1: string | undefined = map.get(id)?.d1
    if (!Number.isNaN(separacion)) {
      map.set(id, { d1: d1 ? d1 : '', d2: `${separacion}` })
    } else if (map.has(id) && Number.isNaN(separacion) && d1) {
      map.set(id, { d1: d1 ? d1 : '', d2: '' })
    } else if (map.has(id) && Number.isNaN(separacion) && !d1) {
      map.delete(id)
    }
    await updateProductSelected(dispatch, {
      ...productSelected,
      puestaTierraSelect: map,
    })
  }

  const [open, setOpen] = useState(false)
  const [dialogDescription, setDialogDescription] = useState({ title: '', description: '' })
  const handleOpenDialog = (title: string, description: string) => {
    const texts = {
      title: title,
      description: description,
    }
    setDialogDescription(texts)
    setOpen(true)
  }

  const handleCloseDialog = () => {
    setOpen(false)
  }

  const printItems = (puestaTierra: Puestatierra) => {
    if (!productSelected?.searchPuestaTierra) return true

    if (
      puestaTierra.nombre.toLowerCase().includes(productSelected.searchPuestaTierra.toLowerCase())
    )
      return true
    else return false
  }

  const getPuestaTierraValue = (puestatierraId: number, id: string): string => {
    if (!productSelected?.puestaTierraSelect || productSelected?.puestaTierraSelect.size <= 0)
      return ''

    const select = productSelected?.puestaTierraSelect.get(puestatierraId)
    if (!select) return ''
    else {
      if (id === 'd1') {
        return select.d1
      } else if (id === 'd2') {
        return select.d2
      } else return ''
    }
  }

  return productSelected ? (
    <Grid container direction="column" className={classes.cardContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ThemeProvider theme={themeCharofil}>
            <TextField
              variant="outlined"
              fullWidth
              id="search"
              label="Buscar"
              name="search"
              value={productSelected.searchPuestaTierra}
              onChange={handleSearchOnChange}
            />
          </ThemeProvider>
        </Grid>
        {productSelected.puestaTierra
          ? productSelected.puestaTierra.map((puestaTierra) =>
              printItems(puestaTierra) ? (
                <Grid item key={puestaTierra.id} xs={12} sm={6} md={4} lg={3}>
                  <Card>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        alt={puestaTierra.nombre}
                        image={puestaTierra.img ? puestaTierra.img : ''}
                        title={puestaTierra.nombre}
                        height="220"
                        sx={{ padding: '1em 1em 0 0', objectFit: 'contain' }}
                      />
                      <CardContent className={classes.cardContent}>
                        <Grid>
                          <Typography variant="subtitle2" gutterBottom>
                            {puestaTierra.nombre}
                          </Typography>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                    <CardActions disableSpacing>
                      <Grid container direction="column">
                        <Grid item xs={12}>
                          <ThemeProvider theme={themeCharofil}>
                            <TextField
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              id={`distancia1-${puestaTierra.id}`}
                              label="Distancia a cubrir (mts)"
                              name={`distancia1-${puestaTierra.id}`}
                              value={getPuestaTierraValue(puestaTierra.id, 'd1')}
                              inputProps={{
                                style: { textAlign: 'center' },
                              }}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                handleDistancia1OnChange(
                                  puestaTierra.id,
                                  parseInt(event.target.value.replace(/[^0-9]/g, ''))
                                )
                              }
                            />
                          </ThemeProvider>
                        </Grid>
                        <Grid item xs={12}>
                          <ThemeProvider theme={themeCharofil}>
                            <TextField
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              id={`distancia2-${puestaTierra.id}`}
                              label="Distancia entre Puestas a Tierra (mts)"
                              name={`distancia2-${puestaTierra.id}`}
                              value={getPuestaTierraValue(puestaTierra.id, 'd2')}
                              inputProps={{
                                style: { textAlign: 'center' },
                              }}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                handleDistancia2OnChange(
                                  puestaTierra.id,
                                  parseInt(event.target.value.replace(/[^0-9]/g, ''))
                                )
                              }
                            />
                          </ThemeProvider>
                        </Grid>
                        <Grid>
                          <Tooltip title="Ver más" placement="top">
                            <IconButton
                              aria-label="Ver más"
                              onClick={() =>
                                handleOpenDialog(puestaTierra.nombre, puestaTierra.descripcion)
                              }
                            >
                              <InfoIcon fontSize="small" style={{ color: '#BE0A15' }} />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              ) : (
                ''
              )
            )
          : ''}
      </Grid>
      <Grid item xs={12}>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialogDescription.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogDescription.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={themeCharofil}>
              <Button onClick={handleCloseDialog} variant="contained" color="primary">
                Cerrar
              </Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  ) : (
    <Loading />
  )
}
export default PuestaTierraForm

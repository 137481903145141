import { createTheme } from '@mui/material'

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const themeCharofil = createTheme({
  palette: {
    primary: {
      main: '#BE0A15',
    },
  },
})

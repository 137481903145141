// import Moment from 'moment'
import React, { Dispatch, useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ThemeProvider
} from '@mui/material'

import { AppState } from '../../config/types'
import { DispatchAction } from '../../config/types/contextTypes'
// import { UserSession } from '../../config/types/sessionTypes'
import { logout } from '../../context/actions/loginActions'
import { useStateValue } from '../../context/store'
import { themeCharofil } from '../../styles/styles'

// import { USER_SESSION_KEY } from '../../util/constants'
// import { getDataSync } from '../../util/localStorage'

const InactiveSessionDialog: React.FC = () => {
  const { dispatch }: { state: AppState; dispatch: Dispatch<DispatchAction> } = useStateValue()
  // const userSession: UserSession = getDataSync(USER_SESSION_KEY)

  // const getTimeout = () => {
  //   const endDate = Moment(userSession.endTime)
  //   const seconds = endDate.diff(Moment(), 'seconds')
  //   return seconds
  // }

  // Time in seconds:
  const timeoutActivity = 360 // 6 mins
  const promptBeforeIdleActivity = 60
  // const timeoutSession = getTimeout()
  // const promptBeforeIdleSession = 60
  const unableToClose = 1

  const [remainingActivity, setRemainingActivity] = useState<number>(timeoutActivity)
  const [openActivity, setOpenActivity] = useState<boolean>(false)
  // const [remainingSession, setRemainingSession] = useState<number>(timeoutSession)
  // const [openSession, setOpenSession] = useState<boolean>(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingActivity(Math.ceil(getRemainingTime() / 1000))
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  })

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setRemainingSession(Math.ceil(getRemainingTimeSession() / 1000))
  //     console.log(remainingSession)
  //   }, 1000)

  //   return () => {
  //     clearInterval(interval)
  //   }
  // })

  const onIdleActivity = async () => {
    await logout(dispatch)
  }

  const onActionActivity = () => {
    activate()
    setOpenActivity(false)
  }

  const onPromptActivity = () => {
    setOpenActivity(true)
  }

  const handleCloseActivity = () => {
    activate()
    setOpenActivity(false)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle: onIdleActivity,
    onPrompt: onPromptActivity,
    onAction: onActionActivity,
    timeout: timeoutActivity * 1000,
    promptBeforeIdle: (promptBeforeIdleActivity - 1) * 1000,
    throttle: 1000,
  })

  // const onIdleSession = async () => {
  //   await logout(dispatch)
  // }

  // const onActionSession = () => {
  //   activateSession()
  //   setOpenSession(false)
  // }

  // const onPromptSession = () => {
  //   activate()
  //   setOpenSession(true)
  // }

  // const handleCloseSession = () => {
  //   activateSession()
  //   setOpenSession(false)
  // }

  // const handleExtendSession = () => {
  //   console.log('session refresh')
  // }

  // const { getRemainingTime: getRemainingTimeSession, activate: activateSession } = useIdleTimer({
  //   onIdle: onIdleSession,
  //   onPrompt: onPromptSession,
  //   onAction: onActionSession,
  //   timeout: timeoutActivity * 1000,
  //   promptBeforeIdle: (promptBeforeIdleSession - 1) * 1000,
  //   throttle: 1000,
  // })

  // return openActivity && !openSession ? (
  return openActivity ? (
    <Grid>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openActivity}
        onClose={remainingActivity <= unableToClose ? undefined : handleCloseActivity}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Inactividad</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid>
              Ha estado inactivo por alrededor de 5 minutos. Por su seguridad, su cuenta será
              finalizada en {remainingActivity} segundos
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ThemeProvider theme={themeCharofil}>
            <Button
              onClick={handleCloseActivity}
              color="primary"
              autoFocus
              disabled={remainingActivity <= unableToClose ? true : false}
            >
              Continuar
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    </Grid>
  ) : // : openSession ? (
  //   <Grid>
  //     <Dialog
  //       fullWidth
  //       maxWidth="sm"
  //       open={openSession}
  //       onClose={handleCloseSession}
  //       aria-labelledby="alert-dialog-title"
  //       aria-describedby="alert-dialog-description"
  //     >
  //       <DialogTitle id="alert-dialog-title">Sesión de Usuario</DialogTitle>
  //       <DialogContent>
  //         <DialogContentText id="alert-dialog-description">
  //           <Grid>Su sesión está por terminarse: {remainingSession}.</Grid>
  //         </DialogContentText>
  //       </DialogContent>
  //       <DialogActions>
  //         <Button onClick={handleExtendSession} color="primary" autoFocus>
  //           Extender sesión
  //         </Button>
  //         <Button onClick={handleCloseSession} color="primary" autoFocus>
  //           Cerrar sesión
  //         </Button>
  //       </DialogActions>
  //     </Dialog>
  //   </Grid>
  // )
  null
}

export default InactiveSessionDialog

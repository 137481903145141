import { ProductSelected } from '../../config/types/contentTypes'
import { DispatchAction } from '../../config/types/contextTypes'

export const APP_MY_LIST_TYPE = 'myList'

const Reducer = (state: ProductSelected[], action: DispatchAction): ProductSelected[] => {
  switch (action.type) {
    case APP_MY_LIST_TYPE:
      return [...action.data]

    default:
      return state
  }
}

export default Reducer

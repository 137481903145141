import { isIOS, isAndroid } from 'react-device-detect'

const DEVICE_BROWSER = 'Web'
const DEVICE_IOS = 'Web IOS'
const DEVICE_ANDROID = 'Web Android'

export const getDevice = () => {
  if (isIOS) {
    return DEVICE_IOS
  } else if (isAndroid) {
    return DEVICE_ANDROID
  } else {
    return DEVICE_BROWSER
  }
}

export const getLanguage = () => {
  if (navigator.language.split('-')[0] === '') return 'es'
  else return navigator.language.split('-')[0]
}

import { Dispatch } from 'react'

import { getLanguage } from '../../api/utils'
import { API_ENDPOINT, API_VERSION, ROUTE_CONTENT } from '../../config'
import { Content } from '../../config/types/contentTypes'
import { DispatchAction } from '../../config/types/contextTypes'
import { APP_LOGIN_CONTENT_TYPE } from '../../context/reducers/contentReducer'
import { apiCall, getOptions } from '../../util/axios'
import { activateLoading, deactivateLoading } from './appStatusActions'

export const getContent = async (dispatch: Dispatch<DispatchAction>): Promise<void> => {
  activateLoading(dispatch)
  const loginContentResponse = await apiCall(
    await getOptions({
      endpoint: `${API_ENDPOINT}/${API_VERSION}/${ROUTE_CONTENT}`,
      httpMethod: 'GET',
      params: { lang: getLanguage() },
    })
  ).catch((error) => {
    throw error
  })

  if (loginContentResponse?.status === 200) {
    const loginContent: Content = {
      labels: loginContentResponse.data.labels,
      resources: loginContentResponse.data.resources,
      socialNetworks: loginContentResponse.data.socialNetworks,
      success: loginContentResponse.data.success,
    }

    dispatch({ type: APP_LOGIN_CONTENT_TYPE, data: loginContent })
  } else {
    alert(loginContentResponse.data.message)
    // dispatch({ type: GENERAL_ERROR_TYPE, data: 'Ocurrió un error al intentar iniciar sesión. Intente más tarde' })
  }
  deactivateLoading(dispatch)
}

import React, { Dispatch, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DispatchAction } from '../../config/types/contextTypes'
import { UserSession } from '../../config/types/sessionTypes'
import { validateGuestSession } from '../../context/actions/sessionActions'
import { useStateValue } from '../../context/store'
import withRouter from '../../hooks/UseWithRouter'
import { USER_SESSION_KEY } from '../../util/constants'
import { getData, removeData } from '../../util/localStorage'
import Loading from '../loading'

const GuestLogin: React.FC = () => {
  const { dispatch }: { dispatch: Dispatch<DispatchAction> } = useStateValue()

  const [searchParams] = useSearchParams()
  const [removeUserSession, setRemoveUserSession] = useState<boolean>(false)

  const token = searchParams.get('token')
  const device = searchParams.get('device')

  useEffect(() => {
    async function removeOldData() {
      if (!removeUserSession) {
        const userSession: UserSession = await getData(USER_SESSION_KEY)
        if (userSession) {
          removeData(USER_SESSION_KEY)
          setRemoveUserSession(true)
        }
      }
    }

    async function sendValidation(token: string, device: string) {
      await validateGuestSession(dispatch, token, device)
    }

    if (token && (device?.toLowerCase() === 'ios' || device?.toLowerCase() === 'android')) {
      removeOldData()
      sendValidation(token, device)
    }
  })

  return <Loading />
}

export default withRouter(GuestLogin)

import React, { Dispatch } from 'react'

import { Avatar, Grid } from '@mui/material'
import { useGoogleLogin } from '@react-oauth/google'

import { AppState } from '../../config/types'
import { DispatchAction } from '../../config/types/contextTypes'
import { SocialNetworks } from '../../config/types/socialNetworksTypes'
import { googleLogin } from '../../context/actions/loginActions'
import { useStateValue } from '../../context/store'
import googleAvatar from '../../resources/login/google.png'
import { apiCall, getDevice } from '../../util/axios'
import { useStyles } from './useStyles'

const GoogleLogin: React.FC = () => {
  const { state, dispatch }: { state: AppState; dispatch: Dispatch<DispatchAction> } =
    useStateValue()

  const classes = useStyles()

  const socialNetworks: SocialNetworks[] | undefined = state?.content?.socialNetworks
  const social = socialNetworks?.find((s) => s.nombre === 'Google')

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => responseGoogle(tokenResponse),
    onError: (error) => console.log(error),
  })

  const responseGoogle = async (response: any) => {
    if (!response.error) {
      const profileResponse = await apiCall({
        method: 'GET',
        url: `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`,
      })

      if (profileResponse.status === 200) {
        const profile = profileResponse.data

        const data = {
          id: profile.id,
          name: profile.name,
          email: profile.email,
          pic: profile.picture,
          expiresIn: response.expires_in,
          accessToken: response.access_token,
          device: getDevice(),
        }

        googleLogin(dispatch, data)
      } else {
        alert(
          'Ocurriò un error al intentar ingresar desde Google. Por favor intente más tarde o póngase en contacto con su Administrador'
        )
      }
    }
  }

  return social ? (
    <Grid item key="Google">
      <Avatar
        alt={'Google'}
        src={googleAvatar}
        className={classes.socialNetAvatars}
        onClick={() => login()}
      />
    </Grid>
  ) : null
}

export default GoogleLogin

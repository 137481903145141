import React, { Dispatch } from 'react'
import { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from 'react-facebook-login'
import Facebook from 'react-facebook-login/dist/facebook-login-render-props'

import { Avatar, Grid } from '@mui/material'

import { AppState } from '../../config/types'
import { DispatchAction } from '../../config/types/contextTypes'
import { SocialNetworks } from '../../config/types/socialNetworksTypes'
import { fbLogin } from '../../context/actions/loginActions'
import { useStateValue } from '../../context/store'
import fbAvatar from '../../resources/login/facebook.png'
import { getDevice } from '../../util/axios'
import { useStyles } from './useStyles'

const FacebookLogin: React.FC = () => {
  const { state, dispatch }: { state: AppState; dispatch: Dispatch<DispatchAction> } =
    useStateValue()

  const classes = useStyles()

  const socialNetworks: SocialNetworks[] | undefined = state?.content?.socialNetworks
  const social = socialNetworks?.find((s) => s.nombre === 'Facebook')

  const responseFacebook = (userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
    interface FBResponseSuccess extends ReactFacebookLoginInfo {
      expiresIn: any
    }

    if ((userInfo as ReactFacebookLoginInfo).id) {
      const user = userInfo as FBResponseSuccess

      const pic = user.picture?.data ? (user.picture.data.url ? user.picture.data.url : '') : ''
      const data = {
        id: user.id,
        name: user.name,
        email: user.email,
        pic: pic,
        expiresIn: user.expiresIn,
        accessToken: user.accessToken,
        device: getDevice(),
      }

      fbLogin(dispatch, data)
    }
  }

  return social ? (
    <Grid item key="Facebook">
      <Facebook
        appId={social.appID}
        callback={responseFacebook}
        fields="name,email,picture"
        render={(renderProps: any) => (
          <Avatar
            alt={'Facebook'}
            src={fbAvatar}
            className={classes.socialNetAvatars}
            onClick={renderProps.onClick}
          />
        )}
      />
    </Grid>
  ) : null
}

export default FacebookLogin

import { Content } from '../../config/types/contentTypes'
import { DispatchAction } from '../../config/types/contextTypes'

export const APP_LOGIN_CONTENT_TYPE = 'loginContent'

const Reducer = (state: Content, action: DispatchAction): Content => {
  switch (action.type) {
    case APP_LOGIN_CONTENT_TYPE:
      return {
        ...state,
        labels: action.data.labels,
        resources: action.data.resources,
        socialNetworks: action.data.socialNetworks,
        success: action.data.success,
      }

    default:
      return state
  }
}

export default Reducer

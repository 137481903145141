import React, { Dispatch, useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography
} from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { AppState } from '../../../config/types'
import {
  ProductComplement,
  ProductComplementUniones,
  ProductSelected
} from '../../../config/types/contentTypes'
import { DispatchAction } from '../../../config/types/contextTypes'
import { getProductComplement } from '../../../context/actions/productComplementActions'
import {
  DEFAULT_SELECT_ITEM,
  updateProductSelected
} from '../../../context/actions/productSelectedActions'
import { useStateValue } from '../../../context/store'
import { themeCharofil } from '../../../styles/styles'
import Loading from '../../loading'
import { useStyles } from '../useStyles'

const TramosRectosForm: React.FC = () => {
  const classes = useStyles()
  const { state, dispatch }: { state: AppState; dispatch: Dispatch<DispatchAction> } =
    useStateValue()

  const productSelected: ProductSelected | undefined = state?.productSelected
  const productComplement: ProductComplement | undefined = state?.productComplement

  const [verMas, setVerMas] = useState<ProductComplementUniones | undefined>(undefined)

  const handlePeralteChange = async (event: SelectChangeEvent) => {
    if (!productSelected) return

    if (parseInt(event.target.value) === 0) {
      const newProductSelected: ProductSelected = {
        ...productSelected,
        peralte: DEFAULT_SELECT_ITEM,
        ancho: DEFAULT_SELECT_ITEM,
        union: DEFAULT_SELECT_ITEM,
        soporte: DEFAULT_SELECT_ITEM,
      }
      await updateProductSelected(dispatch, newProductSelected)
    } else {
      productSelected.acabado?.peraltes.forEach(async (itemPeralte) => {
        if (itemPeralte.id === parseInt(event.target.value)) {
          const newProductSelected: ProductSelected = {
            ...productSelected,
            peralte: itemPeralte,
            ancho: DEFAULT_SELECT_ITEM,
            union: DEFAULT_SELECT_ITEM,
            soporte: DEFAULT_SELECT_ITEM,
          }
          await updateProductSelected(dispatch, newProductSelected)
        }
      })
    }
  }

  const handlePeralteAnchoChange = async (event: SelectChangeEvent) => {
    if (!productSelected || !productSelected?.peralte) return

    const newProductSelected: ProductSelected = {
      ...productSelected,
      ancho: DEFAULT_SELECT_ITEM,
      union: DEFAULT_SELECT_ITEM,
      soporte: DEFAULT_SELECT_ITEM,
    }
    if (parseInt(event.target.value) !== 0) {
      productSelected.peralte?.anchos?.forEach((itemAncho) => {
        if (itemAncho.id === parseInt(event.target.value)) {
          newProductSelected.ancho = itemAncho
          return
        }
      })
    }

    await updateProductSelected(dispatch, newProductSelected)

    if (newProductSelected.ancho) {
      await getProductComplement(
        dispatch,
        productSelected.id,
        productSelected.peralte.id,
        newProductSelected.ancho.id
      )
    }
  }

  const handleOnChangeMetros = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!productSelected) return

    const metros: number = parseInt(event.target.value.replace(/[^0-9]/g, ''))
    const newProductSelected: ProductSelected = { ...productSelected, metros: metros }
    await updateProductSelected(dispatch, newProductSelected)
  }

  const handleUnionesChange = async (event: SelectChangeEvent) => {
    if (!productSelected || !event.target?.value) return

    if (parseInt(event.target.value) === 0)
      await updateProductSelected(dispatch, { ...productSelected, union: DEFAULT_SELECT_ITEM })
    else if (productComplement) {
      productComplement.uniones.forEach(async (union) => {
        if (union.id === parseInt(event.target.value)) {
          await updateProductSelected(dispatch, { ...productSelected, union: union })
          return
        }
      })
    }
  }

  const handleAddUnionOnClick = async () => {
    if (!productSelected || !productSelected.union) return

    if (productSelected.union.id !== 0) {
      const uniones = productSelected.uniones ? productSelected.uniones : []
      uniones.push(productSelected.union)

      const data = {
        union: DEFAULT_SELECT_ITEM,
        uniones: uniones,
      }
      await updateProductSelected(dispatch, { ...productSelected, ...data })
    }
  }

  const handleEliminarUnionOnClick = async (index: number) => {
    if (!productSelected || !productSelected.uniones) return

    let uniones = productSelected.uniones
    uniones.splice(index, 1)
    await updateProductSelected(dispatch, { ...productSelected, uniones })
  }

  const handleCloseVerMas = () => {
    setVerMas(undefined)
  }

  const handleVerMasOnClick = async (index: number) => {
    if (!productSelected || !productSelected.uniones) return
    setVerMas(productSelected.uniones[index])
  }

  return productSelected ? (
    <Grid container direction="column" className={classes.cardContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl className={classes.selectInput}>
            <ThemeProvider theme={themeCharofil}>
              <InputLabel id="ducto-select-label">Seleccione un Tipo de Ducto:</InputLabel>
              <Select
                fullWidth
                labelId="ducto-select-label"
                id="ducto-select"
                key="ducto-select"
                label="Seleccione un Tipo de Ducto:"
                value={productSelected.peralte ? `${productSelected.peralte.id}` : '0'}
                onChange={handlePeralteChange}
              >
                <MenuItem key={`ducto-empty`} value={0}>
                  -
                </MenuItem>
                {productSelected.acabado
                  ? productSelected.acabado.peraltes
                    ? productSelected.acabado.peraltes.map((currentPeralte) => (
                        <MenuItem key={`ducto-${currentPeralte.id}`} value={currentPeralte.id}>
                          {currentPeralte.name}
                        </MenuItem>
                      ))
                    : ''
                  : ''}
              </Select>
            </ThemeProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.selectInput}>
            <ThemeProvider theme={themeCharofil}>
              <InputLabel id="peralte-ancho-select-label">Seleccione un Ancho:</InputLabel>
              <Select
                labelId="peralte-ancho-select-label"
                id="peralte-ancho-select"
                key="peralte-ancho-select"
                label="Seleccione un Ancho:"
                value={productSelected.ancho ? `${productSelected.ancho.id}` : '0'}
                onChange={handlePeralteAnchoChange}
              >
                <MenuItem key={`peralte-ancho-empty`} value={0}>
                  -
                </MenuItem>
                {productSelected.peralte
                  ? productSelected.peralte.anchos
                    ? productSelected.peralte.anchos.map((currentAncho) => (
                        <MenuItem key={`peralte-ancho-${currentAncho.id}`} value={currentAncho.id}>
                          {currentAncho.ancho}
                        </MenuItem>
                      ))
                    : ''
                  : ''}
              </Select>
            </ThemeProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <ThemeProvider theme={themeCharofil}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="metros"
              name="metros"
              label="Metros Requeridos"
              inputProps={{ style: { textAlign: 'center' } }}
              value={`${productSelected.metros ? productSelected.metros : ''}`}
              onChange={handleOnChangeMetros}
            />
          </ThemeProvider>
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <Grid item xs={12} className={classes.expandGrid}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="añadir-uniones-content"
                id="añadir-uniones-header"
              >
                <Typography color="textPrimary" variant="subtitle2">
                  Añadir Uniones
                  <br />
                  <Typography variant="body2" component="span" color="textSecondary">
                    {productSelected.uniones
                      ? productSelected.uniones.length > 0
                        ? productSelected.uniones.length === 1
                          ? `${productSelected.uniones.length} seleccionada`
                          : `${productSelected.uniones.length} seleccionadas`
                        : `Aún no ha seleccionado uniones`
                      : `Aún no ha seleccionado uniones`}
                  </Typography>
                </Typography>
              </AccordionSummary>
            </Grid>
            <Grid item xs={12}>
              <AccordionDetails>
                <Grid container item spacing={3}>
                  <Grid item xs={12}>
                    <FormControl className={classes.selectInput}>
                      <ThemeProvider theme={themeCharofil}>
                        <InputLabel id="uniones-select-label">Uniones Disponibles:</InputLabel>
                        <Select
                          labelId="uniones-select-label"
                          id="uniones-select"
                          key="uniones-select"
                          label="Uniones Disponibles:"
                          value={productSelected.union ? `${productSelected.union.id}` : '0'}
                          onChange={handleUnionesChange}
                          fullWidth
                        >
                          <MenuItem key={`uniones-empty`} value={0}>
                            -
                          </MenuItem>
                          {productComplement &&
                          (productSelected.peralte?.id !== 0 || productSelected.ancho?.id !== 0)
                            ? productComplement.uniones
                              ? productComplement.uniones.map((union) => (
                                  <MenuItem key={`uniones-${union.id}`} value={union.id}>
                                    {union.nombre}
                                  </MenuItem>
                                ))
                              : ''
                            : ''}
                        </Select>
                      </ThemeProvider>
                    </FormControl>
                    <Grid className={classes.expandGrid2} />
                  </Grid>
                  {productSelected.union ? (
                    productSelected.union.id !== 0 ? (
                      <Grid item xs={12}>
                        <FormControl className={classes.selectInput}>
                          <Grid item xs={12}>
                            <Grid className={classes.expandGrid2} />
                            <Grid item xs={12}>
                              <Button
                                type="submit"
                                variant="contained"
                                onClick={handleAddUnionOnClick}
                                style={{ backgroundColor: '#BE0A15' }}
                              >
                                Añadir
                              </Button>
                            </Grid>
                            <Grid className={classes.expandGrid2} />
                          </Grid>
                        </FormControl>
                      </Grid>
                    ) : (
                      <Grid className={classes.expandGrid2} />
                    )
                  ) : (
                    <Grid className={classes.expandGrid2} />
                  )}
                </Grid>
              </AccordionDetails>
            </Grid>
            <Grid container item spacing={3}>
              <Grid container spacing={0} direction="column" className={classes.complGrid} />
              {productSelected.uniones
                ? productSelected.uniones.map((cartUnion, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={`card-union-selected-${index}`}>
                      <Card>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            alt={cartUnion.nombre}
                            image={cartUnion.img}
                            title={cartUnion.nombre}
                            height="220"
                            sx={{ padding: '1em 1em 0 0', objectFit: 'contain' }}
                          />
                          <CardContent>
                            <Grid style={{ height: 60 }}>
                              <Typography variant="subtitle2" gutterBottom>
                                {cartUnion.nombre}
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                // align="justify"
                              >
                                {cartUnion.descripcion?.substring(0, 50)}...
                              </Typography>
                            </Grid>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <Grid alignItems={'flex-start'} style={{ width: '50%' }}>
                            <Tooltip title="Eliminar" placement="top">
                              <IconButton
                                aria-label="Eliminar"
                                onClick={() => handleEliminarUnionOnClick(index)}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid alignItems={'flex-end'} style={{ width: '50%' }}>
                            <Tooltip title="Ver más" placement="top">
                              <IconButton
                                aria-label="Ver más"
                                onClick={() => handleVerMasOnClick(index)}
                              >
                                <InfoIcon fontSize="small" style={{ color: '#BE0A15' }} />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))
                : ''}
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      {verMas ? (
        <Grid>
          <Dialog
            fullWidth
            maxWidth="sm"
            open={verMas !== undefined}
            onClose={handleCloseVerMas}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{verMas?.nombre}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid>{verMas?.descripcion}</Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ThemeProvider theme={themeCharofil}>
                <Button onClick={handleCloseVerMas} color="primary" autoFocus>
                  Cerrar
                </Button>
              </ThemeProvider>
            </DialogActions>
          </Dialog>
        </Grid>
      ) : null}
    </Grid>
  ) : (
    <Loading />
  )
}

export default TramosRectosForm

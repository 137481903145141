import { ProductContent } from '../../config/types/contentTypes'
import { DispatchAction } from '../../config/types/contextTypes'

export const APP_PRODUCT_TYPE = 'product'

const Reducer = (state: ProductContent, action: DispatchAction): ProductContent => {
  switch (action.type) {
    case APP_PRODUCT_TYPE:
      return { ...action.data }

    default:
      return state
  }
}

export default Reducer

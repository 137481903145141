import React from 'react'

import { Box, Grid, Toolbar } from '@mui/material'

import CharofilLogo from '../../resources/logo-charofil.png'
import Drawer from '../MenuDrawer'
import { useStyles } from './useStyles'

const MainMenu: React.FC = () => {
  const classes = useStyles()

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid style={{ backgroundColor: '#333' }}>
        <Toolbar style={{ minHeight: 50 }}>
          <Grid container direction={'row'}>
            <Grid item xs={1} sm={4} textAlign="left"></Grid>
            <Grid
              item
              xs={10}
              sm={4}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <img src={CharofilLogo} className={classes.toolBarLogo} alt="logo" />
            </Grid>
            <Grid item xs={1} sm={4} textAlign="right">
              <Drawer />
            </Grid>
          </Grid>
        </Toolbar>
      </Grid>
    </Box>
  )
}

export default MainMenu

import { createContext, useContext, useReducer } from 'react'

import { AppContext, AppState } from '../../config/types'
import Reducers from '../reducers'

const initialState: AppState = {
  userSession: undefined,
  status: {
    isLoading: false,
    initialized: false,
    isMaintenance: false,
  },
  content: undefined,
  contentData: undefined,
  error: undefined,
  myList: [],
}

export const Context = createContext<AppContext>({
  state: initialState,
  dispatch: () => null,
})

// @ts-ignore
export const Store: React.FC = ({ children }) => {
  // @ts-ignore
  const [state, dispatch] = useReducer(Reducers, initialState)
  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
}

export const useStateValue = (): AppContext | any => useContext(Context)

const buildStore = { Context, Store }
export default buildStore

import './styles/App.css'
import './styles/slider-animations.css'
import './styles/styles.css'

import { FC } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import { GoogleOAuthProvider } from '@react-oauth/google'

import Scroll2Top from './components/scroll2top'
import { GOOGLE_LOGIN_CLIENT_ID } from './config'
import { Store } from './context/store'
import InitApp from './screens/init'

// import { USER_SESSION_KEY } from './util/constants'
// import { removeData } from './util/localStorage'

// removeData(USER_SESSION_KEY)

const App: FC = () => {
  return (
    <div className="App">
      {/* @ts-ignore  */}
      <Store>
        <GoogleOAuthProvider clientId={GOOGLE_LOGIN_CLIENT_ID}>
          <Router>
            <Scroll2Top />
            <InitApp />
          </Router>
        </GoogleOAuthProvider>
      </Store>
    </div>
  )
}

export default App

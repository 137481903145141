import { makeStyles } from '@mui/styles'
// import background from '../../resources/img/login/login.jpg'

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const useStyles = makeStyles(() => ({
  root: {
    ...flexCenter,
    height: '100vh',
    backgroundColor: '#630c00',
    color: '#ffffff',
  },
  logo: {
    ...flexCenter,
    width: '50%',
  },
  error: {
    paddingTop: 50,
  },
}))

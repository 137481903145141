import React from 'react'

import { Grid, Typography } from '@mui/material'

import { AppState } from '../../config/types'
import { useStateValue } from '../../context/store'
import logo from '../../resources/logo-charofil.png'
import { useStyles } from './useStyles'

const Loading: React.FC = () => {
  const classes = useStyles()
  const { state }: { state: AppState } = useStateValue()

  return (
    <Grid
      container
      component="main"
      className={classes.root}
      alignContent="center"
      direction="column"
    >
      <div className={classes.logo}>
        <img src={logo} alt="Logo" />
      </div>
      <div className={classes.error}>
        <Typography>
          {state?.error ? state.error : 'Ocurrió un error. Por favor, intenta más tarde'}
        </Typography>
      </div>
    </Grid>
  )
}

export default Loading

import React from 'react'

import { Grid } from '@mui/material'

import withRouter from '../../hooks/UseWithRouter'
import { useStyles } from './useStyles'

const Privacy: React.FC = () => {
  const classes = useStyles()

  return (
    <Grid container direction="column" className={classes.cardContainer}>
      <Grid item className={classes.title}>
        AVISO DE PRIVACIDAD
      </Grid>
      <Grid item>
        <span className={classes.imp}>MANCILLA GRUPO, S.A. DE C.V.</span>, con domicilio en Pirul
        25, Santa María Insurgentes, Cuauhtémoc, 06430 Ciudad de México, (en lo sucesivo “
        <span className={classes.imp}>
          <span className={classes.imp}>CHAROFIL</span>
        </span>
        ”), será la responsable del tratamiento de los datos personales y datos personales sensibles
        que le sean confiados y pone a disposición el presente Aviso de Privacidad, el cual se
        realiza de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de
        los Particulares y su Reglamento, así como las demás disposiciones aplicables.
      </Grid>

      <Grid item className={classes.subTitle}>
        FORMAS DE OBTENCIÓN DE DATOS PERSONALES{' '}
      </Grid>
      <Grid item>
        Para cumplir con las finalidades establecidas en el presente aviso,{' '}
        <span className={classes.imp}>CHAROFIL</span> podrá recabar los siguientes datos personales
        del titular; datos de identificación, de contacto, laborales, sobre características físicas,
        académicos, patrimoniales o financieros y biométricos.
      </Grid>

      <Grid item className={classes.subTitle}>
        FORMAS DE OBTENCIÓN DE DATOS PERSONALES
      </Grid>
      <Grid item>
        Esta información podrá ser obtenida de manera directa cuando el titular la entregue
        personalmente y de forma indirecta cuando sea recibida por un tercero o por medios
        digitales.
        <br />
        En caso de obtener datos personales sensibles, el titular deberá de autorizar la obtención,
        tratamiento y transmisión por escrito en el momento en que se obtengan los datos personales
        sensibles, ello con base en el artículo 9 de la Ley Federal de Protección de Datos
        Personales en Posesión de los Particulares.
        <br />
        Si los datos personales sensibles se obtienen por medio de un tercero,{' '}
        <span className={classes.imp}>CHAROFIL</span> tendrá un plazo de 15 días para informar al
        titular respecto de los datos obtenidos, para que éste autorice el tratamiento y transmisión
        de sus datos, o si es el caso solicite la cancelación y eliminación de los mismo.
      </Grid>

      <Grid item className={classes.subTitle}>
        FINALIDAD DEL USO DE LOS DATOS PERSONALES
      </Grid>
      <Grid item>
        La información proporcionada será conservada y protegida, de forma que únicamente se
        utilizará para los siguientes fines:
      </Grid>
      <Grid item>
        I. Prestar los servicios profesionales
        <br />
        II. Para la realización de contratos
        <br />
        III. Resolver sus dudas y comentarios
        <br />
        IV. Identificar al titular
        <br />
        V. Actualizar los datos del titular
        <br />
        VI. Cumplir con la relación jurídica que se forme
        <br />
        VII. Contactar al titular
        <br />
        VIII. Enviar información de su interés
        <br />
        IX. Fines administrativos
        <br />
        <br />
        Así mismo utilizará los datos proporcionados para el cumplimiento de sus obligaciones
        legales, laborales, fiscales y de seguridad social.
      </Grid>

      <Grid item className={classes.subTitle}>
        TRANSMISIÓN DE LOS DATOS PERSONALES{' '}
      </Grid>
      <Grid item>
        <span className={classes.imp}>CHAROFIL</span> podrá transferir los datos proporcionados a
        terceros para cumplir con los fines propios de su objeto.
        <br />
        La transferencia de los datos personales se realizará solo con personas que tengan una
        relación jurídica con <span className={classes.imp}>CHAROFIL</span> y de acuerdo a dicha
        relación, necesiten conocer la información.
        <br />
        En tal caso <span className={classes.imp}>CHAROFIL</span> se compromete a asegurar que los
        terceros cumplan con los requisitos de protección de datos y usos establecidos en este
        aviso.
        <br />
        <span className={classes.imp}>CHAROFIL</span> podrá revelar cualquier información sobre los
        datos proporcionados cuando así lo requiera alguna autoridad competente, y siempre que así
        lo establezca la ley.
      </Grid>

      <Grid item className={classes.subTitle}>
        SEGURIDAD DE LOS DATOS PERSONALES{' '}
      </Grid>
      <Grid item>
        Los datos personales serán tratados bajo las políticas internas,{' '}
        <span className={classes.imp}>CHAROFIL</span> tiene establecidas ciertas medidas
        administrativas, tecnológicas y físicas, necesarias para cuidar los datos personales contra
        daños, perdidas, alteraciones, destrucción o uso no autorizado.
        <br />
        Los datos proporcionados serán conservados por el tiempo necesario para cumplir con las
        finalidades para lo que fueron recabados y para dar cumplimiento con las obligaciones a la
        que está sujeto <span className={classes.imp}>CHAROFIL</span> establecidas en la ley.
      </Grid>

      <Grid item className={classes.subTitle}>
        DERECHOS DEL TITULAR DE LOS DATOS PERSONALES{' '}
      </Grid>
      <Grid item>
        I. Acceder a los datos personales que se conserven.
        <br />
        II. Rectificar o actualizar sus datos personales.
        <br />
        III. Cancelar la autorización de la conservación de los datos personales total o
        parcialmente.
        <br />
        IV. Oponerse al tratamiento de sus datos
        <br />
        En cualquier momento el titular podrá restringir o limitar el acceso o transmisión de sus
        datos personales.
        <br />
        En cualquier momento, el titular podrá revocar el consentimiento otorgado para el
        tratamiento de sus datos personales. Sin embargo, debe considerar que la revocación no es
        inmediata por el cumplimiento de ciertas obligaciones legales.
        <br />
        Asimismo, el titular acepta que, al revocar el consentimiento otorgado, no se le podrá
        seguir brindando el servicio o los servicios solicitados, así como se da por terminada la
        relación existente con <span className={classes.imp}>CHAROFIL</span>.
        <br />
        Para poder dar cumplimiento a los derechos que tiene el titular, deberá de solicitarlo por
        medio de una carta la cual deberá ser enviada a la siguiente dirección de correo
        electrónico: contacto@charofil.mx.
        <br />
        Para conocer los requisitos y procedimientos para dar cumplimiento a sus Derechos ARCO,
        puede solicitar dicha información al correo antes mencionado.
      </Grid>

      <Grid item className={classes.subTitle}>
        RETENCIÓN Y ELIMINACIÓN DE DATOS
      </Grid>
      <Grid item>
        Charofil conserva los datos del usuario durante el tiempo que sea necesario para los fines
        descritos anteriormente.
      </Grid>
      <Grid item>
        Los usuarios pueden solicitar la eliminación de sus cuentas en cualquier momento.
      </Grid>
      <Grid item>
        Los usuarios pueden solicitar la eliminación de su cuenta en cualquier momento a través de
        la cuenta de correo electrónico contacto@charofil.mx indicando el motivo y los siguientes
        datos de la cuenta a eliminar:
      </Grid>
      <Grid item>• Correo electrónico</Grid>
      <Grid item>• Nombre de Usuario</Grid>
      <Grid item>• Contraseña de Usuario</Grid>
      <Grid item>
        Dichos datos deberán coincidir con los datos registrados para su proceso de eliminación.
      </Grid>
      <Grid item>
        Luego de una solicitud de eliminación de cuenta, Charofil elimina la cuenta y los datos del
        usuario, a menos que deban conservarse debido a un problema relacionado con la cuenta del
        usuario o uso indebido, para lo cual se realizara una investigación previa, sus datos
        generalmente se eliminan dentro de los 30 días posteriores a una solicitud de eliminación,
        excepto cuando la retención es necesaria por las razones anteriores.
      </Grid>

      <Grid item className={classes.subTitle}>
        MODIFICACIONES AL AVISO DE PRIVACIDAD
      </Grid>
      <Grid item>
        <span className={classes.imp}>CHAROFIL</span> se reserva el derecho de modificar el presente
        aviso de privacidad en cualquier momento le considere conveniente o debido a cualquier
        modificación en la legislación correspondiente. Los cambios realizados al presente aviso de
        privacidad serán informados por medio de la página de internet de{' '}
        <span className={classes.imp}>CHAROFIL</span> https://charofil.mx/
      </Grid>
      <Grid item className={classes.subTitle}>
        CONTACTO
      </Grid>
      <Grid item>
        Para cualquier duda o comentario favor de enviar un correo electrónico a la siguiente
        dirección contacto@charofil.mx .
      </Grid>
      <Grid item>Última actualización 19 de Agosto del 2021 </Grid>
    </Grid>
  )
}

export default withRouter(Privacy)

import { format, isBefore, parseISO } from 'date-fns'
import { Dispatch } from 'react'

import { API_ENDPOINT, API_VERSION, ROUTE_STATUS } from '../../config'
import { DispatchAction } from '../../config/types/contextTypes'
import { UserSession } from '../../config/types/sessionTypes'
import { STATUS_TYPE } from '../../context/reducers/generalErrorReducer'
import { apiCall, getDevice, getOptions } from '../../util/axios'
import { USER_SESSION_KEY } from '../../util/constants'
import { getData } from '../../util/localStorage'
import { APP_STATUS_LOADING_TYPE, APP_STATUS_TYPE } from '../reducers/appStatusReducer'
import { LOGIN_EXIST } from '../reducers/loginReducer'
import { logout } from './loginActions'

export const initializeState = async (dispatch: Dispatch<DispatchAction>): Promise<void> => {
  activateLoading(dispatch)
  try {
    const userSession: UserSession = await getData(USER_SESSION_KEY)
    if (userSession) {
      const date1 = parseISO(userSession.endTime ? userSession.endTime : '')
      const date2 = parseISO(format(new Date(), 'yyyy-MM-dd HH:mm:ss'))

      if (isBefore(date2, date1)) {
        dispatch({ type: LOGIN_EXIST, data: userSession })
      } else {
        await logout(dispatch)
      }
    }

    await getAppStatus(dispatch)
  } catch (error) {
    dispatch({
      type: STATUS_TYPE,
      data: 'Ha ocurrido un error al intentar obtener información de Charofil. Por favor, intente más tarde',
    })
  }
  deactivateLoading(dispatch)
}

export const getAppStatus = async (dispatch: Dispatch<DispatchAction>): Promise<void> => {
  try {
    const statusResponse = await apiCall(
      await getOptions({
        endpoint: `${API_ENDPOINT}/${API_VERSION}/${ROUTE_STATUS}`,
        httpMethod: 'GET',
        params: { device: getDevice() },
      })
    ).catch((error) => {
      throw error
    })

    if (statusResponse?.data?.success && statusResponse?.status === 200) {
      dispatch({
        type: APP_STATUS_TYPE,
        data: {
          isLoading: false,
          initialized: true,
          isMaintenance: false,
        },
      })
    } else {
      dispatch({
        type: STATUS_TYPE,
        data:
          'Ha ocurrido un error al intentar obtener la información de los servidores de Charofil. ' +
          'Es posible que se reestablezca en algunos minutos. Por favor, intente más tarde',
      })
    }
  } catch (error) {
    dispatch({
      type: STATUS_TYPE,
      data:
        'Ha ocurrido un error al intentar obtener la información de los servidores de Charofil. ' +
        'Es posible que se reestablezca en algunos minutos. Por favor, intente más tarde',
    })
  }
}

export const activateLoading = async (dispatch: Dispatch<DispatchAction>): Promise<void> => {
  dispatch({ type: APP_STATUS_LOADING_TYPE, data: { isLoading: true } })
}

export const deactivateLoading = async (dispatch: Dispatch<DispatchAction>): Promise<void> => {
  dispatch({ type: APP_STATUS_LOADING_TYPE, data: { isLoading: false } })
}

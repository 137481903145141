import React from 'react'

import { Grid, LinearProgress } from '@mui/material'

const LoadingPage: React.FC = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <LinearProgress color="primary" />
        <LinearProgress color="inherit" style={{ opacity: 0.1 }} />
        <LinearProgress color="primary" />
      </Grid>
    </Grid>
  )
}

export default LoadingPage

import { makeStyles } from '@mui/styles'

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const useStyles = makeStyles(() => ({
  toolBarLogo: {
    maxWidth: '280px',
    maxHeight: '38px',
    animation: `$rotate 0.7s ease-in-out 0.5s`,
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotateY(360deg)',
    },
    '100%': {
      transform: 'rotateY(0rem)',
    },
  },
}))

import { Dispatch } from 'react'

import { API_ENDPOINT, API_VERSION, ROUTE_USER_HISTORY } from '../../config'
import {
  UserHistoryRaw,
  UserHistoryRawItem,
  UserHistoryRawItemSoportes,
  UserHistoryRawItemUniones
} from '../../config/types/contentTypes'
import { DispatchAction } from '../../config/types/contextTypes'
import { apiCall, getOptions } from '../../util/axios'

export const getUserHistory = async (userId: number) => {
  const historyResponse = await apiCall(
    await getOptions({
      endpoint: `${API_ENDPOINT}/${API_VERSION}/${ROUTE_USER_HISTORY}`,
      httpMethod: 'GET',
      params: { userID: userId },
    })
  ).catch((error) => {
    throw error
  })

  if (historyResponse?.data?.success) {
    return historyResponse?.data.historial
  }
}

export const preloadUserHistory = async (
  dispatch: Dispatch<DispatchAction>,
  userId: number,
  userHistoryId: number
): Promise<UserHistoryRaw | undefined> => {
  const historyResponseRaw = await apiCall(
    await getOptions({
      endpoint: `${API_ENDPOINT}/${API_VERSION}/${ROUTE_USER_HISTORY}`,
      httpMethod: 'GET',
      params: { userID: userId, historyId: userHistoryId },
    })
  ).catch((error) => {
    throw error
  })

  if (historyResponseRaw?.status === 200 && historyResponseRaw.data) {
    const userHistory: UserHistoryRawItem[] = []
    historyResponseRaw.data.userHistory?.forEach((userHistoryItem: any) => {
      const userHistorySoportes: UserHistoryRawItemSoportes[] = []
      const userHistoryUniones: UserHistoryRawItemUniones[] = []

      userHistoryItem.soportes?.forEach((soporte: any) => {
        userHistorySoportes.push({
          id: soporte.id,
          anchoId: soporte.anchoID,
          peralteId: soporte.peralteID,
          distancia: parseFloat(soporte.distancia),
          distanciaTecho: soporte.distanciaTecho ? soporte.distanciaTecho : undefined,
          kitInstalacionId: soporte.kitInstalacionId ? soporte.kitInstalacionId : undefined,
        })
      })

      userHistoryItem.uniones?.forEach((union: any) => {
        userHistoryUniones.push({
          id: union.id,
          anchoId: union.anchoID,
          peralteId: union.peralteID,
          adicional: parseInt(union.adicional),
        })
      })

      const derivaciones = new Map<number, number>()
      userHistoryItem.derivaciones.forEach((obj: any) => {
        for (const [key, value] of Object.entries(obj)) {
          derivaciones.set(parseInt(`${key}`), parseInt(`${value}`))
        }
      })

      const otrosAccesorios = new Map<number, number>()
      userHistoryItem.otrosAccesorios.forEach((obj: any) => {
        for (const [key, value] of Object.entries(obj)) {
          otrosAccesorios.set(parseInt(`${key}`), parseInt(`${value}`))
        }
      })

      userHistory.push({
        productId: userHistoryItem.product,
        acabadoId: userHistoryItem.acabado,
        acabadoRelatedId: userHistoryItem.acabadoRelated,
        peralteId: userHistoryItem.peralte,
        peralteRelatedId: userHistoryItem.peralteRelated,
        anchoId: userHistoryItem.ancho,
        anchoRelatedId: userHistoryItem.anchoRelated,
        metros: parseInt(userHistoryItem.metros),
        derivaciones: derivaciones,
        otrosAccesorios: otrosAccesorios,
        puestasTierra: userHistoryItem.puestasTierra,
        soportes: userHistorySoportes,
        uniones: userHistoryUniones,
      })
    })

    const result: UserHistoryRaw = {
      id: historyResponseRaw.data.id,
      creation: historyResponseRaw.data.creation,
      userHistory: userHistory,
    }

    return result
  } else if (historyResponseRaw?.data?.error) {
    alert(historyResponseRaw.data.error)
  }

  return undefined
}

import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Box } from '@mui/material'

import Privacy from '../pages/legal/privacy'
import Terms from '../pages/legal/terms'
import Login from '../pages/login'
import GuestLogin from '../pages/login/guestLogin'

const LoginScreen: React.FC = () => {
  return (
    <Box style={{ position: 'relative', minHeight: '100vh' }}>
      <Routes>
        <Route key="GuestLogin" path="/session/:token?/:device?" element={<GuestLogin />} />
        <Route key="Login" path="/" element={<Login />} />
        <Route key="Privacy" path="/avisodeprivacidad" element={<Privacy />} />
        <Route key="Terms" path="/terminoscondiciones" element={<Terms />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Box>
  )
}

export default LoginScreen

import 'react-responsive-carousel/lib/styles/carousel.min.css'

import React, { Dispatch, useEffect, useState } from 'react'
// import Carousel from 'react-material-ui-carousel' // NEW
import { Carousel } from 'react-responsive-carousel'
import { useNavigate } from 'react-router-dom'

import { Avatar, Grid, Typography } from '@mui/material'

import { AppState } from '../../config/types'
import { Content, ProductContent } from '../../config/types/contentTypes'
import { DispatchAction } from '../../config/types/contextTypes'
import { getContent } from '../../context/actions/contentActions'
import { useStateValue } from '../../context/store'
import withRouter from '../../hooks/UseWithRouter'
import { useGeneralStyles } from '../../util/useGeneralStyles'
import Loading from '../loading'
import { useStyles } from './useStyles'

interface Props {}

const Home: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { state, dispatch }: { state: AppState; dispatch: Dispatch<DispatchAction> } =
    useStateValue()

  const generalClasses = useGeneralStyles()
  const classes = useStyles()

  const productsContent: ProductContent[] | undefined = state?.contentData
  const content: Content | undefined = state?.content
  const [background, setBackground] = useState<any | undefined>(undefined)

  useEffect(() => {
    async function getScreenContent() {
      await getContent(dispatch)
    }

    async function setBackgroundImg() {
      if (!content?.resources?.images?.home) return
      const size = Object.keys(content.resources.images.home).length
      const random = Math.floor(Math.random() * (size - 1 + 1) + 1)
      let background = ''
      if (random === 1) background = content.resources.images.home.homeBG1.imagen
      else background = content.resources.images.home.homeBG2.imagen
      setBackground({
        backgroundSize: 'cover',
        backgroundImage: `url(${background})`,
        backgroundAttachment: 'fixed',
        backgroundColor: 'black',
      })
    }

    if (!state.content && !state.status.isLoading) {
      // Get Content
      getScreenContent()
    } else if (!background && content) {
      setBackgroundImg()
    }
  }, [background, content, dispatch, state.content, state.status.isLoading])

  const handleProductOnClick = (product: ProductContent) => {
    navigate({
      pathname: '/product',
      search: `?id=${product.id}`,
    })
  }

  return productsContent && background ? (
    <Grid
      container
      direction="column"
      style={{
        ...background,
      }}
    >
      <Grid container className={generalClasses.generalContainer} direction="column">
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          lg={4}
          style={{ height: '100hv', paddingBottom: 70, opacity: 0.85, color: 'black' }}
        >
          <div className={classes.paper}>
            <Avatar
              src={'https://www.cuantificador.charofil-apps.com/static/img/home/welcome-logo.png'}
              sx={{ width: 80, height: 80, margin: 10 }}
            ></Avatar>
            <Typography component="h1" variant="h4" color="white" textAlign="center" margin={5}>
              Cuantificador
            </Typography>
            <img
              src={'https://www.cuantificador.charofil-apps.com/static/img/home/wolcome-linea.png'}
              alt={''}
            />
            <Typography component="h2" variant="h5" color="white" textAlign="center" margin={5}>
              Bienvenido
            </Typography>
            <Typography component="h2" variant="h6" color="white" textAlign="center" margin={5}>
              Genera una lista de los productos que requieres así como la cantidad de ellos
            </Typography>
            <img
              src={'https://www.cuantificador.charofil-apps.com/static/img/home/wolcome-linea.png'}
              alt={''}
            />
            <Typography component="h6" variant="h6" color="white" textAlign="center" margin={5}>
              Seleccione un producto para iniciar
            </Typography>
            <img
              src={
                'https://www.cuantificador.charofil-apps.com/static/img/home/welcome-flechas.png'
              }
              alt={''}
              style={{ margin: 50 }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ height: '100hv' }}>
        {/* NEW Carousel */}
        {/* <Carousel animation="fade" swipe={true} className={classes.carousel}>
          {productsContent?.map((product, index) => getItem(product, index))}
        </Carousel> */}

        {/* Old Carousel */}
        <Carousel
          showThumbs={false}
          showIndicators={false}
          showStatus={true}
          useKeyboardArrows
          className="presentation-mode"
          autoPlay
          interval={9000}
          infiniteLoop
          emulateTouch
          stopOnHover={true}
          transitionTime={600}
        >
          {productsContent.map((product, index) => (
            <Grid
              key={index}
              className="slider-content"
              style={{
                background: `url('${product.img}') no-repeat center center`,
              }}
            >
              <Grid className="inner">
                <Grid className="text">
                  <Typography variant="body1" component="h1">
                    {product.nombre}
                  </Typography>
                  <Typography variant="h3" component="p">
                    {product.descripcion}
                  </Typography>

                  <button onClick={() => handleProductOnClick(product)}>Seleccionar</button>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Carousel>
      </Grid>
    </Grid>
  ) : (
    <Loading />
  )
}

export default withRouter(Home)

import { ProductSelected } from '../../config/types/contentTypes'
import { DispatchAction } from '../../config/types/contextTypes'

export const APP_PRODUCT_SELECTED_TYPE = 'productSelected'

const Reducer = (state: ProductSelected, action: DispatchAction): ProductSelected => {
  switch (action.type) {
    case APP_PRODUCT_SELECTED_TYPE:
      return action.data ? { ...action.data } : undefined

    default:
      return state
  }
}

export default Reducer

import React, { Dispatch, useEffect, useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import InfoIcon from '@mui/icons-material/Info'
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  ThemeProvider,
  Tooltip,
  Typography
} from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { AppState } from '../../../config/types'
import {
  ProductComplement,
  ProductComplementSoportes,
  ProductSelected
} from '../../../config/types/contentTypes'
import { DispatchAction } from '../../../config/types/contextTypes'
import { getProductComplement } from '../../../context/actions/productComplementActions'
import {
  DEFAULT_SELECT_ITEM,
  updateProductSelected
} from '../../../context/actions/productSelectedActions'
import { useStateValue } from '../../../context/store'
import { themeCharofil } from '../../../styles/styles'
import Loading from '../../loading'
import { useStyles } from '../useStyles'

const CharolasForm: React.FC = () => {
  const classes = useStyles()
  const { state, dispatch }: { state: AppState; dispatch: Dispatch<DispatchAction> } =
    useStateValue()

  const productSelected: ProductSelected | undefined = state?.productSelected
  const productComplement: ProductComplement | undefined = state?.productComplement

  const [soporteDistancia, setSoporteDistancia] = useState<number>(1.5)
  const [soporteDistanciaTecho, setSoporteDistanciaTecho] = useState<number>(0)
  const [soporteKitType, setSoporteKitType] = useState<number>(0)
  const [verMas, setVerMas] = useState<ProductComplementSoportes | undefined>(undefined)

  useEffect(() => {
    async function getComplement(productId: number, peralteId: number, anchoId: number) {
      await getProductComplement(dispatch, productId, peralteId, anchoId)
    }

    if (
      !productComplement &&
      productSelected?.id &&
      productSelected?.peralte &&
      productSelected?.ancho
    ) {
      getComplement(productSelected.id, productSelected.peralte.id, productSelected.ancho.id)
    }
  }, [dispatch, productComplement, productSelected])

  const handleCloseVerMas = () => {
    setVerMas(undefined)
  }

  const handleSoportesChange = async (event: SelectChangeEvent) => {
    if (!productSelected || !event.target?.value) return

    if (parseInt(event.target.value) === 0)
      await updateProductSelected(dispatch, { ...productSelected, soporte: DEFAULT_SELECT_ITEM })
    else if (productComplement) {
      productComplement.soportes.forEach(async (soporte) => {
        if (soporte.id === parseInt(event.target.value)) {
          await updateProductSelected(dispatch, { ...productSelected, soporte: soporte })
          return
        }
      })

      if (productComplement.config.soportes)
        setSoporteDistancia(parseFloat(productComplement.config.soportes[0]))
    }
  }

  const handleAddSoporteOnClick = async () => {
    if (!productSelected || !productSelected.soporte) return

    if (productSelected.soporte.id !== 0 && soporteDistancia > 0) {
      const soportes = productSelected.soportes ? productSelected.soportes : []
      soportes.push({
        ...productSelected.soporte,
        distancia: soporteDistancia,
        distanciaTecho: soporteDistanciaTecho !== 0 ? soporteDistanciaTecho : undefined,
        kitInstalacionId: soporteKitType && soporteKitType !== 0 ? soporteKitType : undefined,
      })
      const data = {
        soporte: DEFAULT_SELECT_ITEM,
        soportes: soportes,
      }
      await updateProductSelected(dispatch, { ...productSelected, ...data })
      setSoporteDistancia(1.5)
      setSoporteDistanciaTecho(0)
      setSoporteKitType(0)
    }
  }

  const handleVerMasOnClick = async (index: number) => {
    if (!productSelected || !productSelected.soportes) return
    setVerMas(productSelected.soportes[index])
  }

  const handleEliminarSoporteOnClick = async (index: number) => {
    if (!productSelected || !productSelected.soportes) return

    let soportes = productSelected.soportes
    soportes.splice(index, 1)
    await updateProductSelected(dispatch, { ...productSelected, soportes })
  }

  const getDistanciaATecho = (soporte: ProductComplementSoportes) => {
    return [
      {
        label: '300mm',
        value: 300,
      },
      {
        label: '1500mm',
        value: 1500,
      },
      {
        label: '3000mm',
        value: 3000,
      },
    ]
  }

  const applyVarillasAndKits = () => {
    // sólo charolas por el momento
    const tab = state?.product?.tabs?.find((tab) => tab.TipoProductoID === 1001)

    return tab ? true : false
  }

  return productSelected ? (
    <Grid container direction="column" className={classes.cardContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <ThemeProvider theme={themeCharofil}>
                  <InputLabel id="soportes-select-label">Soportes Disponibles:</InputLabel>
                  <Select
                    labelId="soportes-select-label"
                    id="soportes-select"
                    key="soportes-select"
                    label="Soportes Disponibles:"
                    value={productSelected.soporte ? `${productSelected.soporte.id}` : '0'}
                    onChange={handleSoportesChange}
                    fullWidth
                    disabled={productSelected.ancho?.ancho ? false : true}
                  >
                    <MenuItem key={`soportes-empty`} value="0">
                      -
                    </MenuItem>
                    {productComplement &&
                    (productSelected?.peralte?.id !== 0 || productSelected?.ancho?.id !== 0)
                      ? productComplement.soportes
                        ? productComplement.soportes.map((soporte: ProductComplementSoportes) => (
                            <MenuItem key={`soportes-${soporte.id}`} value={`${soporte.id}`}>
                              {soporte.nombre}
                            </MenuItem>
                          ))
                        : ''
                      : ''}
                  </Select>
                </ThemeProvider>
              </FormControl>
              <Grid className={classes.expandGrid2} />
            </Grid>
            {!productSelected.ancho?.ancho ? (
              <Grid item xs={12}>
                Se requiere seleccionar un Peralte / Tubería
              </Grid>
            ) : null}
            {productSelected.soporte ? (
              productSelected.soporte.id !== 0 ? (
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <ThemeProvider theme={themeCharofil}>
                        <InputLabel id={`soportes-select-distancia-label`}>
                          Distancia (mts):
                        </InputLabel>
                        <Select
                          labelId="soportes-select-distancia-label"
                          id="soportes-extra-select"
                          key="soportes-extra-select"
                          label="Distancia (mts):"
                          value={
                            soporteDistancia
                              ? soporteDistancia
                              : productComplement?.config.soportes[0]
                          }
                          fullWidth
                          onChange={(event) => {
                            setSoporteDistancia(event.target.value as number)
                          }}
                        >
                          {productComplement?.config.soportes.map((option) => (
                            <MenuItem key={`soportes-extra-${option}`} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </ThemeProvider>
                    </FormControl>
                    <Grid className={classes.expandGrid2} />
                  </Grid>
                  {productSelected.soporte.kitInstalacion &&
                  productComplement?.kitsIntalacion &&
                  applyVarillasAndKits() ? (
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <ThemeProvider theme={themeCharofil}>
                          <InputLabel id={`soportes-select-distancia-techo-label`}>
                            Distancia a Techo:
                          </InputLabel>
                          <Select
                            labelId="soportes-select-distancia-techo-label"
                            id="soportes-select-distancia-techo"
                            key="soportes-select-distancia-techo"
                            label="Distancia a Techo:"
                            value={soporteDistanciaTecho ? `${soporteDistanciaTecho}` : '0'}
                            fullWidth
                            onChange={(event) => {
                              setSoporteDistanciaTecho(parseInt(event.target.value))
                            }}
                          >
                            <MenuItem key={`soportes-empty`} value="0">
                              -
                            </MenuItem>
                            {getDistanciaATecho(productSelected.soporte).map((option, index) => (
                              <MenuItem
                                key={`soportes-select-distancia-techo-val-${index}`}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </ThemeProvider>
                      </FormControl>
                      <Grid className={classes.expandGrid2} />
                      <FormControl fullWidth>
                        <ThemeProvider theme={themeCharofil}>
                          <InputLabel id={`soportes-select-distancia-techolabel`}>
                            Kit de instalación:
                          </InputLabel>
                          <Select
                            labelId="soportes-select-distancia-techo-label"
                            id="soportes-select-distancia-techo"
                            key="soportes-select-distancia-techo"
                            label="Distancia a Techo:"
                            value={soporteKitType ? `${soporteKitType}` : '0'}
                            fullWidth
                            onChange={(event) => {
                              setSoporteKitType(parseInt(event.target.value))
                            }}
                          >
                            <MenuItem key={`soportes-empty`} value="0">
                              -
                            </MenuItem>
                            {productComplement?.kitsIntalacion.map((option, index) => (
                              <MenuItem key={`soportes-select-kit-val-${index}`} value={option.id}>
                                {option.nombre}
                              </MenuItem>
                            ))}
                          </Select>
                        </ThemeProvider>
                      </FormControl>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} style={{ paddingTop: 20, paddingBottom: 15 }}>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={handleAddSoporteOnClick}
                      style={{ backgroundColor: '#BE0A15' }}
                    >
                      Añadir Soporte
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid className={classes.expandGrid2} />
              )
            ) : (
              <Grid className={classes.expandGrid2} />
            )}
          </Grid>
          {productSelected.soportes && productSelected.soportes.length > 0 ? (
            <Grid container item spacing={3}>
              <Grid style={{ paddingTop: 30, paddingLeft: 30 }}>
                <Typography variant="body2">Soportes seleccionados:</Typography>
              </Grid>
              <Grid container spacing={0} direction="column" />
              {productSelected.soportes
                ? productSelected.soportes.map((cartSoporte, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={`card-soportes-selected-${index}`}>
                      <Card>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            alt={cartSoporte.nombre}
                            image={cartSoporte.img}
                            title={cartSoporte.nombre}
                            height="220"
                            sx={{ padding: '1em 1em 0 0', objectFit: 'contain' }}
                          />
                          <CardContent>
                            <Grid style={{ height: 60 }}>
                              <Typography variant="subtitle2" gutterBottom>
                                {cartSoporte.nombre}
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                align="justify"
                              >
                                {cartSoporte.descripcion?.substring(0, 50)}
                                ...
                              </Typography>
                            </Grid>
                          </CardContent>
                        </CardActionArea>
                        <CardActions disableSpacing>
                          <Grid alignItems={'flex-start'} style={{ width: '50%' }}>
                            <Tooltip title="Eliminar" placement="top">
                              <IconButton
                                aria-label="Eliminar"
                                onClick={() => handleEliminarSoporteOnClick(index)}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid alignItems={'flex-end'} style={{ width: '50%' }}>
                            <Tooltip title="Ver más" placement="top">
                              <IconButton
                                aria-label="Ver más"
                                onClick={() => handleVerMasOnClick(index)}
                              >
                                <InfoIcon fontSize="small" style={{ color: '#BE0A15' }} />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))
                : ''}
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {verMas ? (
        <Grid>
          <Dialog
            fullWidth
            maxWidth="sm"
            open={verMas !== undefined}
            onClose={handleCloseVerMas}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{verMas?.nombre}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid>{verMas?.descripcion}</Grid>
                <Grid style={{ paddingTop: 15 }}>Distancia: {verMas?.distancia}</Grid>
                {verMas?.distanciaTecho ? (
                  <Grid>
                    Distancia a Techo:{' '}
                    {
                      getDistanciaATecho(verMas).find(
                        (item) => item.value === verMas?.distanciaTecho
                      )?.label
                    }
                  </Grid>
                ) : null}
                {verMas?.kitInstalacionId && productComplement?.kitsIntalacion ? (
                  <Grid>
                    Kit:{' '}
                    {
                      productComplement?.kitsIntalacion.find(
                        (kit) => kit.id === verMas.kitInstalacionId
                      )?.nombre
                    }
                  </Grid>
                ) : null}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ThemeProvider theme={themeCharofil}>
                <Button onClick={handleCloseVerMas} color="primary" autoFocus>
                  Cerrar
                </Button>
              </ThemeProvider>
            </DialogActions>
          </Dialog>
        </Grid>
      ) : null}
    </Grid>
  ) : (
    <Loading />
  )
}

export default CharolasForm

import { AppStatus } from '../../config/types'
import { DispatchAction } from '../../config/types/contextTypes'

export const APP_STATUS_TYPE = 'appStatus'
export const APP_STATUS_LOADING_TYPE = 'appLoading'

const Reducer = (state: AppStatus, action: DispatchAction): AppStatus => {
  switch (action.type) {
    case APP_STATUS_TYPE:
      return {
        ...state,
        ...action.data,
      }

    case APP_STATUS_LOADING_TYPE:
      return {
        ...state,
        ...action.data,
      }

    default:
      return state
  }
}

export default Reducer

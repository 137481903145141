import { Dispatch, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { AppState } from '../config/types'
import { ProductSelected } from '../config/types/contentTypes'
import { DispatchAction } from '../config/types/contextTypes'
import { updateProductSelected } from '../context/actions/productSelectedActions'
import { useStateValue } from '../context/store'

function CleanState({ children }: { children: any }) {
  const location = useLocation()

  const { state, dispatch }: { state: AppState; dispatch: Dispatch<DispatchAction> } =
    useStateValue()

  const productSelected: ProductSelected | undefined = state?.productSelected

  useEffect(() => {
    async function cleanProductSelected() {
      await updateProductSelected(dispatch, undefined)
    }

    const toIgnore = ['/product']

    if (!toIgnore.includes(location.pathname) && productSelected) {
      cleanProductSelected()
    }
  }, [dispatch, location.pathname, productSelected, state.productSelected])

  return <>{children}</>
}

export default CleanState

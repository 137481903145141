import React, { useState } from 'react'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography
} from '@mui/material'

import { NewUser } from '../../../config/types/userTypes'
import { createNewUser } from '../../../context/actions/usersAction'
import { themeCharofil } from '../../../styles/styles'
import { useStyles } from '../useStyles'

interface NewAccountProps {
  onCancel: () => void
}

const NewAccount: React.FC<NewAccountProps> = ({ onCancel }) => {
  const classes = useStyles()

  const [user, setUser] = useState('')
  const [name, setName] = useState('')
  const [last, setLast] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [showPassword, setShowPassword] = useState(false)
  const [btnBlocked, setBtnBlocked] = useState(false)
  const [error, setError] = useState('')

  const handleSaveUser = async () => {
    // dispatch && history.push
    if (!user && !name && !last && !email && !password) {
      setError('Aún no ha llenado los campos')
      return
    }
    if (user.length < 8) {
      setError('El nombre de usuario debe tener un mínimo de 8 caracteres')
      return
    }
    if (!name || name.length < 3) {
      setError('El nombre debe tener un mínimo de 3 caracteres')
      return
    }
    if (!last || last.length < 3) {
      setError('Los apellidos deben tener un mínimo de 3 caracteres')
      return
    }
    if (!email || !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setError('El correo no es válido')
      return
    }
    if (!password || password.length < 8) {
      setError('La contraseña no puede tener menos de 8 caracteres')
      return
    }

    setBtnBlocked(true)

    const userData: NewUser = {
      username: user.trim(),
      name: name.trim(),
      lastname: last.trim(),
      email: email.trim(),
      password: password.trim(),
    }

    const responseMsg: string | undefined = await createNewUser(userData)
    if (responseMsg) {
      setError(responseMsg)
    } else {
      onCancel()
    }

    setBtnBlocked(false)
  }

  return (
    <Grid container direction="column" className={classes.cardContainer}>
      <Grid container style={{ padding: 10 }}>
        <Grid item xs={12} style={{ padding: 20 }}>
          <Grid xs={12} display={'flex'} alignItems="center" justifyContent="center">
            <Typography component="h1" variant="h5">
              Nuevo Usuario
            </Typography>
          </Grid>
          <ThemeProvider theme={themeCharofil}>
            <Grid item xs={12}>
              <TextField
                required
                variant="outlined"
                margin="normal"
                fullWidth
                id="user"
                label="Usuario"
                name="user"
                value={user}
                onChange={(event) => {
                  const value = event.target.value.trim().replace(/[^a-zA-Z0-9]/g, '')
                  setUser(value.replace(' ', ''))
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                variant="outlined"
                margin="normal"
                fullWidth
                id="name"
                label="Nombre"
                name="name"
                value={name}
                onChange={(event) => {
                  const value = event.target.value
                    .replace(/[^a-zA-Z\s]+/g, '')
                    .replace('  ', ' ')
                    .replace(/\b[a-zA-Z]/g, (match) => match.toUpperCase())

                  setName(value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                autoCapitalize="none"
                variant="outlined"
                margin="normal"
                fullWidth
                id="last"
                label="Apellidos"
                name="last"
                value={last}
                onChange={(event) => {
                  const value = event.target.value
                    .replace(/[^a-zA-Z\s]+/g, '')
                    .replace('  ', ' ')
                    .replace(/\b[a-zA-Z]/g, (match) => match.toUpperCase())
                  setLast(value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                autoCapitalize="none"
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                value={email}
                onChange={(event) => {
                  const value = event.target.value
                    .trim()
                    .replace(/[^a-zA-Z0-9.@_-]+/g, '')
                    .replace('  ', ' ')
                  setEmail(value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <FormControl fullWidth style={{ alignContent: 'center', justifyContent: 'center' }}>
                  <TextField
                    required
                    autoCapitalize="none"
                    label="Contraseña"
                    variant="outlined"
                    margin="normal"
                    value={password}
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    onChange={(event) => setPassword(event.target.value.trim().replace(' ', ''))}
                  />
                  <Tooltip
                    title={!showPassword ? 'Ver Contraseña' : 'Ocultar Contraseña'}
                    placement="top"
                    style={{
                      position: 'absolute',
                      right: 22,
                    }}
                  >
                    <IconButton
                      aria-label="Ver Contraseña"
                      onClick={() => {
                        setShowPassword(showPassword ? false : true)
                      }}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon fontSize="medium" />
                      ) : (
                        <VisibilityIcon fontSize="medium" />
                      )}
                    </IconButton>
                  </Tooltip>
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: 30 }}>
                <Typography component="h5" variant="body2">
                  Se requieren 8 caracteres como mínimo
                </Typography>
              </Grid>
            </Grid>
          </ThemeProvider>
          <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              onClick={handleSaveUser}
              style={{ backgroundColor: '#BE0A15' }}
              disabled={btnBlocked}
            >
              Crear Usuario
            </Button>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              onClick={onCancel}
              style={{ backgroundColor: '#BE0A15' }}
              disabled={btnBlocked}
            >
              Cancelar
            </Button>
          </Grid>
          {error ? (
            <Grid item xs={12} style={{ paddingTop: 20 }}>
              <Typography component="h5" variant="body2" color={'error'}>
                {error}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NewAccount

import React, { Dispatch, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ThemeProvider,
  Typography
} from '@mui/material'

import TableCollapse from '../../components/table-collapse'
import { getHistoryHeaders } from '../../components/table-collapse/configuration'
import { AppState } from '../../config/types'
import { ProductSelected, UserHistoryItem } from '../../config/types/contentTypes'
import { DispatchAction } from '../../config/types/contextTypes'
import { UserSession } from '../../config/types/sessionTypes'
import { calculateMaterial } from '../../context/actions/calculateMaterialActions'
import { exportToXLSX } from '../../context/actions/exportFileActions'
import { saveMyList, updateMyList } from '../../context/actions/myListActions'
import { getProductByIdMyList } from '../../context/actions/productActions'
import { useStateValue } from '../../context/store'
import { themeCharofil } from '../../styles/styles'
import { getDevice } from '../../util/axios'

const MyList: React.FC = () => {
  const navigate = useNavigate()
  const { state, dispatch }: { state: AppState; dispatch: Dispatch<DispatchAction> } =
    useStateValue()

  const user: UserSession | undefined = state?.userSession
  const myList: ProductSelected[] | undefined = state?.myList
  const [currentList, setCurrentList] = useState<UserHistoryItem[][] | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openOnSaveModal, setOpenOnSaveModal] = useState<boolean>(false)

  useEffect(() => {
    async function calculate(myList: ProductSelected[]) {
      setCurrentList(await calculateMaterial(myList))
      setIsLoading(false)
    }

    if (!currentList && myList?.length && !isLoading && !openOnSaveModal) {
      setIsLoading(true)
      calculate(myList)
    }
  }, [currentList, dispatch, isLoading, myList, openOnSaveModal])

  const onPreLoad = async (listIndex: number | undefined, itemIndex: number | undefined) => {
    if (!myList || !(listIndex !== undefined && itemIndex !== undefined)) return

    await handlePreloadByProductIndex(listIndex)
  }

  const handlePreloadByProductIndex = async (productIndex: number) => {
    if (!myList) return

    const productSelected = myList[productIndex]
    await getProductByIdMyList(dispatch, productSelected)
    navigate(
      {
        pathname: '/product',
        search: `?id=${productSelected.id}`,
      },
      {
        state: { indexEdit: productIndex },
      }
    )
  }

  const handleOnSave = async () => {
    if (!myList || !user) return

    await saveMyList(dispatch, myList, user)
    await updateMyList(dispatch, [])
    setOpenOnSaveModal(true)
  }

  const handleOnSaveAndDownloadExcel = async (items: UserHistoryItem[]) => {
    if (!myList || !user) return

    await saveMyList(dispatch, myList, user)
    await updateMyList(dispatch, [])

    const headers = getHistoryHeaders(false)

    const data = {
      headers: [...headers.filter((k) => k.exportName !== 'Editar').map((k) => k.exportName)],
      items: items,
    }

    await exportToXLSX(data, user.externalDevice ? user.externalDevice : getDevice())

    setOpenOnSaveModal(true)
  }

  const handleOnSaveRedirectModal = async (redirect: boolean) => {
    if (redirect) {
      navigate('/home')
    } else {
      navigate('/historial')
    }

    setOpenOnSaveModal(false)
  }

  const handleOnDeleteItem = async (
    listIndex: number | undefined,
    indexItem: number | undefined
  ) => {
    if (!myList || !currentList || listIndex === undefined || indexItem === undefined) return

    const itemToDelete = currentList[listIndex][indexItem]
    const listSelected = myList[listIndex]

    switch (itemToDelete.type) {
      case 'product':
        myList.splice(listIndex, 1)
        break

      case 'derivacion':
        if (itemToDelete.id !== undefined) {
          listSelected.derivaciones?.delete(itemToDelete.id)
          myList[listIndex].derivaciones = listSelected.derivaciones
        }
        break

      case 'soporte':
        let indexSoporteToDelete: number | undefined = undefined
        listSelected.soportes?.forEach((s, soporteIndex) => {
          if (s.id === itemToDelete.id && indexSoporteToDelete === undefined) {
            indexSoporteToDelete = soporteIndex
          }
        })

        if (indexSoporteToDelete !== undefined) {
          listSelected.soportes?.splice(indexSoporteToDelete, 1)
          myList[listIndex].soportes = listSelected.soportes
        }
        break

      case 'union':
        let indexUnionToDelete: number | undefined = undefined
        listSelected.uniones?.forEach((u, unionIndex) => {
          if (u.id === itemToDelete.id && indexUnionToDelete === undefined) {
            indexUnionToDelete = unionIndex
          }
        })

        if (indexUnionToDelete !== undefined) {
          listSelected.uniones?.splice(indexUnionToDelete, 1)
          myList[listIndex].uniones = listSelected.uniones
        }
        break

      case 'otros-accesorios':
        if (itemToDelete.id !== undefined) {
          listSelected.otrosAccesorios?.delete(itemToDelete.id)
          myList[listIndex].otrosAccesorios = listSelected.otrosAccesorios
        }
        break

      default:
        break
    }

    await updateMyList(dispatch, myList)
    setCurrentList(await calculateMaterial(myList))
  }

  const handleDeleteAll = async () => {
    if (!myList || !currentList) return

    await updateMyList(dispatch, [])
    navigate('/home')
  }

  const buildItems = (): UserHistoryItem[] => {
    if (!currentList) return []

    const items: UserHistoryItem[] = []
    currentList.forEach((currentListItems, listIndex) => {
      currentListItems.forEach((itemHistory, productIndex) => {
        const item = { ...itemHistory }
        item.listIndex = listIndex
        item.itemIndex = productIndex
        items.push(item)
      })
    })

    return items
  }

  return currentList && currentList.length > 0 ? (
    <Grid
      container
      direction="column"
      style={{ minHeight: '95vh', backgroundColor: '#f1f1f1' }}
      alignItems="center"
    >
      <Grid item xs={12} style={{ width: '80%' }}>
        <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 45 }}>
          <TableCollapse
            title={`Lista de Materiales`}
            items={buildItems()}
            onPreLoad={onPreLoad}
            onSave={handleOnSave}
            onSaveAndDownloadExcel={() => handleOnSaveAndDownloadExcel(buildItems())}
            onDeleteItem={handleOnDeleteItem}
            onDeleteAll={handleDeleteAll}
          />
        </Grid>
        <Grid item xs={12}>
          Usted puede seguir agregando productos a esta lista seleccionando un producto nuevo desde
          el Menu o desde el Home
        </Grid>
        <Grid style={{ height: 50 }} />
      </Grid>
      <Grid style={{ paddingTop: 10, paddingBottom: 25 }}>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={openOnSaveModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Lista Guardada</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Su lista se ha guardado en su Historial.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={themeCharofil}>
              <Button onClick={() => handleOnSaveRedirectModal(false)} color="primary">
                Revisar Historial
              </Button>
            </ThemeProvider>
            <ThemeProvider theme={themeCharofil}>
              <Button onClick={() => handleOnSaveRedirectModal(true)} color="primary" autoFocus>
                Ir a Home
              </Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      direction="column"
      style={{ minHeight: '95vh', backgroundColor: '#f1f1f1' }}
      alignItems="center"
    >
      <Grid item xs={12} style={{ width: '80%', paddingTop: '5%' }}>
        <Typography variant="h6">La lista se encuentra vacía.</Typography>
        <Typography variant="body2">Por favor seleccione productos para poder continuar</Typography>
      </Grid>
    </Grid>
  )
}

export default MyList

import React from 'react'

import { AppState } from '../config/types'
import { useStateValue } from '../context/store'
import Error from '../pages/error'
import HomeScreen from './homeScreen'
import LoginScreen from './loginScreen'

const WrapperScreen: React.FC = () => {
  const { state }: { state: AppState } = useStateValue()

  const getScreen = () => {
    if (!state.userSession) {
      return <LoginScreen />
    } else if (state.userSession) {
      return <HomeScreen />
    } else if (state.error) {
      return <Error />
    } else {
      console.log('¿¿¿ Who are you ???')
      return null
    }
  }

  return getScreen()
}

export default WrapperScreen

import React, { Dispatch, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router'

import { Box } from '@mui/material'

import Menu from '../components/menu'
import { AppState } from '../config/types'
import { DispatchAction } from '../config/types/contextTypes'
import { getProductsContent } from '../context/actions/productsContentActions'
import { useStateValue } from '../context/store'
import Account from '../pages/account'
import Footer from '../pages/footer'
import History from '../pages/history'
import Home from '../pages/home'
import InactiveDialog from '../pages/idleDialog'
import Privacy from '../pages/legal/privacy'
import Terms from '../pages/legal/terms'
import MyList from '../pages/myList'
import Product from '../pages/product'
import CleanState from './cleanState'

// import Product from '../pages/history'

const HomeScreen: React.FC = () => {
  const { state, dispatch }: { state: AppState; dispatch: Dispatch<DispatchAction> } =
    useStateValue()

  useEffect(() => {
    async function callProductsContent() {
      await getProductsContent(dispatch)
    }

    if (!state.error && !state.contentData) {
      callProductsContent()
    }
  }, [dispatch, state.contentData, state.error])

  return (
    <Box style={{ position: 'relative', minHeight: '100vh' }}>
      <CleanState>
        {state.contentData ? <Menu /> : null}
        <Routes>
          <Route key="Root" path="/" element={<Navigate to="/home" />} />
          <Route key="Home" path="/home" element={<Home />} />
          <Route key="MyList" path="/myList" element={<MyList />} />
          <Route key="Historial" path="/historial" element={<History />} />
          <Route key="Product" path="/product" element={<Product />} />
          <Route key="Account" path="/account" element={<Account />} />
          <Route key="Privacy" path="/avisodeprivacidad" element={<Privacy />} />
          <Route key="Terms" path="/terminoscondiciones" element={<Terms />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Footer position="absolute" />
        <InactiveDialog />
      </CleanState>
    </Box>
  )
}

export default HomeScreen

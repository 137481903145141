import { makeStyles } from '@mui/styles'

// import background from '../../resources/img/login/login.jpg'

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  languages: {
    ...flexCenter,
    margin: 4,
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
  },
  language: {
    fontSize: 11,
    color: '#666666',
    paddingLeft: 10,
    paddingRight: 10,
    cursor: 'pointer',
  },
  cardContainer: {
    ...flexCenter,
    width: '100%',
  },
  button: {
    margin: 200,
  },
  submit: {
    paddingTop: 20,
    margin: 2,
  },
  paper: {
    ...flexCenter,
    margin: 20,
    flexDirection: 'column',
  },
  avatar: {
    margin: 10,
    backgroundColor: '#E90031',
    bgcolor: '#E90031',
  },
  socialNetworks: {
    ...flexCenter,
    padding: '15px',
  },
  socialNetAvatars: {
    margin: 3,
    width: 6,
    height: 6,
    cursor: 'pointer',
  },
  errorMsgGrid: {
    paddingTop: '35px',
    height: '25px',
  },
  errorMsg: {
    ...flexCenter,
    maxHeight: '15px',
  },
}))

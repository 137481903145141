import React from 'react'

import PersonIcon from '@mui/icons-material/Person'
import { Avatar, Grid, ListItem, Typography } from '@mui/material'

interface ListItemHeaderProps {
  keyName: string
  text: string | undefined
  image?: string
}

const ListItemHeaderCustom: React.FC<ListItemHeaderProps> = ({ keyName, text, image }) => {
  return (
    <ListItem key={keyName}>
      <Grid container direction="column" justifyContent={'center'} alignItems={'center'}>
        <Grid item xs={12}>
          {image ? (
            <Avatar
              alt="User Image"
              src={image}
              sx={{
                width: 56,
                height: 56,
              }}
            />
          ) : (
            <Avatar alt="User Image" sx={{ width: 56, height: 56 }}>
              <PersonIcon />
            </Avatar>
          )}
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 15, paddingBottom: 5 }}>
          <Typography style={{ textAlign: 'center' }}>{text ? text : ' - '}</Typography>
        </Grid>
      </Grid>
    </ListItem>
  )
}

export default ListItemHeaderCustom

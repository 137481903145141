import React, { Dispatch, useState } from 'react'

import InfoIcon from '@mui/icons-material/Info'
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography
} from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

// redux
import { AppState } from '../../../config/types'
import {
  ProductAcabadosPeraltesAnchosDerivaciones,
  ProductSelected
} from '../../../config/types/contentTypes'
import { DispatchAction } from '../../../config/types/contextTypes'
import { updateProductSelected } from '../../../context/actions/productSelectedActions'
import { useStateValue } from '../../../context/store'
import { themeCharofil } from '../../../styles/styles'
import Loading from '../../loading'
import { useStyles } from '../useStyles'

const DerivacionesForm: React.FC = () => {
  const classes = useStyles()
  const { state, dispatch }: { state: AppState; dispatch: Dispatch<DispatchAction> } =
    useStateValue()

  const tipoFilterDefault: string = 'Todos'
  const paginationSize: number = 8

  const productSelected: ProductSelected | undefined = state?.productSelected
  const [radioFilter, setRadioFilter] = useState<number>(0)
  const [tiposFilter, setTiposFilter] = useState<string>(tipoFilterDefault)
  const [page, setPage] = useState<number>(1)

  const handleRadioChange = (event: SelectChangeEvent) => {
    setRadioFilter(parseInt(event.target.value))
    setTiposFilter(tipoFilterDefault)
    setPage(1)
  }

  const handleDerivacionChange = (event: SelectChangeEvent) => {
    setTiposFilter(event.target.value)
    setPage(1)
  }

  const displayRadioFilter = (
    derivaciones: ProductAcabadosPeraltesAnchosDerivaciones[] | undefined
  ) => {
    if (!derivaciones) return false
    let displayFilter = false

    derivaciones.forEach((derivacion: ProductAcabadosPeraltesAnchosDerivaciones) => {
      if (derivacion.radio) {
        displayFilter = true
        return
      }
    })

    return displayFilter
  }

  const displayTipoDerivacionFilter = (): boolean => {
    let displayFilter = false

    if (
      !productSelected?.ancho?.derivaciones &&
      !productSelected?.anchoRelated?.derivaciones &&
      (!productSelected?.derivacionesByAncho ||
        (productSelected.derivacionesByAncho.size > 0 &&
          !productSelected.derivacionesByAncho?.has(`${productSelected.ancho?.ancho}`)))
    ) {
      return false
    } else {
      if (!displayFilter && productSelected?.ancho?.derivaciones) {
        productSelected?.ancho?.derivaciones.forEach((derivacion) => {
          if (derivacion.tipo) {
            displayFilter = true
            return
          }
        })
      }

      if (!displayFilter && productSelected?.anchoRelated?.derivaciones) {
        productSelected?.anchoRelated?.derivaciones.forEach((derivacion) => {
          if (derivacion.tipo) {
            displayFilter = true
            return
          }
        })
      }

      if (
        !displayFilter &&
        productSelected?.derivacionesByAncho &&
        productSelected?.derivacionesByAncho.size > 0 &&
        productSelected?.derivacionesByAncho?.has(`${productSelected.ancho?.ancho}`)
      ) {
        const derivaciones = productSelected.derivacionesByAncho?.get(
          `${productSelected.ancho?.ancho}`
        )
        derivaciones?.forEach((derivacion) => {
          if (derivacion.tipo) {
            displayFilter = true
            return
          }
        })
      }
    }

    return displayFilter
  }

  const getRadioFilterOps = (): number[] => {
    if (
      !productSelected ||
      (!productSelected.ancho?.derivaciones && !productSelected.derivacionesByAncho)
    ) {
      return []
    }

    let all: ProductAcabadosPeraltesAnchosDerivaciones[] = []

    if (
      productSelected.ancho?.derivaciones ||
      (productSelected.derivacionesByAncho &&
        productSelected.derivacionesByAncho?.size > 0 &&
        productSelected.ancho?.ancho)
    ) {
      const byAncho = productSelected.derivacionesByAncho
        ? productSelected.derivacionesByAncho.get(`${productSelected.ancho.ancho}`)
        : []
      all = byAncho
        ? productSelected.ancho?.derivaciones
          ? [...productSelected.ancho?.derivaciones, ...byAncho]
          : byAncho
        : productSelected.ancho?.derivaciones
        ? [...productSelected.ancho?.derivaciones]
        : []
    } else if (productSelected.ancho?.derivaciones && !productSelected.derivacionesByAncho) {
      all = [...productSelected.ancho?.derivaciones]
    } else if (
      !productSelected.ancho?.derivaciones &&
      productSelected.derivacionesByAncho &&
      productSelected.derivacionesByAncho?.size > 0 &&
      productSelected.ancho?.ancho
    ) {
      const byAncho = productSelected.derivacionesByAncho.get(`${productSelected.ancho.ancho}`)
      all = byAncho ? [...byAncho] : []
    } else {
      return []
    }

    const options: number[] = []
    all.forEach((derivacion) => {
      if (derivacion.radio) {
        if (!options.includes(derivacion.radio)) options.push(derivacion.radio)
      }
    })

    return options.sort((a, b) => {
      if (a < b) return -1
      else if (a > b) return 1
      else return 0
    })
  }

  const getTipoDerivacionFilterOps = (): string[] => {
    if (
      !productSelected ||
      (!productSelected.ancho?.derivaciones && !productSelected.derivacionesByAncho)
    )
      return []

    let all: ProductAcabadosPeraltesAnchosDerivaciones[] = []

    if (productSelected.ancho?.derivaciones) all = productSelected.ancho.derivaciones
    if (productSelected.anchoRelated?.derivaciones)
      all = [...all, ...productSelected.anchoRelated.derivaciones]

    if (
      productSelected.derivacionesByAncho &&
      productSelected.derivacionesByAncho?.size > 0 &&
      productSelected.ancho?.ancho
    ) {
      let derivacionesByAncho: ProductAcabadosPeraltesAnchosDerivaciones[] | undefined =
        productSelected.derivacionesByAncho.get(`${productSelected.ancho.ancho}`)
      if (derivacionesByAncho) all = [...all, ...derivacionesByAncho]
    }

    let options: string[] = []
    all.forEach((derivacion) => {
      if (derivacion.tipo) {
        if (!options.includes(derivacion.tipo)) {
          options.push(derivacion.tipo)
        }
      }
    })

    return options.sort()
  }

  const sortDerivaciones = () => {
    if (
      !productSelected ||
      (!productSelected.ancho?.derivaciones && !productSelected.derivacionesByAncho)
    )
      return []

    let all: ProductAcabadosPeraltesAnchosDerivaciones[] = []

    if (productSelected.ancho?.derivaciones) all = productSelected.ancho.derivaciones
    if (productSelected.anchoRelated?.derivaciones)
      all = [...all, ...productSelected.anchoRelated.derivaciones]

    if (
      productSelected.derivacionesByAncho &&
      productSelected.derivacionesByAncho?.size > 0 &&
      productSelected.ancho?.ancho
    ) {
      let derivacionesByAncho: ProductAcabadosPeraltesAnchosDerivaciones[] | undefined =
        productSelected.derivacionesByAncho.get(`${productSelected.ancho.ancho}`)
      if (derivacionesByAncho) all = [...all, ...derivacionesByAncho]
    }

    return all.sort((a, b) => {
      if (a.radio < b.radio) return -1
      else if (a.radio > b.radio) return 1
      else return 0
    })
  }

  const handlePiezasOnChange = async (derivacionId: number, value: number) => {
    if (!productSelected) return

    const map = productSelected.derivaciones
      ? productSelected.derivaciones
      : new Map<number, number>()
    if (!Number.isNaN(value)) {
      map.set(derivacionId, value)
    } else if (map.has(derivacionId) && Number.isNaN(value)) {
      map.delete(derivacionId)
    }

    await updateProductSelected(dispatch, { ...productSelected, derivaciones: map })
  }

  const [open, setOpen] = useState(false)
  const [dialogDescription, setDialogDescription] = useState({ title: '', description: '' })

  const handleOpenDialog = (title: string, description: string) => {
    const texts = {
      title: title,
      description: description,
    }
    setDialogDescription(texts)
    setOpen(true)
  }

  const handleCloseDialog = () => {
    setOpen(false)
  }

  const printItems = (derivacion: ProductAcabadosPeraltesAnchosDerivaciones) => {
    if (!derivacion) return false

    if (radioFilter === 0 && tiposFilter === tipoFilterDefault) {
      // Muestra Todo
      return true
    } else if (radioFilter !== 0 && tiposFilter !== tipoFilterDefault) {
      // Muestra filtros de Radio y filtros de Tipo
      if (derivacion.radio === radioFilter && derivacion.tipo === tiposFilter) {
        return true
      } else {
        return false
      }
    } else if (radioFilter !== 0) {
      // Muestra sólo radio
      if (derivacion.radio === radioFilter) {
        return true
      } else {
        return false
      }
    } else if (tiposFilter !== tipoFilterDefault) {
      // Muestra solo Tipos
      if (derivacion.tipo === tiposFilter) {
        return true
      } else {
        return false
      }
    }
    return true
  }

  const paginate = (list: JSX.Element[]) => {
    if (!list) return []

    const pageSize = Math.ceil(list.length / paginationSize)

    return Array.from({ length: pageSize }, (_, index) => {
      const start = index * paginationSize
      return list.slice(start, start + paginationSize)
    })
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const printDerivaciones = (derivacion: ProductAcabadosPeraltesAnchosDerivaciones) => {
    return printItems(derivacion) ? (
      <Grid item key={derivacion.id} xs={12} sm={6} md={4} lg={3}>
        <Card>
          <CardActionArea>
            <CardMedia
              component="img"
              alt={derivacion.nombre}
              image={derivacion.img ? derivacion.img : ''}
              title={derivacion.nombre}
              height="220"
              sx={{ padding: '1em 1em 0 0', objectFit: 'contain' }}
            />
            <CardContent className={classes.cardContent}>
              <Grid style={{ height: 45 }}>
                <Typography variant="subtitle2" gutterBottom>
                  {derivacion.nombre}
                </Typography>
              </Grid>
            </CardContent>
          </CardActionArea>
          <CardActions disableSpacing>
            <Grid container direction="column">
              <Grid item xs={12}>
                <ThemeProvider theme={themeCharofil}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id={`piezas-${derivacion.id}`}
                    label="Piezas"
                    name={`piezas-${derivacion.id}`}
                    value={getDerivacionValue(derivacion.id)}
                    inputProps={{
                      style: { textAlign: 'center' },
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handlePiezasOnChange(
                        derivacion.id,
                        parseInt(event.target.value.replace(/[^0-9]/g, ''))
                      )
                    }
                  />
                </ThemeProvider>
              </Grid>
              <Grid>
                <Tooltip title="Ver más" placement="top">
                  <IconButton
                    aria-label="Ver más"
                    onClick={() => handleOpenDialog(derivacion.nombre, derivacion.descripcion)}
                  >
                    <InfoIcon fontSize="small" style={{ color: '#BE0A15' }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    ) : null
  }

  const getDerivacionValue = (id: number) => {
    if (!productSelected || !productSelected.derivaciones) return ''

    if (productSelected.derivaciones?.has(id)) return productSelected.derivaciones.get(id)
    else return ''
  }

  const getDerivaciones = () => {
    const derivaciones: JSX.Element[] = []

    sortDerivaciones().forEach((derivacion) => {
      const dev = printDerivaciones(derivacion)
      if (dev) derivaciones.push(dev)
    })

    return derivaciones
  }

  return productSelected ? (
    <Grid container direction="column" className={classes.cardContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <ThemeProvider theme={themeCharofil}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    required
                    id="ancho"
                    name="ancho"
                    label="Ancho de Derivación"
                    inputProps={{ style: { textAlign: 'center' } }}
                    value={productSelected.ancho?.ancho}
                    disabled
                  />
                </ThemeProvider>
              </FormControl>
            </Grid>
            {displayRadioFilter(productSelected.ancho?.derivaciones) ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <ThemeProvider theme={themeCharofil}>
                    <InputLabel id="derivacion-select-label">Seleccione un radio:</InputLabel>
                    <Select
                      fullWidth
                      labelId="radio-select-label"
                      id="radio-select"
                      key="radio-select"
                      label="Seleccione un radio:"
                      value={`${radioFilter}`}
                      onChange={handleRadioChange}
                    >
                      <MenuItem key={`radio-empty`} value={0}>
                        Todos
                      </MenuItem>
                      {getRadioFilterOps().map((option) => (
                        <MenuItem key={`radio-${option}`} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </ThemeProvider>
                </FormControl>
              </Grid>
            ) : (
              ''
            )}
            {displayTipoDerivacionFilter() ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <ThemeProvider theme={themeCharofil}>
                    <InputLabel id="derivacion-tipo-select-label">
                      Seleccione el tipo de Derivación:
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="derivacion-tipo-select-label"
                      id="derivacion-tipo-select"
                      key="derivacion-tipo-select"
                      label="Seleccione el tipo de Derivación:"
                      value={`${tiposFilter}`}
                      onChange={handleDerivacionChange}
                    >
                      <MenuItem key={`derivacion-tipo-empty`} value={tipoFilterDefault}>
                        Todos
                      </MenuItem>
                      {getTipoDerivacionFilterOps().map((option) => (
                        <MenuItem key={`derivacion-tipo-${option}`} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </ThemeProvider>
                </FormControl>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        {productSelected.ancho?.derivaciones ? (
          <>
            <Grid item xs={12} display={'flex'} alignItems="center" justifyContent="center">
              <ThemeProvider theme={themeCharofil}>
                <Pagination
                  key="pagination-top"
                  hideNextButton
                  hidePrevButton
                  count={Math.ceil(getDerivaciones().length / paginationSize)}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </ThemeProvider>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                {/* {paginate(getAccesorios())[page - 1]} */}
                {paginate(getDerivaciones())[page - 1]}
              </Grid>
            </Grid>
            <Grid item xs={12} display={'flex'} alignItems="center" justifyContent="center">
              <ThemeProvider theme={themeCharofil}>
                <Pagination
                  key="pagination-bottom"
                  hideNextButton
                  hidePrevButton
                  count={Math.ceil(getDerivaciones().length / paginationSize)}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </ThemeProvider>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            Se requiere seleccionar un Peralte / Tubería
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialogDescription.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogDescription.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={themeCharofil}>
              <Button onClick={handleCloseDialog} variant="contained" color="primary">
                Cerrar
              </Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  ) : (
    <Loading />
  )
}

export default DerivacionesForm

import React from 'react'

import { Grid } from '@mui/material'

import LoadingPage from '../../components/LoadingPage'
import logo from '../../resources/logo-charofil.png'
import { useGeneralStyles } from '../../util/useGeneralStyles'
import { useStyles } from './useStyles'

const Loading: React.FC = () => {
  const generalClasses = useGeneralStyles()
  const classes = useStyles()

  return (
    <Grid container className={generalClasses.generalLoadingRoot}>
      <Grid container className={generalClasses.generalContainer}>
        <Grid item xs={12} className={classes.itemImage}>
          <img src={logo} alt="Charofil" className={classes.image} />
        </Grid>
        <Grid item xs={10} sm={8} className={classes.itemProgress}>
          <LoadingPage />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Loading

import { DispatchAction } from '../../config/types/contextTypes'
import { UserSession } from '../../config/types/sessionTypes'
import { USER_SESSION_KEY } from '../../util/constants'
import { removeData, storeData } from '../../util/localStorage'

export const LOGIN_TYPE = 'login'
export const LOGIN_EXIST = 'loginExist'
export const LOGOUT_TYPE = 'logout'

const setUserSession = (userSession: UserSession) => {
  storeData(USER_SESSION_KEY, userSession)
}

const removeUserSession = () => {
  removeData(USER_SESSION_KEY)
}

const Reducer = (
  state: UserSession | undefined,
  action: DispatchAction
): UserSession | undefined => {
  switch (action.type) {
    case LOGIN_TYPE:
      if (action.data) {
        setUserSession(action.data)

        return {
          ...state,
          ...action.data,
        }
      }
      return state

    case LOGIN_EXIST:
      return {
        ...state,
        ...action.data,
      }

    case LOGOUT_TYPE:
      removeUserSession()

      return undefined

    default:
      return state
  }
}

export default Reducer
